<template>
  <div>
    <HeaderTitle style="margin-top: 10px" title="申请开店门店信息" />
    <div v-if="tableLayout === 'horizontal'">
      <div>
        <el-table
          style="margin-bottom: 10px"
          ref="filterTable"
          :data="shopList"
          border>
          <!-- <el-table-column type="selection" width="55" v-if="!isOA" /> -->
          <!-- <el-table-column label="是否经典+沙发综合店 " width="120">
        <template slot="header">
          <span>
            是否经典+沙发综合店
          </span>
          <i
            style="color: #000; cursor: pointer;"
            title="经典沙发综合店分开提交开店流程，需关联已有经典或沙发门店编码"
            class="el-icon-question"
          ></i>
        </template>
        <template slot-scope="scope">
          <div>
            <CommonSelect
              v-model="scope.row.ifOldShopId"
              class="selecItem"
              type="flow_common_select_ifEnable"
              label="是否已有门店"
              :show-label="false"
              :clearable="false"
              @changeSelectedItem="handleIsOldShop"
            />
          </div>
        </template>
      </el-table-column> -->
          <el-table-column label="是否已有物理店 " width="120">
            <template slot-scope="scope">
              <div>
                <CommonSelect
                  class="selecItem"
                  type="flow_common_select_ifEnable"
                  v-model="scope.row.ifParentShopNumber"
                  label="是否已有物理店"
                  :showLabel="false"
                  :clearable="false"
                  @changeSelectedItem="handleIsOldShop" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="parentShopNumber"
            label="物理店编号"
            width="160">
            <template slot-scope="scope">
              <div>
                <div v-if="scope.row.ifParentShopNumber === '0'">
                  {{ scope.row.parentShopNumber }}
                </div>
                <div v-else>
                  <small class="tip">编码规则由6000001开始</small>
                  <required-form-item-input
                    :inputProps="{ type: 'number' }"
                    class="selecItem"
                    v-model="scope.row.parentShopNumber"
                    label="物理店编号"
                    :showLabel="false"
                    :required="scope.row.ifParentShopNumber === '1'"
                    :prop="`shopList.${scope.$index}.parentShopNumber`"
                    :rules="[
                      {
                        validator(rule, value, callback) {
                          if (
                            value.length !== 7 ||
                            !/^[0-9]+\.?[0-9]*$/.test(value)
                          ) {
                            return callback('请输入7位数字物理店编号')
                          }
                          callback()
                        },
                        trigger: 'blur'
                      }
                    ]" />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="shopNumber" label="门店编号" width="140">
            <template slot-scope="scope">
              <div>
                <div>
                  {{ scope.row.shopNumber }}
                </div>
                <!-- <div v-else>
              <required-form-item-select
                class="selecItem"
                v-model="scope.row.oldShopId"
                label="门店编号"
                :showLabel="false"
                :required="false"
                label-key="shopNumber"
                value-key="id"
                :dataList="shopNumberList"
                @changeSelectedItem="v => handleSelectShopNumber(v)"
              />
            </div> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="storeNature" label="店态性质 " width="180">
            <template slot="header">
              <span> 店态性质 </span>
              <i
                style="color: #000; cursor: pointer"
                title="1. 寝具综合店：一个门店包含大于等于2个寝具系列（在一个空间里，无隔断）

2.沙发综合店：一个门店包含大于等于2个沙发系列（在一个空间里，无隔断）

3.客餐卧综合店：一个门店包含大于等于2个系列，至少含一个寝具系列、一个沙发系列

4. 寝具单系列店：一个门店一个寝具系列（芯睡眠、助眠系列为通配系列，不在单系列计算范围内）

5.沙发单系列店：一个门店一个沙发系列（芯睡眠、助眠系列为通配系列，不在单系列计算范围内）

6.整装店：包含电器、衣柜、橱柜、卫浴等全屋定制产品"
                class="el-icon-question"></i>
            </template>
            <template slot-scope="scope">
              <div>
                <small class="tip"
                  >若为经典+沙发综合店,必须选择:客餐卧综合店</small
                >
                <CommonSelect
                  class="selecItem"
                  type="shop_nature_code"
                  v-model="scope.row.storeNature"
                  label="店态性质"
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.storeNature`"
                  :getDataListParams="{
                    ...shopNatureCode,
                    ifDelete: isOA ? '-1' : '0'
                  }" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="申请品牌（加盟品牌）" width="200">
            <template slot-scope="scope">
              <div>
                <required-form-item-select
                  label="申请品牌（加盟品牌）"
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.brandList`"
                  class="selecItem"
                  :dataList="brandData"
                  labelKey="name"
                  valueKey="brandId"
                  v-model="scope.row.brandList"
                  :selectProps="{ multiple: true }"
                  @changeSelectedItem="
                    value => {
                      getBusinessBrand(value, scope.row, scope.$index)
                    }
                  "
                  :filterDataList="list => brandFilterDataList(list, scope.row)"
                  :disabled="!scope.row.storeNature" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="门店是否需要装修" width="140">
            <template slot-scope="scope">
              <div>
                <CommonSelect
                  class="selecItem"
                  type="renovation"
                  v-model="scope.row.ifRenovation"
                  @changeSelectedItem="
                    () => {
                      scope.row.statusExplain =
                        scope.row.ifRenovation == '1' ? '未开业' : '开业'
                    }
                  "
                  label="门店是否需要装修"
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.ifRenovation`" />
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="合作关系" width="160">
        <template slot-scope="scope">
          <div>
            <CommonSelect
              class="selecItem"
              type="partnership"
              v-model="scope.row.partnership"
              label="合作关系"
              :showLabel="false"
              :prop="`shopList.${scope.$index}.partnership`"
            />
          </div>
        </template>
      </el-table-column> -->

          <!-- <el-table-column label="商场类型" width="160">
        <template slot-scope="scope">
          <div>
            <CommonSelect class="selecItem" type="market" v-model="scope.row.marketType" label="商场类型" :showLabel="false"
              :prop="`shopList.${scope.$index}.marketType`"
               />
          </div>
        </template>
      </el-table-column> -->
          <el-table-column label="门店位置等级" width="160">
            <template slot="header">
              <span> 门店位置等级 </span>
              <i
                style="color: #000; cursor: pointer"
                title="根据门店所在商场位置划分为：
A+、A、B+、B、C

A+：商场中厅扶梯一圈的位置
A：面向正门，从商场中厅向后延伸的位置
B+：商场面向正门中厅A+左右两边的位置
B：靠近B+左右两边偏厅的位置
C：商场偏厅的角落位置"
                class="el-icon-question"></i>
            </template>
            <template slot-scope="scope">
              <div>
                <CommonSelect
                  class="selecItem"
                  type="shop_level"
                  v-model="scope.row.shopPositionLevel"
                  label="门店位置等级"
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.shopPositionLevel`" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="独立外立面门头" width="160">
            <template slot-scope="scope">
              <div>
                <CommonSelect
                  class="selecItem"
                  type="independent_door_head_flag_boolean"
                  v-model="scope.row.independentDoorHeadFlag"
                  label="独立外立面门头"
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.independentDoorHeadFlag`" />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="shopAlias" label="门店简称" width="200">
            <template slot-scope="scope">
              <div>
                <small class="tip"
                  >授权城市+商场名称+楼层+系列/授权城市+小区名称+楼层+系列</small
                >
                <required-form-item-input
                  delay-update
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.shopAlias`"
                  v-model="scope.row.shopAlias"
                  label="门店简称" />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="province" label="省份" width="160">
          </el-table-column>
          <el-table-column prop="city" label="地级市" width="160">
          </el-table-column>
          <el-table-column label="区/县" width="160">
            <template slot-scope="scope">
              <div>
                <required-module-form-item-select
                  class="selecItem"
                  :showLabel="false"
                  :required="false"
                  v-model="scope.row.area"
                  label="区/县"
                  type="area"
                  module="public"
                  :parent-code="authorizedCityMsg.cityCode"
                  valueKey="content"
                  dependentParentCode />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="道路/街道" width="230">
            <template slot-scope="scope">
              <small style="color: #fc5055">
                请填写门店所在街道以及所在道路
              </small>
              <div>
                <required-form-item-input
                  style="width: 100%"
                  delay-update
                  class="selecItem"
                  v-model="scope.row.street"
                  label="道路/街道"
                  placeholder="示例：湖景街道科技大道 1 号"
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.street`" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="渠道性质" width="160">
            <template slot="header">
              <span> 渠道性质 </span>
              <i
                style="color: #000; cursor: pointer"
                title="1. KA商场店：在全国连锁的家居、家具城或建材卖场内开的专卖店，如居然之家、月星家居、红星美凯龙、欧亚达、百佳联盟等商场（详见KA商场明细）；

2.当地商场店：除KA商场以外的家居建材商场；

3.独立店：独立于商场、新渠道的沿街开门的门店或在建材城内的门店；

4. 新渠道店：家装/整装公司、商超、百货、家电卖场、购物中心等非常规渠道的门店或者网点；在全国百货卖场和shopping mall开的门店（如：百盛、新世界、王府井、万达、银泰等）；

5. 展示店：以品牌宣传、引流为主要目的，以销售为辅的门店，如：机场、高铁、汽车4S店、KA中厅展示位等。"
                class="el-icon-question"></i>
            </template>
            <template slot-scope="scope">
              <div>
                <CommonSelect
                  class="selecItem shopType"
                  type="store_type"
                  v-model="scope.row.shopType"
                  label="渠道性质"
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.shopType`"
                  @changeSelectedItem="
                    e => (scope.row.storeTypeContent = e.content)
                  "
                  :selectListeners="{
                    change: data => {
                      changeShopType(data, scope.row)
                    }
                  }" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="渠道性质二级分类（商场名称）" width="180">
            <template slot-scope="scope">
              <CommonSelect
                v-if="['SJ007', 'SJ001'].includes(scope.row.shopType)"
                class="selecItem"
                v-model="scope.row.shopTypeValue"
                :showLabel="false"
                label="渠道性质二级分类（商场名称）"
                type="secondary_store_type"
                :prop="`shopList.${scope.$index}.shopTypeValue`"
                :required="!['SJ004', 'SJ003'].includes(scope.row.shopType)"
                valueKey="content"
                :parent-code="scope.row.shopType"
                :getDataListParams="{ parentType: 'store_type' }"
                :selectListeners="{
                  change: data => {
                    scope.row.shopTypeThree = ''
                  }
                }" />
              <required-form-item-input
                v-else
                delay-update
                class="selecItem"
                :label="
                  ['SJ004', 'SJ003'].includes(scope.row.shopType)
                    ? ''
                    : '渠道性质二级分类（商场名称）'
                "
                :required="!['SJ004', 'SJ003'].includes(scope.row.shopType)"
                :showLabel="false"
                v-model="scope.row.shopTypeValue"
                :prop="`shopList.${scope.$index}.shopTypeValue`"
                :disabled="
                  ['SJ004', 'SJ003', 'SJ005'].includes(scope.row.shopType) ||
                  !scope.row.shopType
                "
                @input="
                  () => {
                    scope.row.shopTypeThree = ''
                  }
                " />
            </template>
          </el-table-column>
          <el-table-column label="渠道性质三级分类" width="180">
            <template slot-scope="scope">
              <CommonSelect
                class="selecItem"
                v-model="scope.row.shopTypeThree"
                :showLabel="false"
                :required="false"
                type="three_store_type"
                value-key="content"
                :parent-content="scope.row.shopTypeValue"
                empty-disabled
                dependent-parent-content
                label="渠道性质三级分类"
                :prop="`shopList.${scope.$index}.shopTypeThree`" />
            </template>
          </el-table-column>
          <!-- <el-table-column prop="marketName" label="商场名称" width="160">
        <template slot-scope="scope">
          <div>
            {{scope.row.marketName}}
          </div>
        </template>
      </el-table-column> -->
          <el-table-column label="门店楼层" width="160">
            <template slot-scope="scope">
              <div>
                <required-form-item-input
                  delay-update
                  class="selecItem"
                  v-model="scope.row.shopFloor"
                  placeholder="填写示例：1层"
                  :required="false"
                  label="门店楼层"
                  :showLabel="false"
                  maxlength="20" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="门店铺号" width="160">
            <template slot-scope="scope">
              <div>
                <required-form-item-input
                  delay-update
                  class="selecItem"
                  v-model="scope.row.shopFloorNumber"
                  placeholder="填写示例：1号"
                  label="门店铺号"
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.shopFloorNumber`" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="门店名称" width="160">
            <template slot-scope="scope">
              <div>
                {{ getShopName(scope.row, scope.$index) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="门店地址" width="160">
            <template slot-scope="scope">
              <div>
                {{ getShopAddress(scope.row) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="门店经纬度" width="160">
            <template slot-scope="scope">
              <div>
                <div v-if="shopList[0].longitude && shopList[0].latitude">
                  {{ shopList[0].longitude }} {{ shopList[0].latitude }}
                </div>
                <el-button @click="mapShow = true">打开地图</el-button>
              </div>
              <!-- {{ location.address }} -->
            </template>
          </el-table-column>
          <el-table-column label="门店联系方式" width="160">
            <template slot-scope="scope">
              <div>
                <required-form-item-input
                  delay-update
                  label="门店联系方式"
                  class="selecItem"
                  v-model="scope.row.shopTel"
                  :required="true"
                  :showLabel="false"
                  :inputProps="{ maxlength: 11 }"
                  :prop="`shopList.${scope.$index}.shopTel`"
                  :rules="[
                    {
                      validator: regCheckPhone,
                      trigger: 'blur'
                    }
                  ]" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="渠道类型" width="160">
            <template slot-scope="scope">
              <div>
                <CommonSelect
                  class="selecItem"
                  type="cooperation_code"
                  v-model="scope.row.channel"
                  label="渠道类型"
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.channel`" />
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="渠道类型二级分类" width="160">
        <template slot-scope="scope">
          <div>
            <CommonSelect class="selecItem" type="secondary_channel_type"
              :getDataTrigger="scope.row.channel?['change']:[]" valueKey="content" :parentCode="scope.row.channel"
              v-model="scope.row.channelClassification" label="渠道类型二级分类" :showLabel="false"
              :disabled="!scope.row.channel" />
          </div>
        </template>
      </el-table-column> -->
          <!-- <el-table-column label="是否展示店" width="160">
        <template slot-scope="scope">
          <div>
            <required-form-item-select class="selecItem" valueKey="value" labelKey="label"
              v-model="scope.row.isShowCategory" :showLabel="false"
              :dataList="[{value:1,label:'是'},{value:0,label:'否'}]"
              @changeSelectedItem="(value)=>{changeShopCategory(value,scope.row)}" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="展示店类型" width="160">
        <template slot-scope="scope">
          <div>
            <CommonSelect class="selecItem" type="exhibition_shop_type" v-model="scope.row.displayShopType"
              label="展示店类型" :showLabel="false" :prop="`shopList.${scope.$index}.displayShopType`"
              :disabled="!scope.row.isShowCategory" :required="scope.row.isShowCategory?true:false" />
          </div>
        </template>
      </el-table-column> -->
          <el-table-column prop="shopArea" label="门店实用面积" width="160">
          </el-table-column>
          <el-table-column label="经营模式" width="160">
            <template slot-scope="scope">
              <div>
                <CommonSelect
                  class="selecItem"
                  type="management_model"
                  v-model="scope.row.managementModel"
                  label="经营模式"
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.managementModel`" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="二级分销商" width="160">
            <template slot-scope="scope">
              <div v-show="scope.row.managementModel === '20'">
                <required-form-item-input
                  delay-update
                  class="selecItem"
                  v-model="scope.row.contactPerson"
                  label="二级分销商"
                  :showLabel="false"
                  :required="scope.row.managementModel === '20'"
                  :prop="`shopList.${scope.$index}.contactPerson`" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="二级分销商身份证" width="200">
            <template slot-scope="scope">
              <div v-show="scope.row.managementModel === '20'">
                <required-form-item-input
                  delay-update
                  class="selecItem"
                  v-model="scope.row.contactPersonIdcard"
                  label="二级分销商身份证"
                  :required="scope.row.managementModel === '20'"
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.contactPersonIdcard`"
                  :rules="[
                    {
                      validator:
                        scope.row.managementModel === '20' ? regIsCard : null,
                      trigger: 'blur'
                    }
                  ]" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="二级分销商联系方式" width="160">
            <template slot-scope="scope">
              <div v-show="scope.row.managementModel === '20'">
                <required-form-item-input
                  delay-update
                  label="二级分销商联系方式"
                  class="selecItem"
                  v-model="scope.row.contactPersonPhone"
                  :required="scope.row.managementModel === '20'"
                  :showLabel="false"
                  :inputProps="{ maxlength: 11 }"
                  :prop="`shopList.${scope.$index}.contactPersonPhone`"
                  :rules="[
                    {
                      validator:
                        scope.row.managementModel === '20'
                          ? regCheckPhone
                          : null,
                      trigger: 'blur'
                    }
                  ]" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="计划装修时间" width="210">
            <template slot-scope="scope">
              <div>
                <el-form-item
                  class="selecItem"
                  :prop="`shopList.${scope.$index}.decorationTime`"
                  :rules="[
                    {
                      required: true,
                      message: '请选择计划装修时间',
                      trigger: 'change'
                    }
                  ]">
                  <el-date-picker
                    style="width: 190px"
                    size="mini"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="scope.row.decorationTime"
                    type="date"
                    placeholder="选择日期时间"
                    :readonly="isOA">
                  </el-date-picker>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="计划开业时间" width="210">
            <template slot-scope="scope">
              <div>
                <el-form-item
                  class="selecItem"
                  :prop="`shopList.${scope.$index}.openingTime`"
                  :rules="[
                    {
                      required: true,
                      message: '请选择计划开业时间',
                      trigger: 'change'
                    }
                  ]">
                  <el-date-picker
                    style="width: 190px"
                    size="mini"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="scope.row.openingTime"
                    type="date"
                    placeholder="选择日期时间"
                    :readonly="isOA"
                    @change="
                      () => {
                        scope.row.planOpeningTime = scope.row.openingTime
                      }
                    ">
                  </el-date-picker>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="门店租金（万元/年）" width="160">
            <template slot-scope="scope">
              <div>
                <required-form-item-input
                  class="selecItem"
                  v-model="scope.row.shopRent"
                  label="门店租金"
                  :showLabel="false"
                  :prop="`shopList.${scope.$index}.shopRent`"
                  :inputProps="{ type: 'number' }"
                  @blur="
                    e => {
                      handleBlur(e, scope.row)
                    }
                  " />
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="shopList.length > 0 && shopList[0].shopBrandList.length > 0">
          <div>
            <HeaderTitle style="margin-top: 10px" title="生成门店明细" />
            <div>
              <div style="margin-bottom: 10px">
                <el-table :data="shopList[0].shopBrandList" border>
                  <el-table-column type="index" label="序号" width="55">
                  </el-table-column>
                  <el-table-column
                    prop="shopName"
                    label="门店名称"
                    align="center">
                    <template slot-scope="prop">
                      <div>
                        <!-- {{scope.row.shopName}} -->
                        {{
                          `${shopList[0].city || ''}${shopList[0].area || ''}${
                            shopList[0].street || ''
                          }${shopList[0].shopTypeValue || ''}${
                            shopList[0].shopFloor || ''
                          }${shopList[0].shopFloorNumber || ''}（${
                            prop.row.shopBrand || ''
                          }）专卖店`
                        }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="shopBrand"
                    label="申请品牌"
                    width="160">
                  </el-table-column>
                  <el-table-column
                    prop="brandArea"
                    label="品牌面积"
                    width="120">
                    <template slot-scope="prop">
                      <el-form-item
                        :class="['selecItem', isOA ? ' delay-update' : '']"
                        :prop="`shopList.${0}.shopBrandList.${
                          prop.$index
                        }.brandArea`"
                        :rules="[
                          {
                            required: true,
                            message: '请输入品牌面积',
                            trigger: 'blur'
                          }
                        ]">
                        <el-input
                          v-model="prop.row.brandArea"
                          :disabled="isOA"
                          @keyup.native="
                            prop.row.brandArea = oninput(prop.row.brandArea)
                          "
                          @blur="
                            () => {
                              getArea(shopList[0])
                            }
                          "
                          placeholder="请输入品牌面积"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="sampleSets"
                    label="样品套数"
                    width="120">
                    <template slot-scope="prop">
                      <div>
                        <required-form-item-input
                          delay-update
                          class="selecItem"
                          v-model.number="prop.row.sampleSets"
                          :inputProps="{ maxlength: '4' }"
                          :validations="['number']"
                          label="样品套数"
                          :showLabel="false"
                          :prop="`shopList.${0}.shopBrandList.${
                            prop.$index
                          }.sampleSets`" />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="系列类型" width="160">
                    <template slot-scope="prop">
                      <div>
                        <CommonSelect
                          class="selecItem"
                          type="shop_change"
                          v-model="prop.row.updateReason"
                          label="系列类型"
                          :required="
                            checkBrandIsDerucciClassic(prop.row.shopBrand)
                          "
                          :showLabel="false"
                          :prop="`shopList.${0}.shopBrandList.${
                            prop.$index
                          }.updateReason`"
                          :filter-data-list="
                            list => list.filter(item => item.status === 0)
                          " />
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <div v-if="shopList.length > 0">
          <CommonSelect
            class="formItem"
            type="flow_common_select_ifEnable"
            v-model="shopList[0].ifParentShopNumber"
            label="是否已有物理店"
            :clearable="false"
            @changeSelectedItem="handleIsOldShop" />
          <div>
            <div>
              <small v-if="shopList[0].ifParentShopNumber === '1'" class="tip"
                >编码规则由6000001开始</small
              >
              <required-form-item-input
                :readonly="shopList[0].ifParentShopNumber === '0'"
                :inputProps="{ type: 'number' }"
                class="formItem"
                v-model="shopList[0].parentShopNumber"
                label="物理店编号"
                :required="shopList[0].ifParentShopNumber === '1'"
                :prop="`shopList.${0}.parentShopNumber`"
                :rules="[
                  {
                    validator(rule, value, callback) {
                      if (shopList[0].ifParentShopNumber === '0') {
                        return callback()
                      }
                      if (
                        value.length !== 7 ||
                        !/^[0-9]+\.?[0-9]*$/.test(value)
                      ) {
                        return callback('请输入7位数字物理店编号')
                      }
                      callback()
                    },
                    trigger: 'blur'
                  }
                ]" />
            </div>
          </div>
          <required-form-item-input
            class="formItem"
            v-model="shopList[0].shopNumber"
            label="门店编号"
            :readonly="true"
            :required="false" />
          <div>
            <small class="tip">若为经典+沙发综合店,必须选择:客餐卧综合店</small>
            <CommonSelect
              class="formItem"
              type="shop_nature_code"
              v-model="shopList[0].storeNature"
              label="店态性质"
              :prop="`shopList.${0}.storeNature`"
              :disabled="shopList[0].brandList.length === 0"
              :getDataListParams="{
                ...shopNatureCode,
                ifDelete: isOA ? '-1' : '0'
              }" />
          </div>
          <required-form-item-select
            label="申请品牌（加盟品牌）"
            :prop="`shopList.${0}.brandList`"
            class="formItem"
            :dataList="brandData"
            labelKey="name"
            valueKey="brandId"
            v-model="shopList[0].brandList"
            :selectProps="{ multiple: true }"
            @changeSelectedItem="
              value => {
                getBusinessBrand(value, shopList[0], 0)
              }
            "
            :filterDataList="list => brandFilterDataList(list, shopList[0])"
            :disabled="!shopList[0].storeNature" />
          <CommonSelect
            class="formItem"
            type="renovation"
            v-model="shopList[0].ifRenovation"
            @changeSelectedItem="
              () => {
                shopList[0].statusExplain =
                  shopList[0].ifRenovation == '1' ? '未开业' : '开业'
              }
            "
            label="门店是否需要装修"
            :prop="`shopList.${0}.ifRenovation`" />
          <CommonSelect
            class="formItem"
            type="shop_level"
            v-model="shopList[0].shopPositionLevel"
            label="门店位置等级"
            :prop="`shopList.${0}.shopPositionLevel`" />
          <CommonSelect
            class="formItem"
            type="independent_door_head_flag_boolean"
            v-model="shopList[0].independentDoorHeadFlag"
            label="独立外立面门头"
            :prop="`shopList.${0}.independentDoorHeadFlag`" />
          <div>
            <small class="tip"
              >授权城市+商场名称+楼层+系列/授权城市+小区名称+楼层+系列</small
            >
            <required-form-item-input
              class="formItem"
              delay-update
              :prop="`shopList.${0}.shopAlias`"
              v-model="shopList[0].shopAlias"
              label="门店简称" />
          </div>
          <required-form-item-input
            :readonly="true"
            :required="true"
            class="formItem"
            v-model="shopList[0].province"
            label="省份" />
          <required-form-item-input
            :readonly="true"
            :required="true"
            class="formItem"
            v-model="shopList[0].city"
            label="地级市" />
          <required-module-form-item-select
            class="formItem"
            :required="false"
            v-model="shopList[0].area"
            label="区/县"
            type="area"
            module="public"
            :parent-code="authorizedCityMsg.cityCode"
            valueKey="content"
            dependentParentCode />
          <small style="color: #fc5055"> 请填写门店所在街道以及所在道路 </small>
          <required-form-item-input
            delay-update
            class="formItem"
            v-model="shopList[0].street"
            label="道路/街道"
            placeholder="示例：湖景街道科技大道 1 号"
            :prop="`shopList.${0}.street`" />
          <CommonSelect
            class="formItem shopType"
            type="store_type"
            v-model="shopList[0].shopType"
            label="渠道性质"
            :prop="`shopList.${0}.shopType`"
            @changeSelectedItem="
              e => (shopList[0].storeTypeContent = e.content)
            "
            :selectListeners="{
              change: data => {
                changeShopType(data, shopList[0])
              }
            }" />
          <div>
            <CommonSelect
              v-if="['SJ007', 'SJ001'].includes(shopList[0].shopType)"
              class="formItem"
              v-model="shopList[0].shopTypeValue"
              label="渠道性质二级分类（商场名称）"
              type="secondary_store_type"
              :prop="`shopList.${0}.shopTypeValue`"
              :required="!['SJ004', 'SJ003'].includes(shopList[0].shopType)"
              valueKey="content"
              :parent-code="shopList[0].shopType"
              :getDataListParams="{ parentType: 'store_type' }"
              :selectListeners="{
                change: data => {
                  shopList[0].shopTypeThree = ''
                }
              }" />
            <required-form-item-input
              v-else
              delay-update
              class="formItem"
              :label="
                ['SJ004', 'SJ003'].includes(shopList[0].shopType)
                  ? ''
                  : '渠道性质二级分类（商场名称）'
              "
              :required="!['SJ004', 'SJ003'].includes(shopList[0].shopType)"
              v-model="shopList[0].shopTypeValue"
              :prop="`shopList.${0}.shopTypeValue`"
              :disabled="
                ['SJ004', 'SJ003', 'SJ005'].includes(shopList[0].shopType) ||
                !shopList[0].shopType
              "
              @input="
                () => {
                  shopList[0].shopTypeThree = ''
                }
              " />
          </div>
          <CommonSelect
            class="formItem"
            v-model="shopList[0].shopTypeThree"
            :required="false"
            type="three_store_type"
            value-key="content"
            :parent-content="shopList[0].shopTypeValue"
            empty-disabled
            dependent-parent-content
            label="渠道性质三级分类"
            :prop="`shopList.${0}.shopTypeThree`" />
          <required-form-item-input
            delay-update
            class="formItem"
            v-model="shopList[0].shopFloor"
            placeholder="填写示例：1层"
            :required="false"
            label="门店楼层" />
          <required-form-item-input
            delay-update
            class="formItem"
            v-model="shopList[0].shopFloorNumber"
            placeholder="填写示例：1号"
            label="门店铺号"
            :prop="`shopList.${0}.shopFloorNumber`" />
          <el-form-item class="formItem" label="门店名称">
            {{ getShopName(shopList[0], 0) }}
          </el-form-item>
          <el-form-item class="formItem" label="门店地址">
            {{ getShopAddress(shopList[0]) }}
          </el-form-item>
          <el-form-item class="formItem" label="门店经纬度">
            <div>
              <div v-if="shopList[0].longitude && shopList[0].latitude">
                {{ shopList[0].longitude }} {{ shopList[0].latitude }}
              </div>
              <el-button v-if="!isOA" @click="mapShow = true"
                >打开地图</el-button
              >
            </div>
          </el-form-item>
          <required-form-item-input
            delay-update
            label="门店联系方式"
            class="formItem"
            v-model="shopList[0].shopTel"
            :required="true"
            :inputProps="{ maxlength: 11 }"
            :prop="`shopList.${0}.shopTel`"
            :rules="[
              {
                validator: regCheckPhone,
                trigger: 'blur'
              }
            ]" />
          <CommonSelect
            class="formItem"
            type="cooperation_code"
            v-model="shopList[0].channel"
            label="渠道类型"
            :prop="`shopList.${0}.channel`" />
          <required-form-item-input
            v-if="shopList[0].ifChildrenSupportingArea === '1'"
            class="formItem"
            delay-update
            v-model="shopList[0].childrenSupportingArea"
            label="面积（平方米）"
            :required="shopList[0].ifChildrenSupportingArea === '1'"
            :prop="`shopList.${0}.childrenSupportingArea`"
            type="number"
            :max="9999"
            :min="0"
            :rules="[
              {
                validator: regNonzero,
                trigger: 'blur'
              }
            ]" />
          <required-form-item-input
            :readonly="true"
            :required="true"
            class="formItem"
            v-model="shopList[0].shopArea"
            label="门店实用面积" />
          <CommonSelect
            class="formItem"
            type="management_model"
            v-model="shopList[0].managementModel"
            label="经营模式"
            :prop="`shopList.${0}.managementModel`" />
          <div v-show="shopList[0].managementModel === '20'">
            <required-form-item-input
              delay-update
              class="formItem"
              v-model="shopList[0].contactPerson"
              label="二级分销商"
              :required="shopList[0].managementModel === '20'"
              :prop="`shopList.${0}.contactPerson`" />
          </div>
          <div v-show="shopList[0].managementModel === '20'">
            <required-form-item-input
              delay-update
              class="formItem"
              v-model="shopList[0].contactPersonIdcard"
              label="二级分销商身份证"
              :required="shopList[0].managementModel === '20'"
              :prop="`shopList.${0}.contactPersonIdcard`"
              :rules="[
                {
                  validator:
                    shopList[0].managementModel === '20' ? regIsCard : null,
                  trigger: 'blur'
                }
              ]" />
          </div>
          <div v-show="shopList[0].managementModel === '20'">
            <required-form-item-input
              delay-update
              label="二级分销商联系方式"
              class="formItem"
              v-model="shopList[0].contactPersonPhone"
              :required="shopList[0].managementModel === '20'"
              :inputProps="{ maxlength: 11 }"
              :prop="`shopList.${0}.contactPersonPhone`"
              :rules="[
                {
                  validator:
                    shopList[0].managementModel === '20' ? regCheckPhone : null,
                  trigger: 'blur'
                }
              ]" />
          </div>
          <el-form-item
            class="formItem"
            :prop="`shopList.${0}.decorationTime`"
            :rules="[
              {
                required: true,
                message: '请选择计划装修时间',
                trigger: 'change'
              }
            ]"
            label="计划装修时间">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="shopList[0].decorationTime"
              type="date"
              placeholder="选择日期时间"
              :readonly="isOA">
            </el-date-picker>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="计划开业时间"
            :prop="`shopList.${0}.openingTime`"
            :rules="[
              {
                required: true,
                message: '请选择计划开业时间',
                trigger: 'change'
              }
            ]">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="shopList[0].openingTime"
              type="date"
              placeholder="选择日期时间"
              :readonly="isOA"
              @change="
                () => {
                  shopList[0].planOpeningTime = shopList[0].openingTime
                }
              ">
            </el-date-picker>
          </el-form-item>
          <required-form-item-input
            class="formItem"
            v-model="shopList[0].shopRent"
            label="门店租金（万元/年）"
            :prop="`shopList.${0}.shopRent`"
            :inputProps="{ type: 'number' }"
            @blur="
              e => {
                handleBlur(e, shopList[0])
              }
            " />
        </div>
        <div v-if="shopList.length > 0 && shopList[0].shopBrandList.length > 0">
          <HeaderTitle style="margin-top: 10px" title="生成门店明细" />
          <div v-for="(shopBrandItem, index) in shopList[0].shopBrandList">
            <el-form-item class="formItem" label="门店名称">
              {{
                `${shopList[0].city || ''}${shopList[0].area || ''}${
                  shopList[0].street || ''
                }${shopList[0].shopTypeValue || ''}${
                  shopList[0].shopFloor || ''
                }${shopList[0].shopFloorNumber || ''}（${
                  shopBrandItem.shopBrand || ''
                }）专卖店`
              }}
            </el-form-item>
            <el-form-item class="formItem" label="申请品牌">
              {{ shopBrandItem.shopBrand }}
            </el-form-item>
            <el-form-item
              label="品牌面积"
              :prop="`shopList.${0}.shopBrandList.${index}.brandArea`"
              :rules="[
                {
                  required: true,
                  message: '请输入品牌面积',
                  trigger: 'blur'
                }
              ]"
              :class="[isOA ? ' delay-update' : '', 'formItem']">
              <el-input
                v-model="shopBrandItem.brandArea"
                :disabled="isOA"
                @keyup.native="
                  shopBrandItem.brandArea = oninput(shopBrandItem.brandArea)
                "
                @blur="
                  () => {
                    getArea(shopList[0])
                  }
                "
                placeholder="请输入品牌面积"></el-input>
            </el-form-item>
            <required-form-item-input
              delay-update
              class="formItem"
              v-model.number="shopBrandItem.sampleSets"
              :inputProps="{ maxlength: '4' }"
              :validations="['number']"
              label="样品套数"
              :prop="`shopList.${0}.shopBrandList.${index}.sampleSets`" />
            <CommonSelect
              class="formItem"
              type="shop_change"
              v-model="shopBrandItem.updateReason"
              label="系列类型"
              :required="checkBrandIsDerucciClassic(shopBrandItem.shopBrand)"
              :prop="`shopList.${0}.shopBrandList.${index}.updateReason`"
              :filter-data-list="
                list => list.filter(item => item.status === 0)
              " />
          </div>
        </div>
      </div>
    </div>
    <div v-if="shopList.length > 0 && shopList[0].shopBrandList.length > 0">
      <div>
        <UploadFile
          class="formItem"
          style="min-width: 250px"
          label="上传附件"
          v-model="shopList[0].fileList2"
          :prop="`shopList.${0}.fileList2`"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="file => handleUpload(file, 'fileList2', 0)">
          <div slot="describe">
            <div>附件：</div>
            <div>1、店铺含外围照片不低于5张或视频（必填）</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
        <UploadFile
          class="formItem"
          style="min-width: 250px"
          label="上传附件"
          v-model="shopList[0].fileList1"
          :prop="`shopList.${0}.fileList1`"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="file => handleUpload(file, 'fileList1', 0)">
          <div slot="describe">
            <div>附件：</div>
            <div>2、商场平面图标注慕思位置及人流动向、独立店地图定位</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
        <UploadFile
          class="formItem"
          style="min-width: 250px"
          label="上传附件"
          v-model="shopList[0].fileList3"
          :prop="`shopList.${0}.fileList3`"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="file => handleUpload(file, 'fileList3', 0)">
          <div slot="describe">
            <div>附件：</div>
            <div>3、店铺CAD图纸（必填）</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
        <UploadFile
          class="formItem"
          style="min-width: 250px"
          label="上传附件"
          v-model="shopList[0].fileList4"
          :prop="`shopList.${0}.fileList4`"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="file => handleUpload(file, 'fileList4', 0)">
          <div slot="describe">
            <div>附件：</div>
            <div>4、店铺租赁合同</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
        <UploadFile
          class="formItem"
          style="min-width: 250px"
          label="上传附件"
          v-model="shopList[0].fileList5"
          :prop="`shopList.${0}.fileList5`"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="file => handleUpload(file, 'fileList5', 0)">
          <div slot="describe">
            <div>附件：</div>
            <div>5、新商预打款凭证</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
        <UploadFile
          class="formItem"
          style="min-width: 250px"
          label="上传附件"
          v-model="shopList[0].fileList6"
          :prop="`shopList.${0}.fileList6`"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="file => handleUpload(file, 'fileList6', 0)">
          <div slot="describe">
            <div>附件：</div>
            <div>6、开店承诺书</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
        <UploadFile
          class="formItem"
          style="min-width: 250px"
          label="上传附件"
          v-model="shopList[0].fileList7"
          :prop="`shopList.${0}.fileList7`"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="file => handleUpload(file, 'fileList7', 0)">
          <div slot="describe">
            <div>附件：</div>
            <div>7、VMD设计需求表</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
      </div>
      <div v-show="shopList[0].ifRenovation == '1'">
        <required-form-item-input
          class="formItem"
          label="装修对接人"
          :prop="`shopList.${0}.renovationLiablePerson`"
          :required="shopList[0].ifRenovation == '1'"
          v-model="shopList[0].renovationLiablePerson" />
        <required-form-item-input
          delay-update
          class="formItem"
          :required="false"
          label="装修对接人电话"
          :prop="`shopList.${0}.renovationPersonPhone`"
          v-model="shopList[0].renovationPersonPhone"
          :rules="[
            {
              validator: shopList[0].ifRenovation == '1' ? regCheckPhone : null,
              trigger: 'blur',
              required: shopList[0].ifRenovation == '1'
            }
          ]" />
      </div>
      <CommonSelect
        v-show="isV6"
        class="formItem"
        label="是否补贴"
        type="flow_common_select_ifEnable"
        v-model="shopList[0].ifSubsidy"
        :required="false"
        labelTip="V6填写字段，非V6招商可忽略。V6家居所提供的经销商新店装修补贴支持，只补贴净增长部分面积，如若当该授权区域的门店在一年内（按自然日计算）发生了撤店行为，导致门店面积未实现净增长，所获得的装修补贴金额V6家居将全额扣回，且按照所享受补贴金额的10%进行追加处罚">
      </CommonSelect>
      <required-form-item-input
        v-show="shopList[0].ifSubsidy === '1'"
        class="formItem"
        delay-update
        v-model="shopList[0].subsidyAmount"
        label="补贴金额（万）"
        :required="false"
        type="number"
        :max="9999"
        :min="0" />
      <div>
        <CommonSelect
          class="formItem"
          label="是否有儿童配套专区"
          type="flow_common_select_ifEnable"
          v-model="shopList[0].ifChildrenSupportingArea"
          :required="false">
        </CommonSelect>
        <required-form-item-input
          v-if="shopList[0].ifChildrenSupportingArea === '1'"
          class="formItem"
          delay-update
          v-model="shopList[0].childrenSupportingArea"
          label="面积（平方米）"
          :required="shopList[0].ifChildrenSupportingArea === '1'"
          :prop="`shopList.${0}.childrenSupportingArea`"
          type="number"
          :max="9999"
          :min="0"
          :rules="[
            {
              validator: regNonzero,
              trigger: 'blur'
            }
          ]" />
      </div>
    </div>
    <el-dialog title="地图" :visible.sync="mapShow" width="600px">
      <div style="height: 300px">
        <q-map
          :center="
            shopList.length > 0 && shopList[0].longitude && shopList[0].latitude
              ? {
                  latitude: shopList[0].latitude,
                  longitude: shopList[0].longitude
                }
              : null
          "
          @onChange="
            e => {
              location = e
              shopList[0].longitude = e.longitude
              shopList[0].latitude = e.latitude
            }
          " />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mapShow = false">取 消</el-button>
        <el-button type="primary" @click="mapShow = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import HeaderTitle from './headerTitle'
import CommonSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import AddressSelect from './addressSelect'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import UploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
import QMap from '@/components/Map/qqmap'
import { storeNatureBrandListFilter } from '@/utils/flow'
import { common } from '@/mixin/mixin'
import { shopFLowData } from '@/api/flow/index'
import { mapGetters } from 'vuex'
import { isEqual } from 'element-ui/src/utils/util'
import { checkBrandIsDerucciClassic } from '../data/checkBrandIsDerucciClassic'
export default {
  mixins: [common],
  components: {
    QMap,
    HeaderTitle,
    CommonSelect,
    AddressSelect,
    RequiredFormItemSelect,
    RequiredFormItemInput,
    UploadFile,
    RequiredModuleFormItemSelect
  },
  props: {
    businessMsg: {
      type: Object,
      default: () => ({})
    },
    authorizedCityMsg: {
      type: Object,
      default: () => ({})
    },
    brandData: {
      type: Array,
      default: () => []
    },
    tableShopList: {
      type: Array,
      default: () => []
    },
    shopTitleBrand: {
      type: Array,
      default: () => []
    },
    dictionaryList: {
      type: Array,
      default: () => []
    },
    ifOldShopId: {
      type: String,
      default: ''
    },
    authorizedBrandList: {
      type: Array,
      default: () => []
    },
    tableLayout: {
      type: String,
      default: 'vertical' || 'horizontal'
    }
  },
  data() {
    return {
      mapShow: false,
      location: {},
      shopList: [],
      expands: [],
      rowId: [],
      address: {
        provinceList: [],
        cityList: [],
        areaList: [],
        province: '',
        city: '',
        area: ''
      },
      titleBrand: [], // 标题门店内容
      filterTitleBrand: [],
      shopNumberList: [],
      shopArea: 0, // 门店面积
      IsOldShop: '0',
      isV6: false,
      isUpdateReason: false,
      shopNatureCode: {}
    }
  },
  watch: {
    shopList: {
      deep: true,
      handler(value, oldValue) {
        // console.log(value[0].shopName, oldValue[0].shopName)
        if (!isEqual(value, oldValue)) {
          this.$emit('update:tableShopList', value)
        }
      }
    },
    tableShopList: {
      handler(value) {
        this.$set(this, 'shopList', value)
      }
    },
    brandData: {
      handler(newVal, oldVal) {
        console.log('brandData', newVal)

        if (newVal.length > 0 && this.shopList.length === 0) {
          // 首次加载列表
          this.addDerucci()
        }
        this.isV6 = newVal?.some(item => item.bigBrandCode === 'F')
        this.shopNatureCode =
          this.isV6 && !this.isOA ? { moduleBrand: 'F' } : {}
        // if (oldVal.length === 0) return
        // if (!isEqual(newVal, oldVal)) {
        //   this.shopList.length > 0 &&
        //     this.shopList.forEach(item => {
        //       item.brandList = []
        //     })
        // }
      }
    },
    'authorizedCityMsg.authorizedCityCode'(value) {
      value && this.getShopFLowData()
      // this.addDerucci()
      // this.getShopFLowData()
    },
    // shopTitleBrand: {
    //   handler(value, oldVal) {
    //     if (!isEqual(value, oldVal)) {
    //       value && (this.isV6 = value.some(item => item?.includes('V6')))
    //     }
    //   }
    // },
    IsOldShop: {
      handler(value, oldVal) {
        // console.log(value, oldVal)
        if (value === '0') {
          this.shopList = [
            {
              index:
                this.shopList.length === 0
                  ? 0
                  : this.shopList[this.shopList.length - 1].index + 1,
              // oldShopId: '',
              ifOldShopId: '0',
              o1dShopId: '',
              ifParentShopNumber: '0',
              parentShopNumber: '',
              shopNumber: '',
              bossId: this.businessMsg.bossId,
              storeNature: '',
              shopType: '',
              shopTypeValue: '',
              shopTypeThree: '',
              ifRenovation: '1', // 是否装修
              authorizedCityCode:
                this.authorizedCityMsg.authorizedCityCode || '', // 授权城市编号
              authorizedCityName: `${this.authorizedCityMsg.city || ''}${
                this.authorizedCityMsg.area || ''
              }`, // 授权城市名称
              marketLevelCode: this.authorizedCityMsg.marketLevelCode || '', // 市场级别编号
              marketLevelName: this.authorizedCityMsg.marketLevelName || '', // 市场级别名称
              province: this.authorizedCityMsg.province || '', // 省份
              city: this.authorizedCityMsg.city || '', // 城市
              shopArea: 0, // 门店实用面积
              shopStatus: '0', // 0有效1无效
              statusExplain: '未开业', // 状态说明（开业/未开业/撤店失效/装修失效）
              brandList: [],
              shopBrandList: [],
              shopName: '',
              fileList1: [],
              fileList2: [],
              fileList3: [],
              fileList4: [],
              fileList5: [],
              fileList6: [],
              fileList7: [],
              area: '',
              street: '',
              shopFloor: '',
              shopFloorNumber: '',
              shopTel: '',
              channel: '',
              managementModel: '',
              contactPerson: '',
              independentDoorHeadFlag: null, // 独立外立面门头
              ifSubsidy: '0',
              subsidyAmount: 0.0,
              decorationTime: '',
              openingTime: '',
              planOpeningTime: '', // 计划开业时间
              shopRent: '',
              contactPersonPhone: '',
              contactPersonIdcard: '',
              ifChildrenSupportingArea: '0',
              childrenSupportingArea: '0',
              longitude: '',
              latitude: ''
            }
          ]
        }
      }
    }
  },
  computed: {
    ...mapGetters('dealershipTransfer', ['isOA'])
  },
  mounted() {
    // this.addDerucci()
    // this.getShopFLowData()
  },
  methods: {
    storeNatureFilter(list) {
      if (!this.isOA && this.isV6) {
        const V6filterList = ['寝具店', '客餐卧综合店', '大家居店']
        return list.filter(item => V6filterList.includes(item.content))
      }
      return list
    },
    checkBrandIsDerucciClassic,
    handleIsOldShop(data) {
      // console.log(data)
      this.IsOldShop = data.code
      if (this.isOA) return
      if (data.code === '0') {
        this.shopList[0].parentShopNumber = ''
        // this.shopList[0].oldShopId = ''
        // this.$emit('update:oldShopId', '')
      } else if (data.code === '1') {
        this.shopList[0].shopNumber && (this.shopList[0].shopNumber = '')
      }
      // this.$emit('update:ifOldShopId', data.code)
      // this.shopList[0].ifOldShopId = data.code
    },
    // 获取门店编号
    async getShopFLowData() {
      const res = await shopFLowData({
        businessLicenseId: this.authorizedCityMsg.businessLicenseId,
        authorizedCityCode: this.authorizedCityMsg.authorizedCityCode
      })
      this.shopNumberList = res.data
    },
    // 获取门店编号数据
    handleSelectShopNumber(shopNumberData) {
      // console.log(shopNumberData)
      // this.$emit('update:oldShopId', shopNumberData.id)
      this.shopArea = shopNumberData.shopArea
      for (const key in shopNumberData) {
        if (key === 'shopName') {
          // const shopName = `${data.city || ''}${data.area || ''}${data.street ||
          //   ''}${data.shopTypeValue || ''}${data.shopFloor ||
          //   ''}${data.shopFloorNumber || ''}（${this.brandName.join('/') ||
          //   ''}）专卖店`
          // console.log(this.shopTitleBrand);
        } else {
          this.shopList[0][key] = shopNumberData[key]
        }
      }
      // 新店代理品牌
      // this.shopList[0].brandList = shopNumberData.shopBrandDetailsList.map(
      //   item => item.brandId
      // )

      // this.$parent.form.oldShopId = shopNumberData.shopNumber
    },
    // 文件预览
    handlePreview({ url }) {
      window.open(url)
    },
    brandFilterDataList(list, data) {
      return storeNatureBrandListFilter(list, data.storeNature, data.brandList)
    },
    changeShopCategory(value, data) {
      if (!value.value && data.shopCategory) {
        data.shopCategory = ''
      }
    },
    changeShopType(data, row) {
      if (['SJ004', 'SJ003'].includes(data)) {
        // this.$set(row, 'shopTypeValue', '')
        row.shopTypeValue = ''
        row.shopTypeThree = ''
      }
      // row.storeTypeContent = data.content
      // this.$nextTick(()=>{
      //   row.shopTypeValue = ''
      // })
    },
    // 添加慕思品牌
    addDerucci() {
      this.shopList.push({
        index:
          this.shopList.length === 0
            ? 0
            : this.shopList[this.shopList.length - 1].index + 1,
        // oldShopId: '',
        ifOldShopId: '0',
        o1dShopId: '',
        ifParentShopNumber: '0',
        parentShopNumber: '',
        shopNumber: '',
        bossId: this.businessMsg.bossId,
        storeNature: '',
        shopType: '',
        shopTypeValue: '',
        shopTypeThree: '',
        ifRenovation: '1', // 是否装修
        authorizedCityCode: this.authorizedCityMsg.authorizedCityCode || '', // 授权城市编号
        authorizedCityName: `${this.authorizedCityMsg.city || ''}${
          this.authorizedCityMsg.area || ''
        }`, // 授权城市名称
        marketLevelCode: this.authorizedCityMsg.marketLevelCode || '', // 市场级别编号
        marketLevelName: this.authorizedCityMsg.marketLevelName || '', // 市场级别名称
        province: this.authorizedCityMsg.province || '', // 省份
        city: this.authorizedCityMsg.city || '', // 城市
        shopArea: 0, // 门店实用面积
        shopStatus: '0', // 0有效1无效
        statusExplain: '未开业', // 状态说明（开业/未开业/撤店失效/装修失效）
        brandList: [],
        shopBrandList: [],
        shopName: '',
        fileList1: [],
        fileList2: [],
        fileList3: [],
        fileList4: [],
        fileList5: [],
        fileList6: [],
        fileList7: [],
        area: '',
        street: '',
        shopFloor: '',
        shopFloorNumber: '',
        shopTel: '',
        channel: '',
        managementModel: '',
        contactPerson: '',
        independentDoorHeadFlag: null, // 独立外立面门头
        ifSubsidy: '0',
        subsidyAmount: 0.0,
        decorationTime: '',
        openingTime: '',
        shopRent: '',
        contactPersonPhone: '',
        contactPersonIdcard: '',
        ifChildrenSupportingArea: '0',
        childrenSupportingArea: '0',
        latitude: '',
        longitude: ''
      })
      // 展开新增列
      // this.expands.push(this.shopList[this.shopList.length - 1].index + 1)
    },
    // 删除慕思品牌
    delDerucci() {
      const shopList = this.shopList.filter(
        item => !this.rowId.includes(item.index)
      )
      // shopList.forEach((item, index) => {
      //   item.index = index
      // })
      this.rowId = []
      this.shopList = shopList

      // this.$emit('update:tableShopList', shopList)
    },
    handleSelectionChange(val) {
      this.rowId = val.map(item => {
        return item.index
      })
    },
    getRowKeys(row) {
      return row.index + 1
    },
    // 添加加盟品牌
    getBusinessBrand(e, data, index) {
      if (!e) {
        this.$set(this.shopList[0], 'shopBrandList', [])
        return
      }
      // 判断该品牌是否存在
      const shopBrandList = []
      const dataBrandList = data.shopBrandList
      e.length > 0 &&
        e.forEach(item => {
          if (dataBrandList.find(i => i.brandId == item.brandId)) {
            const obj = dataBrandList.find(i => i.brandId == item.brandId)
            // 如果含有此对象 直接赋值
            shopBrandList.push({
              shopBrand: item.name,
              ...obj
            })
          } else {
            shopBrandList.push({
              businessLicenseId: this.businessMsg.id,
              bossId: this.businessMsg.bossId,
              sampleSets: '',
              brandArea: '',
              ifEnabled: 0,
              brandId: item.brandId,
              shopBrand: item.name,
              updateReason: ''
            })
          }
        })
      // // ------------------------------------------------
      this.titleBrand[index] = e
      const filterTitleBrand = []
      this.titleBrand?.length > 0 &&
        this.titleBrand.forEach(item => {
          filterTitleBrand.push(...item)
        })
      const result = {}
      const finalResult = []
      for (let i = 0; i < filterTitleBrand.length; i++) {
        result[filterTitleBrand[i].name] = filterTitleBrand[i]
      }
      for (const item in result) {
        finalResult.push(result[item])
      }
      // if (this.shopTitleBrand.length > 0) {
      //   this.isUpdateReason = ['慕思经典', '慕思儿童'].some(classicName => {
      //     return this.shopTitleBrand.join('').includes(classicName)
      //   })
      // } else {
      //   this.isUpdateReason = false
      // }
      this.filterTitleBrand = finalResult
      // this.$emit('update:shopTitleBrand', this.filterTitleBrand)
      // // ------------------------------------------------
      // setTimeout(() => {
      const resetData = [
        {
          key: 'shopBrandList',
          value: shopBrandList
        }
      ]
      resetData?.length > 0 &&
        resetData.forEach(item => {
          this.$set(data, item.key, item.value)
        })
      // }, 100)
    },
    handleUpload(fileList, fileName, index) {
      this.shopList[index][fileName] = fileList.map(item => {
        return {
          ...item.response,
          functionAttribution: `${fileName}-${index}`
        }
      })
    },
    // 获取门店名称
    getShopName(data, index) {
      // console.log(data)
      const brandName = []
      brandName.push(
        ...data.brandList.map(item => {
          if (this.authorizedBrandList.length > 0) {
            const d = this.authorizedBrandList.find(i => i.brandId == item)
            if (d && d.name) {
              return d.name
            }
          } else {
            return []
          }
        })
      )
      // if(this.dictionaryList.find(i=>i.code === data.shopTypeValue)) {
      //   data.shopTypeValue = this.dictionaryList.find(i=>i.code === data.shopTypeValue).content
      //   console.log(data.shopTypeValue);
      // }
      const shopName = `${data.city || ''}${data.area || ''}${
        data.street || ''
      }${data.shopTypeValue || ''}${data.shopFloor || ''}${
        data.shopFloorNumber || ''
      }（${brandName.join('/') || ''}）专卖店`
      // console.log(shopName);
      // data.shopName = shopName
      // console.log(this.shopList[index].shopName);
      this.shopList[index].shopName = shopName
      // this.selectBrand = brandName
      brandName.length > 0 && this.$emit('update:shopTitleBrand', brandName)
      return shopName
    },
    // 获取门店地址
    getShopAddress(data) {
      const shopAddress = `${data.province || ''}${data.city || ''}${
        data.area || ''
      }${data.street || ''}${data.shopTypeValue || ''}${data.shopFloor || ''}${
        data.shopFloorNumber || ''
      }`
      return shopAddress
    },
    // 获取区域列表
    getArea(data) {
      // console.log(data)
      let area = this.shopArea ? this.shopArea : 0
      data.shopBrandList.length > 0 &&
        data.shopBrandList.forEach(item => {
          area += Number(item.brandArea)
        })
      data.shopArea = area
    },
    // 获取门店性质
    getShopBrandType(data) {
      // console.log(data)
      if (data.length == 0) return '品牌店'
      const brandArr = ['慕思通配', '慕思时尚', '慕思助眠']
      const newBrandData = []
      data.length > 0 &&
        data.forEach(item => {
          //   检验是否'芯睡眠','助眠系列'
          if (!brandArr.includes(item.bigBrandName)) {
            newBrandData.push(item)
          }
        })
      return newBrandData.length > 1 ? '综合店' : '品牌店'
    },
    handleBlur(e, data) {
      data.shopRent = Number(e.target.value).toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.formItem {
  width: 20%;
}

.tip {
  color: $danger-dark-color;
}

.selecItem {
  margin: 0;

  &.is-error {
    margin-bottom: 22px;
  }
}

::v-deep .el-select {
  width: 100%;
}

.el-form-item {
  ::v-deep {
    .el-input.is-disabled {
      .el-input__inner {
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 10px;
      }
    }

    .el-input {
      .el-input__inner[type='number'] {
        padding-right: 0;
      }
    }
  }
}

@media (max-width: 702px) {
  .formItem {
    width: 100%;
  }
}

@media screen and (min-width: 702px) and (max-width: 1260px) {
  .formItem {
    width: calc(33% - 10px);
  }
}

@media (min-width: 1260px) {
  .formItem {
    width: calc(25% - 10px);
  }
}
</style>
