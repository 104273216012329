<template>
  <flow-form
    ref="flowFormRef"
    :model="form"
    :on-submit="onSubmit"
    :get-detail="getDetail"
    title="区域加盟申请表"
    :tips="[
      '1、区域加盟申请表：该地区无经销商代理申请的慕思品牌，现经销商第一次申请加盟',
      '2、如加盟的城市在“授权城市”选项没有找到对应的城市，请使用企业微信联系招商部郭惠新增',
      '3、如经销商在此地区已经申请加盟了慕思其他品牌，现在用不同的执照申请其他品牌，该流程依旧适用',
      '4、如经销商在此地区已经申请加盟了慕思其他品牌，现在用同样的执照申请其他品牌，请走“老经销商增加新系列申请表”'
    ]"
  >
    <RequiredFormItemInput
      v-for="(value, key) in formLayout.title"
      :key="key"
      delay-update
      class="formItem"
      :prop="`dealerDeclarationForm[${key}]`"
      :value="
        key === 'title'
          ? (form.dealerDeclarationForm.title = title)
          : form.dealerDeclarationForm[key]
      "
      :label="value"
      placeholder="归档后生成"
      disabled
      :required="false"
    />
    <!-- 招商单号 -->
    <RequiredFormItemInput
      v-model="form.zsOrderNumber"
      label="招商单号"
      class="formItem"
      prop="zsOrderNumber"
      placeholder="归档后生成"
      disabled
    />
    <RequiredFormItemInput
      v-for="(value, key) in formLayout.optUser"
      :key="key"
      v-model="form.dealerDeclarationForm[key]"
      delay-update
      :placeholder="placeholder[key] || null"
      class="formItem"
      :prop="`dealerDeclarationForm[${key}]`"
      :label="value"
      disabled
    />
    <RequiredFormItemInput
      delay-update
      class="formItem"
      label="最终售达方编号"
      :value="form.finalLicenseNumber"
      placeholder="归档后生成"
      disabled
    />
    <RequiredFormItemInput
      delay-update
      class="formItem"
      label="OA账号"
      :value="form.dealerBoss.bossNumber"
      placeholder="归档后生成"
      disabled
    />
    <RequiredFormItemInput
      delay-update
      class="formItem"
      label="经销商平台账号"
      :value="form.dealerBoss.bossNumber"
      placeholder="归档后生成"
      disabled
    />
    <RequiredFormItemInput
      delay-update
      class="formItem"
      label="送达方编码"
      :value="form.finalSenderNumber"
      placeholder="归档后生成"
      disabled
    />
    <!-- <RequiredFormItemInput
        delay-update
        class="formItem"
        placeholder="归档后生成"
        :required="false"
        disabled
        v-model="form.dealerBusinessLicense4[key]"
        v-for="(value, key) in formLayout.dealerBusinessLicense4"
        :key="value"
        :label="value"
    />-->
    <HeaderTitle
      id="anchor-yyzzxx"
      style="margin-top: 20px"
      title="营业执照信息"
    />
    <RequiredFormItemInput
      delay-update
      :placeholder="placeholder['creditCode'] || null"
      class="formItem"
      prop="dealerBusinessLicense.creditCode"
      :value="form.dealerBusinessLicense.creditCode"
      label="统一社会信用代码"
      strong
      :validations="validations.creditCode"
      @blur="e => (form.dealerBusinessLicense.creditCode = e.target.value)"
    />
    <RequiredFormItemInput
      v-model="form.dealerBusinessLicense.businessLicenseName"
      delay-update
      :placeholder="placeholder['businessLicenseName'] || null"
      class="formItem"
      prop="dealerBusinessLicense.businessLicenseName"
      label="营业执照名称"
      :disabled="isLicenseDisabled"
    />
    <RequiredFormItemInput
      v-model="form.dealerBusinessLicense.businessLicenseShortName"
      delay-update
      :placeholder="placeholder['businessLicenseShortName'] || null"
      class="formItem"
      prop="dealerBusinessLicense.businessLicenseShortName"
      label="简称"
      :disabled="isLicenseDisabled"
    />

    <RequiredModuleFormItemSelect
      v-model="form.dealerBusinessLicense.businessLicenseType"
      class="formItem"
      prop="dealerBusinessLicense.businessLicenseType"
      label="营业执照类型"
      type="business_license_type"
      :disabled="isLicenseDisabled"
      label-tip="
一、营业执照类型为有限责任公司的，就通过一般纳税人查询官网确认纳税类型是否为一般纳税人
  一般纳税人查询官网：http://www.foochen.com/zty/ybnsr/yibannashuiren.html
  1、若属于一般纳税人，纳税类型选择一般纳税人，对应的发票类型为增值税专用发票；
  2、若不属于一般纳税人，纳税类型选择小规模纳税人，对应的发票类型为增值税电子发票；
二、营业执照类型为个体工商户的，则对应的纳税类型为定额纳税，对应的发票类型为增值税电子发票
"
      @change="onChangeSelectedBusinessLicenseType"
    />

    <RequiredFormItemInput
      v-model="form.dealerBusinessLicense.registeredCapital"
      delay-update
      :placeholder="placeholder['registeredCapital'] || null"
      class="formItem"
      prop="dealerBusinessLicense.registeredCapital"
      label="注册资本（万元)"
      type="number"
      :disabled="isLicenseDisabled"
    />

    <component
      :is="
        key === 'registerDate'
          ? 'RequiredFormItemDatePicker'
          : 'RequiredFormItemInput'
      "
      v-for="(value, key) in formLayout.dealerBusinessLicense"
      :key="key"
      v-model="form.dealerBusinessLicense[key]"
      delay-update
      :placeholder="placeholder[key] || null"
      class="formItem"
      :prop="`dealerBusinessLicense[${key}]`"
      :label="value"
      :validations="validations[key] || []"
      :disabled="isLicenseDisabled"
    />
    <div>
      <RequiredFormItemUploadFile
        v-model="form.businessLicenseFileList"
        prop="businessLicenseFileList"
        class="formItem"
        label="上传营业执照"
        limit-size="400"
      >
        <div slot="describe">
          <p style="color: red">先上传营业执照可智能识别出营业执照信息。</p>
          （营业执照照片/电子档）
        </div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
    </div>

    <HeaderTitle
      id="anchor-jxsgrjcxx"
      style="margin-top: 20px"
      title="经销商个人基础信息"
    />
    <RequiredFormItemInput
      v-model="form.dealerBoss.idCard"
      delay-update
      class="formItem"
      prop="dealerBoss.idCard"
      label="身份证"
      :validations="validations['idCard'] || []"
      :disabled="isLicenseDisabled"
    />
    <RequiredFormItemInput
      v-for="(value, key) in formLayout.userInfo2"
      :key="key"
      v-model="form.dealerBoss[key]"
      delay-update
      :placeholder="placeholder[key] || null"
      class="formItem"
      :prop="`dealerBoss[${key}]`"
      :label="value"
      :validations="validations[key] || []"
      :disabled="idCartDisabled"
    />
    <RequiredModuleFormItemSelect
      v-model="form.dealerBoss.education"
      class="formItem"
      prop="dealerBoss.education"
      label="学历"
      type="education"
      :disabled="isLicenseDisabled"
      :required="false"
    />
    <RequiredFormItemInput
      v-model="form.dealerBoss.age"
      delay-update
      :placeholder="placeholder['age'] || null"
      class="formItem"
      prop="dealerBoss.age"
      label="年龄"
      type="number"
      min="1"
      :validations="validations['age']"
      :disabled="isLicenseDisabled"
    />
    <RequiredModuleFormItemSelect
      v-model="form.dealerBoss.maritalStatus"
      prop="dealerBoss.maritalStatus"
      class="formItem"
      label="婚姻状况"
      type="marital_status"
      :disabled="isLicenseDisabled"
      :required="false"
    />
    <RequiredFormItemInput
      v-model="form.dealerBoss.nativePlace"
      delay-update
      :placeholder="placeholder['nativePlace'] || null"
      class="formItem"
      prop="dealerBoss.nativePlace"
      label="籍贯"
      :disabled="isLicenseDisabled"
    />
    <RequiredModuleFormItemSelect
      v-model="form.dealerBoss.sex"
      class="formItem"
      prop="dealerBoss.sex"
      label="性别"
      type="sex"
      :disabled="isLicenseDisabled"
    />
    <!-- <RequiredModuleFormItemSelect
      class="formItem"
      prop="dealerDeclarationForm.dealerType"
      v-model="form.dealerDeclarationForm.dealerType"
      label="经销商类型"
      type="dealer_type"
    />-->
    <RequiredModuleFormItemSelect
      v-model="form.dealerCrmDistributor.channelType"
      class="formItem"
      prop="dealerCrmDistributor.channelType"
      label="经销商类型"
      type="dealer_type"
      network
    />

    <RequiredModuleFormItemSelect
      ref="secondaryChannelTypeRef"
      v-model="form.dealerCrmDistributor.secondaryChannelType"
      prop="dealerCrmDistributor.secondaryChannelType"
      class="formItem"
      type="dealer_sub_type"
      label="经销商类型二级分类"
      :required="false"
      @change="
        () => {
          form.dealerCrmDistributor.thirdChannelType = ''
        }
      "
    />

    <RequiredModuleFormItemSelect
      ref="thirdChannelTypeRef"
      v-model="form.dealerCrmDistributor.thirdChannelType"
      prop="dealerCrmDistributor.thirdChannelType"
      class="formItem"
      type="dealer_thirdary_type"
      label="经销商类型三级分类"
      :parent-code="form.dealerCrmDistributor.secondaryChannelType"
      empty-disabled
      dependent-parent-code
      :required="false"
    />

    <RequiredModuleFormItemSelect
      v-model="form.dealerCrmDistributor.ifRelevance"
      class="formItem"
      prop="dealerCrmDistributor.ifRelevance"
      label="是否关联方"
      type="ifRelevance"
    />
    <RequiredModuleFormItemSelect
      v-model="form.dealerCrmDistributor.joinNature"
      class="formItem"
      prop="dealerCrmDistributor.joinNature"
      label="经销商性质"
      type="join_nature"
    />

    <!-- <RequiredModuleFormItemSelect
      class="formItem"
      prop="dealerDeclarationForm.urgentLevel"
      v-model="form.dealerDeclarationForm.urgentLevel"
      label="紧急程度"
      type="urgent_level"
    />
    <RequiredFormItemInput
      delay-update
      :placeholder="placeholder['urgentExplain'] || null"
      class="formItem"
      prop="dealerDeclarationForm.urgentExplain"
      v-model="form.dealerDeclarationForm.urgentExplain"
      v-if="Number(form.dealerDeclarationForm.urgentLevel) === 2"
      label="紧急说明"
    />-->

    <HeaderTitle id="anchor-kpxx" style="margin-top: 20px" title="开票信息" />

    <!-- :validations="validations[key] || []" -->
    <!-- placeholder="归档后生成" -->
    <RequiredFormItemInput
      v-for="(value, key) in formLayout.dealerBusinessLicense2"
      :key="value"
      v-model="form.dealerBusinessLicense[key]"
      delay-update
      class="formItem"
      :prop="`dealerBusinessLicense[${key}]`"
      :label="value"
      :disabled="isLicenseDisabled"
      :validations="validations[key] || []"
    />

    <RequiredFormItemSelect
      v-model="form.dealerBusinessLicense.payTaxesType"
      class="formItem"
      prop="dealerBusinessLicense.payTaxesType"
      label="纳税类型"
      :data-list="pay_taxes_type"
      label-key="content"
      value-key="code"
      disabled-key="disabled"
    />
    <RequiredModuleFormItemSelect
      v-model="form.dealerBusinessLicense.billType"
      class="formItem"
      prop="dealerBusinessLicense.billType"
      label="发票类型"
      module="public"
      type="invoice_type"
      disabled-key="ifDelete"
      :filter-data-list="dealerBusinessLicenseBillTypeFilterDataList"
      :get-data-list-params="{ifDelete:'-1'}"
    />
    <RequiredBankFormItemSelect
      v-model="form.dealerBusinessLicense.bank"
      :prop="`dealerBusinessLicense.bank`"
      class="formItem"
      label="开户行"
      delay-update
      :validations="validations['bank'] || []"
      :disabled="isLicenseDisabled"
      @changeSelectedItem="onChangeSelectedItemBank"
    />
    <RequiredFormItemInput
      v-model="form.dealerBusinessLicense.bankCode"
      :prop="`dealerBusinessLicense.bankCode`"
      class="formItem"
      label="开户行编码"
      :validations="validations['bankCode'] || []"
      :disabled="isLicenseDisabled"
      delay-update
    />
    <RequiredFormItemInput
      v-for="(value, key) in formLayout.dealerBusinessLicense3"
      :key="key"
      v-model="form.dealerBusinessLicense[key]"
      delay-update
      :placeholder="placeholder[key] || null"
      class="formItem"
      :prop="`dealerBusinessLicense[${key}]`"
      :label="value"
      :validations="validations[key] || []"
      :disabled="isLicenseDisabled"
    />
    <div>
      <RequiredFormItemUploadFile
        v-model="form.taxpayerProveFileList"
        prop="taxpayerProveFileList"
        class="formItem"
        label="上传附件"
        style="width: inherit"
        limit-size="400"
      >
        <div slot="describe">
          （1：一般纳税人登记证 或2：税务登记事项通知书或
          3：网站“全国企业一般纳税人资格查询截图或4：其他途径截图/照片）
        </div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>

      <RequiredFormItemUploadFile
        v-model="form.bankFileList"
        prop="bankFileList"
        class="formItem"
        label="上传附件"
        style="width: inherit"
        limit-size="400"
      >
        <div slot="describe">（开户证明或者网银截图(能显示开户银行全称)）</div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
      <RequiredFormItemUploadFile
        v-model="form.actualControllerPromiseFileList"
        prop="actualControllerPromiseFileList"
        class="formItem"
        label="上传附件"
        style="width: inherit"
        limit-size="400"
        :required="false"
      >
        <div slot="describe">
          <p>实控人承诺函</p>
          <el-button
            v-if="!isOA"
            style="margin-bottom: 10px"
            @click="downloadTemplate"
          >下载模板</el-button>
        </div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
    </div>
    <HeaderTitle
      id="anchor-ymsdhzqk"
      style="margin-top: 20px"
      title="与慕思的合作情况"
    />
    <RequiredModuleFormItemSelect
      v-model="agentType"
      class="formItem"
      style="width: inherit"
      label="与慕思的合作情况（现阶段是否在其他业务/其他有和慕思合作）"
      type="brand_management"
    />
    <!-- <el-form-item class="formItem" label="申请类型">
          <el-input v-model="form." placeholder="请输入申请类型" />
    </el-form-item>-->
    <div v-if="Number(agentType) === 1" style="margin-bottom: 10px">
      <CommonTable
        style="border: 0; border-radius: 0"
        :table-layout-direction="isMobile ? 'horizontal' : 'vertical'"
        :card-props="{ shadow: 'never', bodyStyle: 'padding: 0' }"
        :default-data-list="form.dealerCooperationRecordList"
        :columns="dealerCooperationRecordListColumns"
      />
    </div>
    <HeaderTitle
      id="anchor-xzcsjydhyqk"
      style="margin-top: 20px"
      title="现在从事经营的行业情况"
    />
    <div style="margin-top: 20px">
      <CommonTable
        style="border: 0; border-radius: 0"
        :table-layout-direction="isMobile ? 'horizontal' : 'vertical'"
        :default-data-list="form.dealerBusinessRecordList"
        :columns="dealerBusinessRecordListColumns"
        :card-props="{ shadow: 'never', bodyStyle: 'padding: 0' }"
      />
    </div>
    <HeaderTitle
      id="anchor-sqjmcsqk"
      style="margin-top: 20px"
      title="申请加盟城市情况"
    />
    <div>
      <RequiredFormItemSelect
        v-model="form.dealeShop.province"
        prop="dealeShop.province"
        class="formItem"
        :data-list="provinceList"
        label="省份"
        value-key="province"
        label-key="province"
        @change="
          () => {
            form.dealeShop.city = ''
            form.dealerJoinCity.authorizedCityCode = ''
          }
        "
      />

      <RequiredFormItemSelect
        v-model="form.dealeShop.city"
        prop="dealeShop.city"
        class="formItem"
        clearable
        :disabled="!form.dealeShop.province"
        :get-data-list="getAuthCityList"
        :get-data-list-params="form.dealeShop.province"
        label="地级市"
        value-key="city"
        label-key="city"
        :placeholder="form.dealeShop.province ? null : '请选择省份'"
        @change="
          () => {
            form.dealeShop.area = ''
            form.dealerJoinCity.authorizedCityCode = ''
          }
        "
      />
      <!-- <RequiredFormItemInput
      delay-update
      :placeholder="placeholder[key]||null"
      class="formItem"
      v-model="form.dealeShop.street"
      label="街道"
    />-->
      <RequiredFormItemSelect
        ref="authorizedCityRef"
        v-model="form.dealerJoinCity.authorizedCityCode"
        class="formItem"
        clearable
        :disabled="!form.dealeShop.city"
        prop="dealerJoinCity.authorizedCityCode"
        :get-data-list="getAuthAreaList"
        :get-data-list-params="`${form.dealeShop.province},${form.dealeShop.city}`"
        label="授权城市确认"
        value-key="authorizedCityCode"
        label-key="authorizedCityName"
        @changeSelectedItem="
          selectedItem => (authorizedSelectedItem = selectedItem)
        "
      />
      <RequiredFormItemInput
        delay-update
        placeholder="选择授权城市、授权品牌后自动带出"
        class="formItem"
        :value="form.dealeShop.marketLevelName"
        prop="dealeShop.marketLevelName"
        label="市场级别判定"
        disabled
      />
      <RequiredModuleFormItemSelect
        v-model="form.dealerCrmDistributor.shopCreateType"
        class="formItem"
        prop="dealerCrmDistributor.shopCreateType"
        label="开店类型"
        type="open_type"
      />

      <RequiredBrandFormItemSelect
        ref="dealeShopBrandRef"
        class="formItem"
        prop="applyBrandSeries"
        :value="form.applyBrandSeries"
        :value-translate="list => list.map(item => item.id)"
        :filter-data-list="applyBrandSeriesFilterDataList"
        label="授权品牌确认"
        @changeSelectedItem="
          list =>
            (form.applyBrandSeries = (list || []).map(item => ({
              ...item,
              brandName: item.name
            })))
        "
      />

      <!-- 营销中心 -->
      <RequiredFormItemInput
        v-if="isClassic"
        v-model="form.marketCenter"
        label="营销中心"
        class="formItem"
        prop="marketCenter"
        disabled
      />

      <RequiredModuleFormItemSelect
        v-model="form.dealerCrmDistributor.source"
        class="formItem"
        type="dealer_source"
        label="经销商来源"
        prop="dealerCrmDistributor.source"
        @change="
          () => {
            form.dealerCrmDistributor.sourceClassification = ''
          }
        "
      />
      <RequiredModuleFormItemSelect
        v-model="form.dealerCrmDistributor.sourceClassification"
        class="formItem"
        type="dealer_sub_source"
        :parent-code="form.dealerCrmDistributor.source"
        :get-data-trigger="form.dealerCrmDistributor.source ? ['change'] : []"
        label="经销商来源二级分类"
        empty-disabled
      />

      <!-- capitalSource: "资金来源",
    otherCapitalSource: "其他资金来源",-->
      <RequiredFormItemSelect
        v-model="form.dealerJoinCity.capitalSource"
        class="formItem"
        prop="dealerJoinCity.capitalSource"
        :data-list="['自由资金', '政策贷款', '其他']"
        label="资金来源"
        @change="
          () => {
            form.dealerJoinCity.otherCapitalSource = ''
            $refs['flowFormRef'].clearValidate(
              'dealerJoinCity.otherCapitalSource'
            )
          }
        "
      />

      <RequiredFormItemInput
        v-model="form.dealerJoinCity.otherCapitalSource"
        delay-update
        class="formItem"
        prop="dealerJoinCity.otherCapitalSource"
        label="其他资金来源"
        :disabled="form.dealerJoinCity.capitalSource !== '其他'"
        :required="form.dealerJoinCity.capitalSource === '其他'"
      />

      <RequiredFormItemInput
        v-for="(value, key) in formLayout.dealerJoinCity1"
        :key="key"
        v-model="form.dealerJoinCity[key]"
        delay-update
        :placeholder="placeholder[key] || null"
        class="formItem"
        :prop="`dealerJoinCity[${key}]`"
        :label="value"
        :type="
          ['housePurchaseQuantity', 'planShopQuantity'].includes(key)
            ? 'number'
            : 'input'
        "
        step="1"
        min="1"
      />
      <RequiredModuleFormItemSelect
        v-model="form.dealerJoinCity.modeOfOperation"
        class="formItem"
        prop="dealerJoinCity.modeOfOperation"
        label="代理店的经营方式"
        type="mode_of_operation"
      />

      <RequiredModuleFormItemSelect
        v-model="form.dealerJoinCity.managementStyle"
        class="formItem"
        prop="dealerJoinCity.managementStyle"
        label="代理店的管理方式"
        type="management_style"
      />
    </div>
    <HeaderTitle
      id="anchor-mqtdjg"
      style="margin-top: 20px"
      title="目前团队结构"
    />
    <RequiredFormItemInput
      v-for="(value, key) in formLayout.dealerTeamStructure1"
      :key="key"
      v-model="form.dealerTeamStructure[key]"
      delay-update
      :placeholder="placeholder[key] || null"
      class="formItem"
      :prop="`dealerTeamStructure[${key}]`"
      type="number"
      step="1"
      min="1"
      :label="value"
    />
    <RequiredFormItemInput
      v-for="(value, key) in formLayout.dealerTeamStructure2"
      :key="key"
      v-model="form.dealerTeamStructure[key]"
      delay-update
      :placeholder="placeholder[key] || null"
      style="width: calc(100% - 10px)"
      class="formItem"
      :prop="`dealerTeamStructure[${key}]`"
      :label="value"
    />
    <HeaderTitle
      id="anchor-sqkdmdxx"
      style="margin-top: 20px"
      title="申请开店门店信息"
    />
    <div style="margin-bottom: 10px">
      <CommonTable
        :table-layout-direction="isMobile ? 'horizontal' : 'vertical'"
        :card-props="{ shadow: 'never', bodyStyle: 'padding: 0' }"
        style="border: 0; border-radius: 0"
        :columns="dealeShopColumns"
        :default-data-list="[form.dealeShop]"
      />
    </div>
    <template v-if="dealeShopBrandListFilter.length">
      <HeaderTitle
        id="anchor-scmdmx"
        style="margin-top: 20px"
        title="生成门店明细"
      />
      <div style="margin-bottom: 10px">
        <CommonTable
          :default-data-list="dealeShopBrandListFilter"
          :columns="dealeShopBrandListColumns"
          :table-layout-direction="isMobile ? 'horizontal' : 'vertical'"
          :card-props="{ shadow: 'never', bodyStyle: 'padding: 0' }"
          style="border: 0; border-radius: 0"
        />
      </div>
    </template>
    <template v-if="Number(form.dealeShop.ifRenovation) === 1">
      <div>
        <RequiredFormItemUploadFile
          v-for="(value, key) in formLayout.uploadFileList"
          :key="key"
          v-model="form[key]"
          :prop="key"
          class="formItem"
          :label="value"
          limit-size="400"
          :required="false"
        >
          <!-- <div slot="describe">{{ value }}</div> -->
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
      </div>
      <div>
        <RequiredFormItemInput
          v-for="(value, key) in formLayout.dealeShop1"
          :key="key"
          v-model="form.dealeShop[key]"
          delay-update
          :placeholder="placeholder[key] || null"
          class="formItem"
          :prop="`dealeShop[${key}]`"
          :label="value"
          :validations="validations[key] || []"
        />
      </div>
    </template>
    <RequiredFormItemSelect
      v-if="isV6"
      v-model="form.dealeShop.shopSubsidyFlag"
      class="formItem"
      label="是否补贴"
      :required="false"
      label-tip="V6填写字段，非V6招商可忽略。V6家居所提供的经销商新店装修补贴支持，只补贴净增长部分面积，如若当该授权区域的门店在一年内（按自然日计算）发生了撤店行为，导致门店面积未实现净增长，所获得的装修补贴金额V6家居将全额扣回，且按照所享受补贴金额的10%进行追加处罚"
      :data-list="[
        { label: '是', value: true },
        { label: '否', value: false }
      ]"
      label-key="label"
      value-key="value"
    />
    <RequiredFormItem
      v-if="isV6 && form.dealeShop.shopSubsidyFlag"
      prop="dealeShop.subsidyAmount"
      class="formItem"
      label="补贴金额（万）"
    >
      <el-input-number
        v-model="form.dealeShop.subsidyAmount"
        :max="9999"
        :min="0"
        :controls="false"
      />
    </RequiredFormItem>
    <div>
      <RequiredFormItemSelect
        v-model="form.dealeShop.childrenSupportingAreaFlag"
        class="formItem"
        label="是否有儿童配套专区"
        :required="false"
        :data-list="[
          { label: '是', value: true },
          { label: '否', value: false }
        ]"
        label-key="label"
        value-key="value"
        @changeSelectedItem="handleChildren"
      />
      <RequiredFormItem
        v-if="form.dealeShop.childrenSupportingAreaFlag"
        prop="dealeShop.childrenSupportingArea"
        class="formItem"
        label="面积（平方米）"
        :rules="[
          {
            validator: regNonzero,
            trigger: 'blur'
          }
        ]"
      >
        <el-input-number
          v-model="form.dealeShop.childrenSupportingArea"
          :max="9999"
          :min="0"
          :controls="false"
        />
      </RequiredFormItem>
    </div>
    <header-title style="margin-top: 20px" title="货物及快递收货信息" />
    <div style="margin-bottom: 10px">
      <component
        :is="
          value.moduleSelectType
            ? 'RequiredModuleFormItemSelect'
            : 'RequiredFormItemInput'
        "
        v-for="(value, key) in formLayout.warehouseAndDelivery"
        :key="key"
        v-model="form.warehouseAndDelivery[key]"
        delay-update
        class="formItem"
        :label="value.label"
        :prop="`warehouseAndDelivery[${key}]`"
        :type="value.moduleSelectType"
        :validations="validations[key] || []"
      />
      <template v-if="isOverseas">
        <RequiredModuleFormItemSelect
          v-model="form.dealerBusinessLicense.reconciliationAccount"
          prop="dealerBusinessLicense.reconciliationAccount"
          class="formItem"
          label="统驭科目"
          type="reconciliation_account"
          @changeSelectedItem="
            e => {
              form.dealerBusinessLicense.reconciliationAccountS = e
                ? e.content
                : ''
            }
          "
        />
        <RequiredModuleFormItemSelect
          v-model="form.dealerBusinessLicense.salesDepartmentCode"
          prop="dealerBusinessLicense.salesDepartmentCode"
          class="formItem"
          label="销售部门"
          type="overseas_department"
          @changeSelectedItem="
            e => {
              form.dealerBusinessLicense.salesDepartmentS = e ? e.content : ''
            }
          "
        />
        <RequiredModuleFormItemSelect
          v-model="form.dealerBusinessLicense.tradingItem"
          prop="dealerBusinessLicense.tradingItem"
          class="formItem"
          label="交易条款"
          type="exchange_provision"
          @changeSelectedItem="
            e => {
              form.dealerBusinessLicense.tradingItemS = e ? e.content : ''
            }
          "
        />
        <RequiredModuleFormItemSelect
          v-model="form.dealerBusinessLicense.paymentCondition"
          prop="dealerBusinessLicense.paymentCondition"
          class="formItem"
          label="结算方式"
          type="settlement_type"
          @changeSelectedItem="
            e => {
              form.dealerBusinessLicense.paymentConditionS = e ? e.content : ''
            }
          "
        />
      </template>
    </div>

    <HeaderTitle
      id="anchor-pptgfjppbzjsq"
      style="margin-top: 20px"
      title="品牌推广费及品牌保证金收取"
    />
    <div style="margin-bottom: 10px">
      <CommonTable
        :default-data-list="form.dealerMarginRecordList"
        :columns="dealerMarginRecordListColumns"
        :table-layout-direction="isMobile ? 'horizontal' : 'vertical'"
        :card-props="{ shadow: 'never', bodyStyle: 'padding: 0' }"
        style="border: 0; border-radius: 0"
      />
    </div>
    <remark-item
      v-model="form.dealerDeclarationForm.remark"
      style="margin-top: 40px"
      :file-list="form.fileRecordList"
      @onUpload="
        file => {
          form.fileRecordList = file.map(item => item.response)
        }
      "
    />
    <AnchorNavigate
      v-if="!isOA"
      :options="[
        { id: 'anchor-yyzzxx', title: '营业执照信息' },
        { id: 'anchor-jxsgrjcxx', title: '经销商个人基础信息' },
        { id: 'anchor-kpxx', title: '开票信息' },
        { id: 'anchor-ymsdhzqk', title: '与慕思的合作情况' },
        { id: 'anchor-xzcsjydhyqk', title: '现在从事经营的行业情况' },
        { id: 'anchor-sqjmcsqk', title: '申请加盟城市情况' },
        { id: 'anchor-mqtdjg', title: '目前团队结构' },
        { id: 'anchor-sqkdmdxx', title: '申请开店门店信息' },
        { id: 'anchor-scmdmx', title: '生成门店明细' },
        { id: 'anchor-pptgfjppbzjsq', title: '品牌推广费及品牌保证金收取' },
        { id: 'anchor-bz', title: '备注' }
      ]"
    />
  </flow-form>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import lodash from 'lodash'

import {
  requestGetCityLevel,
  getProvinceOrCityOrAreaList,
  requestGetSizeBarListByDept
} from '@/api/common/'
import * as dealerJoinApi from '@/api/attractPlatform/dealerJoinApi'
import { getShopList } from '@/api/common/index'
import { getBusinessDataList } from '@/api/businessOos/index'
import { ocrBizlicense } from '@/api/flow/index'

import { creditCodeReg, userIdReg } from '@/utils/useFormValidator'
import { storeNatureBrandListFilter } from '@/utils/flow'

import CommonTable from '@/components/TablePro/Table.vue'
import { render } from '@/components/TablePro/fn'

import UploadFile from '@/components/UploadFile/index'
import GoBack from '@/components/goBack'
import AnchorNavigate from '@/components/anchorNavigate.vue'
import RequiredFormItem from '@/components/CustomFormItem/requiredFormItem.vue'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequiredBrandFormItemSelect from '@/components/CustomFormItem/requiredBrandFormItemSelect.vue'
import RequiredBankFormItemSelect from '@/components/CustomFormItem/requiredBankFormItemSelect.vue'
import moduleData from '@/components/CustomFormItem/data/module.data.js'
import PopupForm, { renderFormItem } from '@/components/FormPro/PopupForm.vue'
import QQMap from '@/components/Map/qqmap'
import RemarkItem from './components/remarkItem'

import HeaderTitle from './components/headerTitle'
import FlowForm from './components/flowForm'

import TipsText from './data/tips_text.js'
import { checkBrandIsDerucciClassic } from './data/checkBrandIsDerucciClassic'

import { common } from '@/mixin/mixin'

const validations = {
  realName: ['name'],
  contactInfo: ['phone', 'check_boss_number'],
  idCard: ['user_id'],
  tencentQq: ['email'],
  creditCode: ['credit_code'],
  // taxpayerPhone: ['phone'],
  bankAccount: ['bank_account'],
  personLiableName: ['name'],
  personLiablePhone: ['phone'],
  eMail: ['email'],
  renovationLiablePerson: ['name'],
  renovationPersonPhone: ['phone'],
  contactPerson: ['name'],
  contactPersonIdcard: ['user_id'],
  contactPersonPhone: ['phone'],
  warehouseUser: ['name'],
  warehousePhone: ['phone'],
  legalPersonContact: ['phone'],
  age: ['age'],
  // accountName: ['name'],
  account: ['bank_account'],
  shopTel: ['phone']
}

const originalForm = {
  orderSpecialist: '', // 订单专员
  orderSpecialistNumber: '', // 订单专员联系方式
  marketCenter: '', // 营销中心
  marketCenterCode: '', // 营销中心
  applyBrandSeries: [], // 授权品牌
  // 经销商数据
  dealerCrmDistributor: {
    channelType: '', // 经销商分类
    secondaryChannelType: '', // 经销商二级分类
    thirdChannelType: '', // 经销商三级分类
    source: '', // 经销商来源
    sourceClassification: '', // 经销商来源二级分类
    ifRelevance: '', // 是否关联方
    joinNature: '', // 经营性质
    shopCreateType: '' // 开店类型
  },
  // 经销商个人基础信息
  dealerDeclarationForm: {
    id: '', // 表单id，如果有则返回，如果没有则代表新建
    title: '', // 标题
    orderNumber: '', // 单号
    optJobNumber: '', // 提单人工号
    optUserId: '', // 提单人id
    optUserName: '', // 提单人工号
    optDeptId: '', // 提单部门id
    optDeptName: '', // 提单部门
    optTime: moment().format('YYYY-MM-DD hh:mm:ss'), // 提单日期
    dealerType: '', // 0新经销商1老经销商
    urgentLevel: '', // 0正常1紧急
    urgentExplain: '',
    statusExplain: '',
    remark: '' // 备注文本
  },
  // 经销商个人基础信息
  dealerBoss: {
    bossNumber: '', // 实控人账号
    realName: '', // 经销商（实际控股人）
    contactInfo: '', // 联系方式
    idCard: '', // 身份证
    tencentQq: '', // QQ电邮
    education: '', // 学历
    age: '', // 年龄
    maritalStatus: '', // 0未婚1已婚2离异
    nativePlace: '',
    sex: '', // 性别'0保密1男2女
    createTime: moment().format('YYYY-MM-DD hh:mm:ss')
  },
  // 营业执照信息
  dealerBusinessLicense: {
    businessLicenseName: '', // 营业执照名称
    businessLicenseType: '', // 营业执照类型
    creditCode: '', // 社会统一信用代码
    registeredCapital: '', // 注册资本
    legalPersonName: '', // 法人姓名
    legalPersonContact: '', // 法人联系方式
    registerDate: '', // 注册日期
    businessTerm: '', // 营业期限
    businessLicenseAddress: '', // 营业执照地址
    businessScope: '', // 经营范围
    registrationAuthority: '', // 登记机关
    // 开票信息
    taxpayerPhone: '', // 纳税人电话
    bank: '', // 开户行
    bankCode: '', // 开户行编号
    bankAccountName: '', // 开户名
    payTaxesType: '', // 纳税类型
    billType: '', // 专票/普票
    personLiableName: '', // 税务负责人姓名
    personLiablePhone: '', // 税务负责人电话
    oaAccount: '', // OA账号
    createTime: moment().format('YYYY-MM-DD hh:mm:ss'),
    eMail: '', // 接收电子发票邮箱
    bankAccount: '', // 银企直连账户
    licenseNumber: '', // 售达方
    certificatesRecAddress: '', // 函证接收地址

    reconciliationAccountS: '', // 统驭科目 名称
    reconciliationAccount: '', // 统驭科目
    tradingItemS: '', // 交易条款 名称
    tradingItem: '', // 交易条款
    paymentConditionS: '', // 付款条件 名称
    paymentCondition: '', // 付款条件
    businessLicenseShortName: '', //	营业执照简称
    salesDepartmentCode: '', // 销售部门编码
    salesDepartmentS: '' // 销售部门名称
  },
  // 与慕思的合作情况
  dealerCooperationRecordList: [],
  // 现在从事经营的行业情况
  dealerBusinessRecordList: [],
  // 申请加盟城市情况
  dealerJoinCity: {
    authorizedCityCode: '', // 授权城市编号
    createShopType: '', // 开店类型
    permanentPopulationQuantity: '', // 常住人口数量
    flowPopulationQuantity: '', // 流动人口数量
    localGdp: '', // 当地GDP
    perCapitaGdp: '', // 人均GDP
    plannedInvestment: '', // 计划投入资金
    capitalSource: '', // 资金来源
    otherCapitalSource: '', // 其他资金来源
    joinSource: '', // 加盟信息来源
    urbanPopulation: '', // 城镇人口数量
    perCapitaIncome: '', // 人均可支配收入
    housePurchaseQuantity: '', // 今年市场交楼数量
    houseAveragePrice: '', // 房价均价
    planShopQuantity: '', //* 首次计划开店数
    advCost: '', // 广告费用
    managementStyle: '', // 代理店的管理方式
    modeOfOperation: '', // 代理店的经营方式
    createTime: moment().format('YYYY-MM-DD hh:mm:ss')
  },
  // 目前团队结构
  dealerTeamStructure: {
    storePersonnelNumber: '', // 门店销售及管理人员
    marketPersonnelNumber: '', // 市场策划及推广人员
    servicePersonnelNumber: '', // 售后服务人员
    logisticsPersonnelNumber: '', // 仓库及物流管理人员
    furnitureStore: '', // 高端家具商场数量
    joinBrand: '', // 加入当地品牌数量
    joinReason: '' // 选择慕思原因
  },
  // 门店信息
  dealeShop: {
    channel: '', // 渠道类型
    // channelClassification: "", //经销商类型二级分类

    shopTypeValue: '', // 门店类型二级分类
    displayShopType: '', // 展示店类型
    shopPositionLevel: '', // 门店位置等级

    regionAreaCode: '', // 门店区域
    regionAreaName: '', // 门店区域
    storeNature: '', // 门店性质
    ifRenovation: '1', // 是否装修（1是，0否）
    partnership: '',
    shopType: '',
    shopName: '', // 门店名称
    shopAlias: '', // 门店简称
    authorizedCityCode: '', // 授权城市编号
    authorizedCityName: '', // 授权城市名称
    marketLevelCode: '', // 市场级别编号
    marketLevelName: '', // 市场级别名称
    province: '', // 省份
    city: '', // 城市
    area: '', // 地区
    street: '', // 街道
    shopFloor: '',
    shopFloorNumber: '', // 门店铺号
    shopArea: '', // 门店实用面积
    openingTime: '', // 计划装修时间
    planOpeningTime: '', // 计划开业时间
    managementModel: '', // 经营模式
    contactPerson: '', // 联系人
    contactPersonIdcard: '', // 联系人身份证
    contactPersonPhone: '', // 联系人电话
    renovationLiablePerson: '', // 装修对接人姓名
    renovationPersonPhone: '', // 装修对接人电话
    statusExplain: '', // 状态说明（开业/未开业/撤店失效/装修失效）
    shopRent: '', // 门店租金
    shopTel: '', // 门店联系方式
    independentDoorHeadFlag: '', // 独立外立面门头标记
    shopSubsidyFlag: false,
    subsidyAmount: '',
    oldShopId: '',
    oldShopFlag: false,
    oldShopNumber: '',
    parentShopNumberFlag: false, // 是否已有物理店
    parentShopNumber: '', // 物理店编号
    childrenSupportingAreaFlag: false,
    childrenSupportingArea: '',
    shopTypeThree: '', // 渠道性质三级分类编号
    shopTypeThreeS: '' // 渠道性质三级分类名称
  },
  // 门店品牌信息
  dealeShopBrandList: [],
  dealerMarginRecordList: [
    {
      type: '', // 保证金类型
      remittanceAmount: '', // 汇款金额（万）
      remittanceDate: '', // 汇款日期
      // remittanceDate: moment().format("YYYY-MM-DD hh:mm:ss"), //汇款日期
      remittanceUser: '', // 付款人
      accountName: '', // 开户名
      account: '', // 账号
      bank: '', // 开户行
      financeConfirmStatus: '', // 0未到账1已到账
      paymentTime: '', // 入账时间
      fileList: [] // 文件列表
    }
  ],
  // 营业执照照片/电子档
  businessLicenseFileList: [],
  // （1：一般纳税人登记证 或2：税务登记事项通知书或 3：网站“全国企业一般纳税人资格查询截图或4：其他途径截图/照片）
  taxpayerProveFileList: [],
  // 开户证明或者网银截图(能显示开户银行全称)
  bankFileList: [],
  // 图纸，考察报告，考察谈话录音
  drawingAndReportAndTalkFileList: [],
  // 承诺说明书，商场店面图纸
  promiseAndStoreDrawingFileList: [],
  actualControllerPromiseFileList: [],
  senderName: '', // 送达方
  finalBusinessLicenseName: '', // 最终售达方名称
  finalLicenseNumber: '', // 最终售达方编号 OA账号
  finalBossAccount: '', // 实控人账号
  finalSenderNumber: '', // 最终送达方编号，多个送达方采用 "-" 进行分割
  warehouseAndDelivery: {
    warehouseUser: '', // 仓库收货人
    warehousePhone: '', // 联系方式
    warehouseAddress: '', // 仓库收货地址
    deliveryAddress: '', // 运输目的地
    deliveryType: '', // 运输方式
    transportType: '', // 运输条件
    paymentConditionCode: 'Z013' // 付款条件
  },
  fileRecordList: [], // 备注文件

  shopOutsideFileList: [], // 店铺含外围照片不低于5张或视频
  shopMarketInsideLocationFileList: [], // 商场平面图标注慕思店位置
  shopCADFileList: [], // 人流动向店面CAD图纸
  shopLeaseContractFileList: [], // 店铺租赁合同
  integrityCommitmentFileList: [], // 廉洁承诺书
  bankStatementFileList: [], // 银行流水
  teamPhotoFileList: [], // 团队照片
  independentShopDoorHeaderFileList: [], // 独立店标注门头位置
  // dealerOrLegalPersonIDCardFileList: [], // '经销商法人身份证
  openShopPromiseFileList: [], // 开店承诺书
  vmdDesignFileList: [], // VMD设计需求表
  dealerBestsignCertificatePhotoFileList: [], // 经销商上上签认证截图
  cityDealerAgreementFileList: [], // 一城多商的协议
  newBusinessContractFileList: [] // '新商合同'
}

export default {
  components: {
    UploadFile,
    HeaderTitle,
    AnchorNavigate,
    GoBack,
    RequiredFormItem,
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RequiredFormItemDatePicker,
    RequiredFormItemUploadFile,
    RequiredModuleFormItemSelect,
    RequiredBrandFormItemSelect,
    RequiredBankFormItemSelect,
    FlowForm,
    PopupForm,
    QQMap,
    RemarkItem,
    CommonTable
  },
  mixins: [common],
  data() {
    const currDayTime = moment().startOf('day').valueOf()
    const that = this
    return {
      form: JSON.parse(JSON.stringify(originalForm)),
      provinceList: [],
      agentType: '2',
      isdisplayShopType: '', // 是否展示店
      brandList: [],
      authorizedSelectedItem: null,
      shopTypeSelectedItem: null,

      formLayout: {
        title: {
          title: '标题',
          orderNumber: '单号'
        },

        optUser: {
          optJobNumber: '提单人工号',
          optUserName: '提单人',
          optDeptName: '提单部门',
          optTime: '提单日期'
        },
        // userInfo1: {
        //   idCard: "身份证"
        // },
        userInfo2: {
          realName: '实际控股人',
          contactInfo: '实际控股人联系方式'
          // tencentQq: "电子邮箱"
        },
        dealerBusinessLicense: {
          // registeredCapital: "注册资本（万元)",
          legalPersonName: '法人',
          legalPersonContact: '法人联系方式',
          registerDate: '注册日期',
          businessTerm: '营业期限',
          businessLicenseAddress: '营业执照地址（住所）',
          businessScope: '经营范围',
          registrationAuthority: '登记机关',
          certificatesRecAddress: '函证接收地址'
        },
        dealerBusinessLicense2: {
          businessLicenseName: '账户名称',
          creditCode: '纳税人识别号',
          businessLicenseAddress: '营业执照地址',
          taxpayerPhone: '纳税人电话'
        },
        dealerBusinessLicense3: {
          bankAccount: '银行账号',
          personLiableName: '税务负责人',
          personLiablePhone: '税务负责人电话',
          eMail: '接受电子发票邮箱'
        },
        // dealerBusinessLicense4: {
        //   // bankAccount: "银企直连账户",
        //   businessLicenseName: "售达方",
        //   finalLicenseNumber: "OA账户"
        // },
        dealerJoinCity1: {
          // permanentPopulationQuantity: "所在地区常住人口数量（万人）",
          // flowPopulationQuantity: "所在地区流动人口数量（万人）",
          localGdp: '请输入当地GDP（亿元）',
          perCapitaGdp: '请输入人均GDP（万元/人）',
          plannedInvestment: '计划投入资金（万元）',
          // joinSource: "加盟信息来源",
          urbanPopulation: '城镇人口数量（万人）',
          perCapitaIncome: '人均可支配收入（万元/人）',
          housePurchaseQuantity: '今年市场交楼数量',
          houseAveragePrice: '房价均价（万元）',
          planShopQuantity: '首次计划开店数',
          advCost: '计划投入广告费用（万元）'
        },
        dealerTeamStructure1: {
          storePersonnelNumber: '门店销售及管理人员 （）人',
          marketPersonnelNumber: '市场策划及推广（）人',
          servicePersonnelNumber: '售后服务（）人',
          logisticsPersonnelNumber: '仓库及物流管理（）人'
        },
        dealerTeamStructure2: {
          furnitureStore: '所在城市中/高端家居商场有',
          joinBrand: '你知道的进入当地市场的同类品牌有'
          // joinReason: '简述选择慕思的原因'
        },
        dealeShop1: {
          renovationLiablePerson: '装修对接人',
          renovationPersonPhone: '装修对接人电话'
        },
        contactFormObj: {
          contactPerson: '二级分销商',
          contactPersonIdcard: '二级分销商身份证',
          contactPersonPhone: '二级分销商联系方式'
        },
        warehouseAndDelivery: {
          warehouseUser: { label: '仓库收货人' },
          warehousePhone: { label: '联系方式' },
          warehouseAddress: { label: '仓库收货地址' },
          deliveryAddress: { label: '运输目的地' },
          deliveryType: {
            label: '运输方式',
            moduleSelectType: 'delivery_type'
          },
          transportType: {
            label: '运输条件',
            moduleSelectType: 'transport_type'
          },
          paymentConditionCode: {
            label: '付款条件',
            moduleSelectType: 'settlement_type'
          }
        },
        uploadFileList: {
          promiseAndStoreDrawingFileList: '经销商/法人身份证',
          shopOutsideFileList: '店铺含外围照片不低于5张或视频',
          shopMarketInsideLocationFileList: '商场平面图标注慕思店位置',
          shopCADFileList: '人流动向店面CAD图纸',
          shopLeaseContractFileList: '店铺租赁合同',
          integrityCommitmentFileList: '廉洁承诺书',
          bankStatementFileList: '银行流水',
          teamPhotoFileList: '团队照片',
          independentShopDoorHeaderFileList: '独立店标注门头位置',
          openShopPromiseFileList: '开店承诺书',
          vmdDesignFileList: 'VMD设计需求表',
          dealerBestsignCertificatePhotoFileList: '经销商上上签认证截图',
          cityDealerAgreementFileList: '一城多商的协议',
          newBusinessContractFileList: '新商合同'
        }
      },
      validations,
      licenseDetail: null,
      idCartDetail: null,
      placeholder: {
        registrationAuthority: '请填写营业执照右下方登记机关红章名称',
        bank: '详细至营业点，**分行/分理处',
        certificatesRecAddress: '实控人接收慕思财务下发函证资料地址',
        nativePlace: '广东东莞',
        capitalSource: '自有资金/政策贷款/其他',
        oaAccount: '归档后生成',
        advertisingInvestment: '年度广告投入具体单位新增（万）单位',
        businessTotalTime: '已从事时间指年份',
        shopFloor: '填写示例:1层',
        shopFloorNumber: '填写示例:1号'
      },
      // 最小时间
      pickerOptions: {
        disabledDate(time) {
          return currDayTime > time.getTime()
        }
      },
      TipsText,
      // 其他加盟流程选择过的品牌系列
      otherSelectedBrandList: [],
      parentShopNumberRules: [
        {
          validator(rule, value, callback) {
            if (value?.length !== 7 || !/^[0-9]+\.?[0-9]*$/.test(value)) {
              return callback('请输入7位数字物理店编号')
            }
            callback()
          },
          trigger: 'blur'
        }
      ],

      selectLocationColumns: [
        {
          name: '_latitude_longitude',
          search: {
            transform(e) {
              return e
                ? {
                  latitude: e.latitude,
                  longitude: e.longitude
                }
                : {}
            }
          },
          renderFormItem: renderFormItem((h, context) => {
            return (
              context.$attrs.value && (
                <QQMap
                  ref='mapRef'
                  center={
                    context.$attrs.value.longitude ? context.$attrs.value : null
                  }
                  onOnChange={e => {
                    context.$listeners.input(e)
                  }}
                />
              )
            )
          })
        }
      ],
      dealerCooperationRecordListColumns: [
        {
          width: 55,
          title: render((h, context) => {
            return (
              <span
                class='el-icon-circle-plus iconfont'
                onClick={this.addDealerCooperationRecordList}
              />
            )
          }),
          render: render((h, context) => {
            return (
              <span
                class='el-icon-remove iconfont'
                onClick={this.removeTabelItem.bind(
                  null,
                  this.form.dealerCooperationRecordList,
                  context.index
                )}
              />
            )
          }),
          hideInTable: that.isOA
        },
        { title: '序号', width: 55, valueType: 'index' },
        {
          title: '省份',
          minWidth: 150,
          name: 'province',
          render: render((h, context) => {
            return (
              <RequiredFormItemSelect
                v-model={context.data.province}
                show-label={false}
                data-list={this.provinceList}
                label={context.config.title}
                value-key='province'
                label-key='province'
                onChange={() => (context.data.city = '')}
              />
            )
          })
        },
        {
          title: '地级市',
          minWidth: 150,
          name: 'city',
          render: render((h, context) => {
            return (
              <RequiredFormItemSelect
                v-model={context.data.city}
                label={context.config.title}
                show-label={false}
                value-key='city'
                label-key='city'
                get-data-list-params={context.data.province}
                get-data-list={this.getAuthCityList}
                clearable
                disabled={!context.data.province}
              />
            )
          })
        },
        {
          title: '代理城市',
          name: 'agentCity',
          minWidth: 150,
          render: render((h, context) => {
            return (
              <RequiredFormItemSelect
                v-model={context.data.agentCity}
                label='代理城市'
                show-label={false}
                value-key='authorizedCityCode'
                label-key={item =>
                  `${item.province}${item.city}${item.area || ''}`
                }
                get-data-list-params={context.data.province}
                get-data-list={this.getAuthCityList}
                clearable
                disabled={!context.data.city}
              />
            )
          })
        },
        {
          title: '代理品牌（加盟品牌）',
          name: 'agentBrand',
          minWidth: 170,
          render: render((h, context) => {
            return (
              <RequiredBrandFormItemSelect
                v-model={context.data.agentBrand}
                label='代理品牌（加盟品牌）'
                old-value-keys={['id']}
                select-props={{
                  valueKey: 'name'
                }}
                value-translate={value => {
                  return value && typeof value === 'string'
                    ? value.split(',')
                    : []
                }}
                value-output-translate={value => {
                  if (
                    Object.prototype.toString.call(value) === '[object Array]'
                  ) {
                    return value.join(',')
                  }
                  return value
                }}
                show-label={false}
              />
            )
          })
        },
        {
          title: '近三年提货情况（万元）',
          name: 'applySituation',
          render: render((h, context) => {
            return (
              <RequiredFormItemInput
                v-model={context.data.applySituation}
                delay-update
                placeholder={this.placeholder['applySituation'] || null}
                label='近三年提货情况（万元）'
                show-label={false}
              />
            )
          })
        }
      ],
      dealerBusinessRecordListColumns: [
        {
          name: 'bran',
          width: 60,
          hideInTable: that.isOA,
          tableColumnProps: {
            align: 'center'
          },
          title: render((h, context) => (
            <span
              class='el-icon-circle-plus iconfont'
              onClick={this.addDealerBusinessRecordList}
            />
          )),
          render: render((h, context) => (
            <span
              class='el-icon-remove iconfont'
              onClick={this.removeTabelItem.bind(
                null,
                this.form.dealerBusinessRecordList,
                context.index
              )}
            />
          ))
        },
        { title: '序号', width: 55, valueType: 'index' },
        {
          title: '品牌名称',
          name: 'brandName',
          minWidth: 150,
          render: render((h, context) => {
            return (
              <RequiredFormItemInput
                v-model={context.data.brandName}
                delay-update
                placeholder={this.placeholder['brandName'] || null}
                class='formItem'
                style='width: 100%'
                label='品牌名称'
                show-label={false}
              />
            )
          })
        },
        {
          title: '行业',
          name: 'industryType',
          minWidth: 250,
          render: render((h, context) => {
            return (
              <div style='display:flex;'>
                <RequiredFormItemSelect
                  v-model={context.data.industryType}
                  label='行业类型'
                  style='flex: 1'
                  show-label={false}
                  data-list={['家电', '家居建材', '装修设计', '其他']}
                />
                {context.data.industryType === '其他' && (
                  <RequiredFormItemInput
                    v-model={context.data.industryTypeOther}
                    delay-update
                    placeholder={this.placeholder['industryTypeOther'] || null}
                    style='flex: 2; margin-left: 10px'
                    label='其他行业'
                    show-label={false}
                  />
                )}
              </div>
            )
          })
        },
        {
          title: '经营开始时间',
          name: 'businessStartTime',
          minWidth: 240,
          render: render((h, context) => {
            return (
              <RequiredFormItemDatePicker
                v-model={context.data.businessStartTime}
                placeholder={`请选择${context.config.title}`}
              />
            )
          })
        },
        {
          title: '经营情况概述（年销售额）',
          name: 'businessSummary',
          minWidth: 240,
          render: render((h, context) => {
            return (
              <RequiredFormItemInput
                v-model={context.data.businessSummary}
                delay-update
                placeholder={
                  this.placeholder[context.config.name] ||
                  `请输入${context.config.title}`
                }
              />
            )
          })
        },
        {
          title: '年度广告投入',
          name: 'advertisingInvestment',
          minWidth: 240,
          render: render((h, context) => {
            return (
              <RequiredFormItemInput
                v-model={context.data.advertisingInvestment}
                delay-update
                placeholder={
                  this.placeholder[context.config.name] ||
                  `请输入${context.config.title}`
                }
              />
            )
          })
        },
        {
          title: '从事经营时间',
          name: 'businessTotalTime',
          minWidth: 240,
          render: render((h, context) => {
            return (
              <RequiredFormItemInput
                v-model={context.data.businessTotalTime}
                delay-update
                placeholder={
                  this.placeholder[context.config.name] ||
                  `请输入${context.config.title}`
                }
              />
            )
          })
        }
      ],
      dealeShopBrandListColumns: [
        { title: '序号', valueType: 'index', width: 55 },
        {
          title: '门店名称',
          name: 'name',
          renderText: text => {
            return (
              this.form.dealeShop.city +
              this.form.dealeShop.area +
              this.form.dealeShop.street +
              this.form.dealeShop.shopTypeValue +
              this.form.dealeShop.shopFloor +
              this.form.dealeShop.shopFloorNumber +
              `（${text}）专卖店`
            )
          }
        },
        { title: '申请品牌', name: 'name' },
        {
          title: '品牌面积',
          name: 'brandArea',
          render: render((h, context) => {
            return (
              <RequiredFormItemInput
                v-model={context.data.brandArea}
                delay-update
                label={context.config.title}
                placeholder={this.placeholder[context.config.name] || null}
                prop={`dealeShopBrandList.${context.data.originIndex}.brandArea`}
                type='number'
                min='1'
                show-label={false}
              />
            )
          })
        },
        {
          title: '样品套数',
          name: 'sampleSets',
          render: render((h, context) => {
            return (
              <RequiredFormItemInput
                v-model={context.data.sampleSets}
                delay-update
                label={context.config.title}
                placeholder={this.placeholder[context.config.name] || null}
                prop={`dealeShopBrandList.${context.data.originIndex}.sampleSets`}
                type='number'
                min='1'
                show-label={false}
              />
            )
          })
        },
        {
          title: '系列类型',
          name: 'updateReason',
          render: render((h, context) => {
            return (
              <RequiredModuleFormItemSelect
                v-model={context.data.updateReason}
                type='shop_change'
                required={this.checkBrandIsDerucciClassic(context.data.name)}
                prop={`dealeShopBrandList.${context.data.originIndex}.updateReason`}
                label={context.config.title}
                placeholder={this.placeholder[context.config.name] || null}
                filter-data-list={list =>
                  list.filter(item => item.status === 0)
                }
                show-label={false}
              />
            )
          })
        }
      ],
      dealeShopColumns: [
        {
          title: '是否已有物理店',
          width: 120,
          render: render((h, context) => (
            <RequiredFormItemSelect
              v-model={this.form.dealeShop.parentShopNumberFlag}
              label={context.config.title}
              class='formItem'
              show-label={false}
              label-key='label'
              value-key='value'
              disabled-key='disabled'
              data-list={[
                { label: '是', value: true },
                { label: '否', value: false }
              ]}
              onChange={this.onChangeParentShopNumberFlag}
            />
          ))
        },
        {
          title: '物理店编号',
          width: 160,
          render: render((h, context) => (
            <RequiredFormItemInput
              v-model={this.form.dealeShop.parentShopNumber}
              label={context.config.title}
              class='formItem'
              show-label={false}
              delay-update
              placeholder={
                !this.form.dealeShop.parentShopNumberFlag ? '系统生成' : null
              }
              prop='dealeShop.parentShopNumber'
              rules={this.parentShopNumberRules}
              disabled={!this.form.dealeShop.parentShopNumberFlag}
              label-tip='编码规则由6000001开始'
            />
          ))
        },
        {
          title: '门店编号',
          width: 160,
          render: render((h, context) =>
            this.form.dealeShop.oldShopFlag ? (
              <RequiredFormItemSelect
                v-model={this.form.dealeShop.oldShopNumber}
                label='门店编号'
                class='formItem'
                show-label={false}
                prop='dealeShop.oldShopNumber'
                label-key='shopNumber'
                value-key='shopNumber'
                get-data-list={this.getAreaShopList}
                get-data-list-params={this.getAuthAreaListParamsStr}
                clearable
                onChangeSelectedItem={this.onChangeShopNumber}
              />
            ) : (
              <RequiredFormItemInput
                value={
                  this.form.dealeShop.shopNumber
                    ? this.form.dealeShop.shopNumber
                    : '归档后生成'
                }
                class='formItem'
                show-label={false}
                delay-update
                disabled
                label='门店编号'
              />
            )
          )
        },
        {
          title: render((h, context) => {
            return (
              <div>
                <span>店态性质</span>
                <i
                  style='color: #000; cursor: pointer'
                  title={TipsText.storeNatureTipsText}
                  class='el-icon-question'
                />
              </div>
            )
          }),
          width: 170,
          render: render((h, context) => (
            <RequiredModuleFormItemSelect
              v-model={this.form.dealeShop.storeNature}
              class='formItem'
              prop='dealeShop.storeNature'
              label='店态性质'
              show-label={false}
              type='shop_nature_code'
              disabled={this.form.dealeShop.oldShopFlag}
              getDataListParams=
              {this.isV6
                ? {
                  moduleBrand: 'F'
                }
                : {}}>

              <p
                slot='contentBefore'
                style='color: red; line-height: 1; font-size: 0.8em; margin-bottom: 10px'>
                若为经典+沙发综合店,必须选择:客餐卧综合店
              </p>
            </RequiredModuleFormItemSelect>
          ))
        },
        {
          title: '申请品牌',
          width: 200,
          render: render((h, context) => (
            <RequiredFormItemSelect
              v-model={this.brandList}
              prop='dealeShopBrandList'
              data-list={this.form.applyBrandSeries}
              value-key='id'
              label='申请品牌'
              class='formItem'
              show-label={false}
              label-key='name'
              // filter-data-list={this.brandFilterDataList}
              disabled={!this.form.dealeShop.storeNature}
              multiple
              onChangeSelectedItem={this.onChangeBrandSelectedItem}
            />
          ))
        },
        {
          title: '门店是否需要装修',
          width: 140,
          render: render((h, context) => (
            <RequiredModuleFormItemSelect
              v-model={this.form.dealeShop.ifRenovation}
              class='formItem'
              show-label={false}
              prop='dealeShop.ifRenovation'
              value-translate={String}
              label='门店是否需要装修'
              type='renovation'
            />
          ))
        },
        {
          title: '渠道类型',
          width: 170,
          render: render((h, context) => (
            <RequiredModuleFormItemSelect
              v-model={this.form.dealeShop.channel}
              class='formItem'
              show-label={false}
              prop='dealeShop.channel'
              label='渠道类型'
              type='cooperation_code'
              disabled={this.form.dealeShop.oldShopFlag}
            />
          ))
        },
        {
          title: render((h, context) => {
            return (
              <div>
                <span>门店位置等级</span>
                <i
                  style='color: #000; cursor: pointer'
                  title={`
                根据门店所在商场位置划分为：
A+、A、B+、B、C

A+：商场中厅扶梯一圈的位置
A：面向正门，从商场中厅向后延伸的位置
B+：商场面向正门中厅A+左右两边的位置
B：靠近B+左右两边偏厅的位置
C：商场偏厅的角落位置`}
                  class='el-icon-question'
                />
              </div>
            )
          }),
          width: 160,
          render: render((h, context) => (
            <RequiredModuleFormItemSelect
              v-model={this.form.dealeShop.shopPositionLevel}
              class='formItem'
              show-label={false}
              label='门店位置等级'
              type='shop_level'
              disabled={this.form.dealeShop.oldShopFlag}
            />
          ))
        },
        {
          title: '独立外立面门头',
          width: 160,
          render: render((h, context) => (
            <RequiredModuleFormItemSelect
              v-model={this.form.dealeShop.independentDoorHeadFlag}
              class='formItem'
              show-label={false}
              prop='dealeShop.independentDoorHeadFlag'
              label='独立外立面门头'
              type='independent_door_head_flag_boolean'
              disabled={this.form.dealeShop.oldShopFlag}
            />
          ))
        },
        {
          title: '省份',
          width: 160,
          name: 'province'
        },
        {
          title: '地级市',
          width: 160,
          name: 'city'
        },
        {
          title: '区/县',
          width: 160,
          render: render((h, context) => (
            <RequiredModuleFormItemSelect
              v-model={this.form.dealeShop.regionAreaCode}
              class='formItem'
              show-label={false}
              required={false}
              label='区/县'
              type='area'
              module='public'
              parent-code={
                this.authorizedSelectedItem &&
                this.authorizedSelectedItem.cityCode
              }
              disabled={
                this.form.dealeShop.oldShopFlag || !this.authorizedSelectedItem
              }
              placeholder='请选择授权城市'
              onChangeSelectedItem={e => {
                this.form.dealeShop.regionAreaName = e ? e.content : ''
                this.form.dealeShop.area = this.form.dealeShop.regionAreaName
              }}
            />
          ))
        },
        {
          title: '道路/街道',
          width: 160,
          render: render((h, context) => (
            <RequiredFormItemInput
              v-model={this.form.dealeShop.street}
              class='formItem'
              show-label={false}
              delay-update
              required={false}
              prop={`dealeShop.street`}
              label='道路/街道'
              placeholder='示例：湖景街道科技大道 1 号'
              disabled={this.form.dealeShop.oldShopFlag}>
              <p
                slot='contentBefore'
                style='color: red; line-height: 1; font-size: 0.8em; margin-bottom: 10px'>
                请填写门店所在街道以及所在道路
              </p>
            </RequiredFormItemInput>
          ))
        },
        {
          title: '渠道性质',
          width: 170,
          render: render((h, context) => (
            <RequiredModuleFormItemSelect
              v-model={this.form.dealeShop.shopType}
              class='formItem'
              show-label={false}
              prop='dealeShop.shopType'
              label='渠道性质'
              type='store_type'
              disabled={this.form.dealeShop.oldShopFlag}
              label-tip={TipsText.shopTypeTipsText}
              onChangeSelectedItem={e => (this.shopTypeSelectedItem = e)}
              onChange={this.changeShopType}
            />
          ))
        },
        {
          title: '渠道性质二级分类（商场名称）',
          width: 280,
          render: render((h, context) =>
            ['SJ007', 'SJ001'].includes(this.form.dealeShop.shopType) ? (
              <RequiredModuleFormItemSelect
                v-model={this.form.dealeShop.shopTypeValue}
                class='formItem'
                show-label={false}
                prop='dealeShop.shopTypeValue'
                label='渠道性质二级分类（商场名称）'
                type='secondary_store_type'
                value-key='content'
                disabled={this.form.dealeShop.oldShopFlag}
                parent-code={this.form.dealeShop.shopType}
                onChange={() => {
                  this.form.dealeShop.shopTypeThree = ''
                }}
              />
            ) : (
              <RequiredFormItemInput
                v-model={this.form.dealeShop.shopTypeValue}
                prop='dealeShop.shopTypeValue'
                required={this.form.dealeShop.shopType === 'SJ008'}
                delay-update
                class='formItem'
                show-label={false}
                label='渠道性质二级分类（商场名称）'
                disabled={
                  this.form.dealeShop.oldShopFlag ||
                  ['SJ005', 'SJ004', 'SJ003'].includes(
                    this.form.dealeShop.shopType
                  ) ||
                  !this.form.dealeShop.shopType
                }
                onChange={() => {
                  this.form.dealeShop.shopTypeThree = ''
                }}
              />
            )
          )
        },
        {
          title: '渠道性质三级分类',
          width: 280,
          render: render((h, context) => (
            <RequiredModuleFormItemSelect
              v-model={this.form.dealeShop.shopTypeThree}
              class='formItem'
              show-label={false}
              required={false}
              prop='dealeShop.shopTypeThree'
              label='渠道性质三级分类'
              type='three_store_type'
              value-key='content'
              parent-content={this.form.dealeShop.shopTypeValue}
              empty-disabled
              dependent-parent-content
              onChangeSelectedItem={e => {
                this.form.dealeShop.shopTypeThreeS = e ? e.content : ''
              }}
            />
          ))
        },
        {
          title: '门店楼层',
          width: 160,
          render: render((h, context) => (
            <RequiredFormItemInput
              v-model={this.form.dealeShop.shopFloor}
              delay-update
              placeholder={this.placeholder['shopFloor'] || null}
              label='门店楼层'
              class='formItem'
              show-label={false}
              disabled={this.form.dealeShop.oldShopFlag}
              maxlength='20'
            />
          ))
        },
        {
          title: '门店铺号',
          width: 160,
          render: render((h, context) => (
            <RequiredFormItemInput
              v-model={this.form.dealeShop.shopFloorNumber}
              delay-update
              placeholder={this.placeholder['shopFloorNumber'] || null}
              prop={`dealeShop.shopFloorNumber`}
              label='门店铺号'
              class='formItem'
              show-label={false}
              disabled={this.form.dealeShop.oldShopFlag}
            />
          ))
        },
        {
          title: '门店名称',
          width: 160,
          render: render((h, context) => (
            <RequiredFormItem
              class='formItem'
              show-label={false}
              label='门店名称'>
              {
                (this.form.dealeShop.shopName = this.getShopName(
                  this.form.dealeShop,
                  this.otherShopSelectedBrandList,
                  this.form.dealeShopBrandList,
                  this.shopTypeSelectedItem
                ))
              }
            </RequiredFormItem>
          ))
        },
        {
          title: '门店经纬度',
          width: 160,
          render: render((h, context) => (
            <RequiredFormItem
              class='formItem'
              show-label={false}
              label='门店经纬度'>
              <PopupForm
                title='选择地址'
                columns={this.selectLocationColumns}
                attrs={{
                  'on-submit': params => {
                    this.form.dealeShop.longitude = params.longitude
                    this.form.dealeShop.latitude = params.latitude
                    return true
                  }
                }}
                scopedSlots={{
                  trigger: ({ onShow }) => (
                    <div>
                      {this.form.dealeShop.longitude}，
                      {this.form.dealeShop.latitude}
                      <el-button
                        v-loading-click={() =>
                          onShow({
                            _latitude_longitude: this.form.dealeShop.longitude
                              ? {
                                longitude: this.form.dealeShop.longitude,
                                latitude: this.form.dealeShop.latitude
                              }
                              : {}
                          })
                        }>
                        选择地址
                      </el-button>
                    </div>
                  )
                }}
              />
            </RequiredFormItem>
          ))
        },
        {
          title: '门店地址',
          width: 160,
          renderText: (_, data) => {
            return this.getShopAdress(this.form.dealeShop)
          }
        },
        {
          title: '门店简称',
          width: 200,
          render: render((h, context) => (
            <RequiredFormItemInput
              v-model={this.form.dealeShop.shopAlias}
              class='formItem'
              show-label={false}
              delay-update
              placeholder={this.placeholder['shopAlias'] || null}
              prop='dealeShop.shopAlias'
              label='门店简称'
              disabled={this.form.dealeShop.oldShopFlag}>
              <div slot='contentBefore'>
                <p style='color: red; line-height: 1; font-size: 0.8em; margin-bottom: 10px'>
                  授权城市+商场名称+楼层+系列/授权城市+小区名称+楼层+系列
                </p>
              </div>
            </RequiredFormItemInput>
          ))
        },
        {
          title: '门店实用面积',
          width: 160,
          renderText: () => {
            return (this.form.dealeShop.shopArea =
              this.form.dealeShopBrandList.reduce(
                (accumulator, item) =>
                  accumulator + Number(item.brandArea || 0),
                this.otherShopSelectedBrandList.reduce(
                  (accumulator, item) =>
                    accumulator + Number(item.brandArea || 0),
                  0
                )
              ))
          }
        },
        {
          title: '计划装修时间',
          width: 240,
          render: render(() => (
            <RequiredFormItemDatePicker
              v-model={this.form.dealeShop.openingTime}
              class='formItem'
              prop='dealeShop.openingTime'
              label='计划装修时间'
              show-label={false}
              date-picker-props={{ 'picker-options': this.pickerOptions }}
            />
          ))
        },
        {
          width: 160,
          title: '计划开业时间',
          render: render(h => (
            <RequiredFormItemDatePicker
              v-model={this.form.dealeShop.planOpeningTime}
              class='formItem'
              prop='dealeShop.planOpeningTime'
              label='计划开业时间'
              show-label={false}
              date-picker-props={{ 'picker-options': this.pickerOptions }}
            />
          ))
        },
        {
          title: '经营模式',
          width: 180,
          render: render(h => (
            <RequiredModuleFormItemSelect
              v-model={this.form.dealeShop.managementModel}
              class='formItem'
              prop='dealeShop.managementModel'
              label='经营模式'
              show-label={false}
              type='management_model'
              disabled={this.form.dealeShop.oldShopFlag}
            />
          ))
        },
        {
          title: '二级分销商',
          width: 160,
          hideInTable: true,
          render: render(h => (
            <RequiredFormItemInput
              v-model={this.form.dealeShop.contactPerson}
              class='formItem'
              delay-update
              placeholder={this.placeholder.contactPerson || null}
              prop={`dealeShop.contactPerson`}
              label={'二级分销商'}
              show-label={false}
              validations={validations['contactPerson'] || []}
              disabled={this.form.dealeShop.oldShopFlag}
            />
          ))
        },
        {
          title: '二级分销商身份证',
          width: 160,
          hideInTable: true,
          render: render(h => (
            <RequiredFormItemInput
              v-model={this.form.dealeShop.contactPersonIdcard}
              class='formItem'
              delay-update
              placeholder={this.placeholder.contactPersonIdcard || null}
              prop={`dealeShop.contactPersonIdcard`}
              label={'二级分销商身份证'}
              show-label={false}
              validations={validations['contactPersonIdcard'] || []}
              disabled={this.form.dealeShop.oldShopFlag}
            />
          ))
        },
        {
          title: '二级分销商联系方式',
          width: 160,
          hideInTable: true,
          render: render(h => (
            <RequiredFormItemInput
              v-model={this.form.dealeShop.contactPersonPhone}
              class='formItem'
              delay-update
              placeholder={this.placeholder.contactPersonPhone || null}
              prop={`dealeShop.contactPersonPhone`}
              label={'二级分销商联系方式'}
              show-label={false}
              validations={validations['contactPersonPhone'] || []}
              disabled={this.form.dealeShop.oldShopFlag}
            />
          ))
        },
        {
          title: '门店联系方式',
          width: 180,
          render: render(h => (
            <RequiredFormItemInput
              v-model={this.form.dealeShop.shopTel}
              delay-update
              required={false}
              class='formItem'
              prop='dealeShop.shopTel'
              label='门店联系方式'
              show-label={false}
              type='number'
              validations={
                this.form.dealeShop.shopTel ? this.validations['shopTel'] : []
              }
              disabled={this.form.dealeShop.oldShopFlag}
            />
          ))
        },
        {
          title: '门店租金（万/年）',
          width: 160,
          render: render(h => {
            return (
              <RequiredFormItemInput
                v-model={this.form.dealeShop.shopRent}
                delay-update
                class='formItem'
                placeholder={this.placeholder['shopRent'] || null}
                required={false}
                prop='dealeShop.shopRent'
                label='门店租金（万/年）'
                show-label={false}
                type='number'
                disabled={this.form.dealeShop.oldShopFlag}
              />
            )
          })
        }
      ],
      dealerMarginRecordListColumns: [
        {
          width: 55,
          hideInTable: that.isOA,
          title: render((h, context) => {
            return (
              this.form.dealerMarginRecordList.length < 3 && (
                <span
                  class='el-icon-circle-plus iconfont'
                  onClick={this.addDealerMarginRecordList}
                />
              )
            )
          }),
          render: render((h, context) => {
            return (
              context.index && (
                <span
                  class='el-icon-remove iconfont'
                  onClick={this.removeTabelItem.bind(
                    null,
                    this.form.dealerMarginRecordList,
                    context.index
                  )}
                />
              )
            )
          })
        },
        {
          title: '类型',
          name: 'type',
          width: 200,
          render: render((h, context) => {
            return (
              <RequiredModuleFormItemSelect
                v-model={context.data.type}
                class='formItem'
                prop={`dealerMarginRecordList.${context.index}.type`}
                show-label={false}
                label='类型'
                type='payment_type_code'
              />
            )
          })
        },
        {
          title: '金额（万）',
          name: 'remittanceAmount',
          width: 180,
          render: render((h, context) => {
            return (
              <RequiredFormItemInput
                prop={`dealerMarginRecordList.${context.index}.remittanceAmount`}
                class='formItem'
                rules={this.maxNumberRule(100)}
                min={0}
                label={context.config.title}
                delay-update
                type='number'
                show-label={false}
                v-model={context.data.remittanceAmount}
              />
            )
          })
        },
        {
          title: '汇款日期',
          name: 'remittanceDate',
          render: render((h, context) => {
            return (
              <RequiredFormItemDatePicker
                v-model={context.data.remittanceDate}
                prop={`dealerMarginRecordList.${context.index}.remittanceDate`}
                label='汇款日期'
                show-label={false}
              />
            )
          })
        },
        {
          title: '汇款账号',
          name: 'account',
          minWidth: 200,
          render: render((h, context) => {
            return (
              <div>
                <RequiredFormItemInput
                  v-model={context.data.accountName}
                  prop={`dealerMarginRecordList.${context.index}.accountName`}
                  show-label={false}
                  label='开户名'
                  validations={validations['accountName'] || []}
                />
                <el-divider />
                <RequiredFormItemInput
                  v-model={context.data.account}
                  prop={`dealerMarginRecordList.${context.index}.account`}
                  show-label={false}
                  label='账号'
                  validations={validations['account'] || []}
                />
                <el-divider />
                <RequiredBankFormItemSelect
                  v-model={context.data.bank}
                  prop={`dealerMarginRecordList.${context.index}.bank`}
                  show-label={false}
                  label='开户行'
                  validations={validations['bank'] || []}
                />
              </div>
            )
          })
        },
        {
          title: '款条',
          name: 'fileList',
          minWidth: 200,
          render: render((h, context) => {
            return (
              <RequiredFormItemUploadFile
                v-model={context.data.fileList}
                class='formItem'
                limit-size='400'
                prop={`dealerMarginRecordList.${context.index}.fileList`}
                auto-upload>
                <div slot='tip'>单个文件不能超过400M</div>
              </RequiredFormItemUploadFile>
            )
          })
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapGetters('commonConfig', ['bigBrandMap', 'seriesMap']),
    ...mapState({
      isOA: state => state.dealershipTransfer.isOA,
      isMobile: state =>
        state.app.device === 'mobile' || state.app.isWxworkMoblie
    }),
    // 包含慕思经典
    isClassic({ form: { applyBrandSeries }, bigBrandMap }) {
      const classicId = bigBrandMap.get('慕思经典')?.id
      console.log({ classicId, applyBrandSeries })
      return applyBrandSeries.some(item => item.parentId === classicId)
    },
    // 包含海外
    isOverseas({ form: { applyBrandSeries }, bigBrandMap }) {
      const overseaCode = bigBrandMap.get('慕思海外')?.code
      return applyBrandSeries.some(item => item.achievementCode === overseaCode)
    },
    // 包含V6
    isV6({ form: { applyBrandSeries }}) {
      return applyBrandSeries.some(item => item.name.includes('V6'))
    },
    // 标题名称获取
    title({ form, authorizedSelectedItem }) {
      let authCity = ''
      const realName = form.dealerBoss.realName || ''
      if (authorizedSelectedItem) {
        const { province, city, area } = authorizedSelectedItem
        authCity = `${province || ''}${city || ''}${area || ''}`
      }
      const brand = form.applyBrandSeries.map(item => item.name).join('，')
      const list = []
      if (authCity) {
        list.push(authCity)
      }
      if (realName) {
        list.push(realName)
      }
      if (brand) {
        list.push(`授权品牌：${brand}`)
      }
      let str = '区域加盟申请表'
      if (list.length) {
        str += `（${list.join('，')}）`
      }
      return str
    },
    // 送达方= 省市区-法人-授权品牌的大品牌,逗号分割,1,2,3
    senderName({
      form: {
        dealerBusinessLicense: { legalPersonName },
        applyBrandSeries
      },
      authorizedSelectedItem
    }) {
      let senderName = ''
      if (!this.$refs.dealeShopBrandRef) return

      const parentList = this.$refs.dealeShopBrandRef.parentList

      if (authorizedSelectedItem && legalPersonName) {
        const { city, area } = authorizedSelectedItem
        const authCity = `${city || ''}${area || ''}`

        const brand = applyBrandSeries
          .reduce((previousValue, currentValue) => {
            const parentItem = parentList.find(
              parentItem => currentValue.parentId === parentItem.id
            )
            if (parentItem) {
              if (
                !previousValue.find(
                  parentItem => currentValue.parentId === parentItem.id
                )
              ) {
                previousValue.push(parentItem)
              }
            }
            return previousValue
          }, [])
          .map(item => item.name)
          .join('，')

        if (brand) {
          senderName = `${authCity}-${legalPersonName}-授权品牌：${brand}`
        }
      }

      return senderName
    },
    isLicenseDisabled({
      licenseDetail,
      form: {
        dealerBusinessLicense: { creditCode }
      }
    }) {
      return !!licenseDetail || !creditCodeReg.test(creditCode)
    },
    idCartDisabled({
      isLicenseDisabled,
      idCartDetail,
      form: {
        dealerBoss: { idCard }
      }
    }) {
      return !!idCartDetail || !!isLicenseDisabled || !userIdReg.test(idCard)
    },
    getAuthAreaListParamsStr({
      licenseDetail,
      form: {
        dealerJoinCity: { authorizedCityCode }
      }
    }) {
      console.log(licenseDetail, authorizedCityCode)
      if (licenseDetail && authorizedCityCode) {
        return `${licenseDetail.id},${authorizedCityCode}`
      }
      return ''
    },
    otherShopSelectedBrandList({
      otherSelectedBrandList,
      form: {
        dealeShop: { oldShopFlag }
      }
    }) {
      if (!oldShopFlag) {
        return []
      }
      const otherShopSelectedBrandList = otherSelectedBrandList
        .map(item => item.shopBrandDetailsList)
        .flat()
      return otherShopSelectedBrandList
    },
    pay_taxes_type({ form }) {
      const payTaxesType = moduleData.pay_taxes_type
      switch (form.dealerBusinessLicense.businessLicenseType) {
        case '0':
          return payTaxesType.map(item => {
            return {
              ...item,
              disabled: item.code !== '1'
            }
          })
        case '1':
          return payTaxesType.map(item => {
            return {
              ...item,
              disabled: item.code === '1'
            }
          })
        default:
          return payTaxesType
      }
    },
    // 生成门店明细，需要隐藏芯睡眠、助眠系列
    dealeShopBrandListFilter({ form: { dealeShopBrandList }}) {
      return (
        dealeShopBrandList
          ?.map((item, index) => {
            item.originIndex = index
            return item
          })
          .filter(item => {
            return ![
              this.seriesMap.get('芯睡眠')?.code,
              this.seriesMap.get('助眠系列')?.code
            ].includes(item.code)
          }) || []
      )
    },
    authorizedCityCode_brandId({
      form: {
        applyBrandSeries,
        dealeShop: { authorizedCityCode }
      }
    }) {
      return `${authorizedCityCode || ''}_${
        applyBrandSeries[0]?.parentId || ''
      }`
    }
  },
  watch: {
    authorizedCityCode_brandId(value) {
      const [authorizedCityCode, brandId] = value.split('_')
      if (!authorizedCityCode || !brandId) {
        this.form.dealeShop.marketLevelCode = ''
        this.form.dealeShop.marketLevelName = ''
        return
      }
      requestGetCityLevel({
        brandId,
        authorizedCityCode
      }).then(res => {
        this.form.dealeShop.marketLevelCode =
          res.data?.[0]?.marketLevelCode ?? ''
        this.form.dealeShop.marketLevelName =
          res.data?.[0]?.marketLevelName ?? ''
      })
    },
    agentType(type) {
      if (Number(type) === 1) {
        if (!this.form.dealerCooperationRecordList.length) {
          this.addDealerCooperationRecordList()
        }
      } else {
        this.form.dealerCooperationRecordList = []
      }
    },
    authorizedSelectedItem({ authorizedCityCode, authorizedCityName } = {}) {
      this.form.dealeShop.authorizedCityCode = authorizedCityCode
      this.form.dealeShop.authorizedCityName = authorizedCityName
      this.getGetSizeBarListByDept()
    },
    'form.dealerBusinessLicense.creditCode': {
      immediate: true,
      handler(creditCode) {
        if (this.isOA) return

        if (!creditCode) return (this.licenseDetail = null)
        getBusinessDataList({
          creditCode
        })
          .then(res => {
            if (res.data.length) {
              const licenseDetail = res.data[0]
              this.licenseDetail = licenseDetail
            } else {
              this.licenseDetail = null
            }
          })
          .catch(() => {
            this.licenseDetail = null
          })
      }
    },
    'form.dealerBoss.idCard': {
      immediate: true,
      handler(idCard) {
        if (this.isOA) return
        if (this.licenseDetail) {
          return
        }
        if (!idCard) return (this.idCartDetail = null)
        dealerJoinApi
          .getBossByIdCard({
            idCard
          })
          .then(res => {
            if (res.data) {
              this.idCartDetail = res.data
            } else {
              this.idCartDetail = null
            }
          })
          .catch(() => {
            this.idCartDetail = null
          })
      }
    },
    'form.dealerBusinessLicense.legalPersonContact': {
      immediate: true,
      handler(value) {
        this.form.dealerBusinessLicense.taxpayerPhone = value
      }
    },
    // 删除授权品牌的同时,清除被删除申请开店门店信息的品牌
    'form.applyBrandSeries'(brandList) {
      const brandListIds = brandList.map(item => item.id)
      this.brandList = this.brandList.filter(id => {
        return brandListIds.includes(id)
      })
    },
    'form.dealerBusinessLicense.businessLicenseName': {
      immediate: true,
      handler(value) {
        this.form.dealerBusinessLicense.bankAccountName = value
      }
    },
    'form.dealeShop.managementModel': {
      immediate: true,
      handler(value) {
        this.dealeShopColumns.forEach(item => {
          if (
            ['二级分销商', '二级分销商身份证', '二级分销商联系方式'].includes(
              item.title
            )
          ) {
            console.log(item)
            item.hideInTable = Number(value) !== 20
          }
        })
      }
    },
    licenseDetail(licenseDetail) {
      if (licenseDetail) {
        for (const key in this.form.dealerBusinessLicense) {
          this.form.dealerBusinessLicense[key] = licenseDetail[key]
        }
        for (const key in this.form.dealerBoss) {
          if (licenseDetail[key] !== undefined) {
            this.form.dealerBoss[key] = licenseDetail[key]
          }
        }
      } else {
        this.form.dealerBusinessLicense = {
          ...originalForm.dealerBusinessLicense,
          creditCode: this.form.dealerBusinessLicense.creditCode
        }
        this.form.dealerBoss = {
          ...originalForm.dealerBoss
        }
      }
    },
    idCartDetail(idCartDetail) {
      if (idCartDetail) {
        for (const key in this.form.dealerBoss) {
          if (idCartDetail[key] !== undefined) {
            this.form.dealerBoss[key] = idCartDetail[key]
          }
        }
      } else {
        this.form.dealerBoss = {
          ...originalForm.dealerBoss,
          idCard: this.form.dealerBoss.idCard
        }
      }
    },
    'form.businessLicenseFileList'(arr) {
      // OCR识别营业执照
      if (arr.length && arr[0]['url']) {
        const params = { imgUrl: arr[0]['url'], appId: 'wx27748066d2817941' }
        ocrBizlicense(params).then((res = {}) => {
          if (Number(res.data.errcode) === 0) {
            const data = res.data
            this.form.dealerBusinessLicense.creditCode = data.reg_num // 统一社会信用代码
            this.form.dealerBusinessLicense.businessLicenseName =
              data.enterprise_name // 营业执照名称
            this.form.dealerBusinessLicense.businessLicenseType =
              data.type_of_enterprise.includes('个体工商户')
                ? data.type_of_enterprise
                : '有限责任公司' // 营业执类型
            // this.form.dealerBusinessLicense.registeredCapital =data.registered_capital//注册资本
            this.form.dealerBusinessLicense.legalPersonName =
              data.legal_representative // 法人姓名

            const registerDate = moment(
              data.registered_date.replace(/日|\s/g, '').replace(/年|月/g, '-')
            ).format('YYYY-MM-DD HH:mm:ss')
            this.form.dealerBusinessLicense.registerDate =
              registerDate.toLocaleLowerCase() === 'invalid date'
                ? ''
                : registerDate // 注册日期
            this.form.dealerBusinessLicense.businessTerm = data.valid_period // 营业期限
            this.form.dealerBusinessLicense.businessLicenseAddress =
              data.address // 营业执照地址
            this.form.dealerBusinessLicense.businessScope = data.business_scope // 经营范围
          } else {
            if (Number(res.data.errcode) === 101003) return
            this.$message.warning(
              '您上传的营业执照未能识别成功，请检查营业执照图片是否清晰或手工填写信息。'
            )
          }
        })
      }
    }
  },
  methods: {
    checkBrandIsDerucciClassic,
    downloadTemplate() {
      const a = document.createElement('a')
      const href =
        'https://derucci-app-obs.iderucci.com/upload/20230316/9383d56cc758ef48b96c72b7ab1603df.docx'
      const title = '9383d56cc758ef48b96c72b7ab1603df.docx'
      a.setAttribute('href', href)
      a.setAttribute('download', title)
      a.click()
      a.remove()
    },
    maxNumberRule(maxNumber = 100) {
      return [
        {
          validator(rule, value, callback) {
            if (Number(value) >= maxNumber) {
              return callback(`请输入小于${maxNumber}的数字`)
            }
            callback()
          },
          trigger: 'blur'
        }
      ]
    },
    getGetSizeBarListByDept() {
      if (!this.authorizedSelectedItem) {
        this.form.marketCenter = ''
        this.form.marketCenterCode = ''
      } else {
        return requestGetSizeBarListByDept({
          provinceCode: this.authorizedSelectedItem.provinceCode,
          cityCode: this.authorizedSelectedItem.cityCode,
          areaCode: this.authorizedSelectedItem.areaCode,
          townCode: this.authorizedSelectedItem.townCode,
          deptId: '1390103620495798273'
        }).then(res => {
          this.form.marketCenter = res.data.smallName
          this.form.marketCenterCode = res.data.smallCode
        })
      }
    },
    // otherShopBrandFilterDataList(list) {
    //   const otherShopBrandIds = this.otherShopSelectedBrandList.map(item => item.brandId)
    //   return list.filter(item => !otherShopBrandIds.includes(item.id))
    // },
    onChangeSelectedItemBank(bankItem) {
      this.form.dealerBusinessLicense.bankCode = bankItem ? bankItem.CODE : ''
    },
    getShopAdress(row) {
      // 获取门店地址
      const {
        province = '',
        city = '',
        area = '',
        street = '',
        shopTypeValue = '',
        shopFloor = '',
        shopFloorNumber = ''
      } = row
      const content = this.shopTypeSelectedItem?.content || ''
      return (
        province +
        city +
        area +
        street +
        content +
        shopTypeValue +
        shopFloor +
        shopFloorNumber
      )
    },
    getShopName(
      shopData,
      otherShopSelectedBrandList,
      dealeShopBrandList,
      shopTypeSelectedItem
    ) {
      const mergeBrandList = [
        ...otherShopSelectedBrandList,
        ...dealeShopBrandList
      ].reduce((previousValue, currentValue) => {
        const flag = previousValue.some(
          item => item.brandId === currentValue.brandId
        )
        if (!flag) {
          previousValue.push(currentValue)
        }
        return previousValue
      }, [])
      return (
        shopData.city +
        shopData.area +
        shopData.street +
        shopData.shopTypeValue +
        shopData.shopFloor +
        shopData.shopFloorNumber +
        `（${mergeBrandList
          .map(item => {
            return item.brandName || item.name
          })
          .join('/')}）专卖店`
      )
    },
    brandFilterDataList(list) {
      return storeNatureBrandListFilter(
        list,
        this.form.dealeShop.storeNature,
        this.brandList
      )
    },
    storeNatureFilterDataList(list) {
      if (!this.isOA && this.isV6) {
        return list.filter(item => {
          return ['寝具店', '客餐卧综合店', '大家居店'].includes(item.content)
        })
      }
      return list
    },
    applyBrandSeriesFilterDataList(list) {
      if (this.form.applyBrandSeries.length) {
        return list.filter(item => {
          return item.parentId === this.form.applyBrandSeries[0].parentId
        })
      }
      return list
    },
    dealerBusinessLicenseBillTypeFilterDataList(list) {
      if (list.length === 0) return []
      let tempList = list
      if (this.isOA) {
        return tempList
      }
      switch (String(this.form.dealerBusinessLicense.payTaxesType)) {
        case '0':
          // 一般纳税人
          tempList = list.filter(item => {
            return ['1', '5'].includes(item.code)
          })
          break
        case '1':
        case '2':
          // 定额纳税人
          // 小规模纳税人
          tempList = list.filter(item => {
            return ['2', '6'].includes(item.code)
          })
          break
      }

      if (!tempList.some(item => item.code === this.form.dealerBusinessLicense.billType)) {
        this.form.dealerBusinessLicense.billType = ''
      }

      return tempList
    },
    onPreview(file) {
      window.open(file.url)
    },
    onChangeShopNumber(shopData) {
      if (!shopData) return
      this.form.dealeShop.oldShopId = shopData.id
      // 草稿箱可能把这个字段干掉了
      if (!this.form.dealeShop.shopRent) {
        this.form.dealeShop.shopRent = ''
      }
      for (const key in this.form.dealeShop) {
        if (shopData[key]) {
          this.form.dealeShop[key] = shopData[key]
        }
      }
    },
    onChangeBrandSelectedItem(brandList) {
      this.form.dealeShopBrandList = (brandList || []).map(brandItem => {
        const currItem = this.form.dealeShopBrandList.find(
          item => item.brandId === brandItem.id
        )
        const { id, ...filterBrandItem } = brandItem
        return {
          brandId: brandItem.id,
          ...filterBrandItem,
          ...(currItem || {})
        }
      })
    },
    // 修改营业执照类型,自动带出纳税类型
    onChangeSelectedBusinessLicenseType(e) {
      switch (String(e)) {
        case '0':
          this.form.dealerBusinessLicense.payTaxesType = '1'
          break
        case '1':
          if (this.form.dealerBusinessLicense.payTaxesType === '1') {
            this.form.dealerBusinessLicense.payTaxesType = ''
          }
          break
        default:
          this.form.dealerBusinessLicense.payTaxesType = ''
      }
    },

    // 添加慕思品牌
    addDealerCooperationRecordList() {
      this.form.dealerCooperationRecordList.push({
        province: '',
        city: '',
        bossId: '',
        agentCity: '',
        shopType: '',
        agentSituation: '',
        agentBrand: '',
        applySituation: ''
      })
    },
    // 添加现在从事经营的行业情况
    addDealerBusinessRecordList() {
      this.form.dealerBusinessRecordList.push({
        brandName: '', // 品牌名称
        industryType: '', // 行业类型
        industryTypeOther: '', // 选择其他时的文本
        businessStartTime: '',
        businessSummary: '',
        advertisingInvestment: '',
        businessTotalTime: ''
      })
    },
    // 添加品牌推广费及品牌保证金收取
    addDealerMarginRecordList() {
      this.form.dealerMarginRecordList.push({
        type: '', // 保证金类型
        remittanceAmount: '', // 汇款金额（万）
        // remittanceDate: "", //汇款日期
        remittanceDate: moment().format('YYYY-MM-DD hh:mm:ss'), // 汇款日期
        remittanceUser: '', // 付款人
        accountName: '', // 开户名
        account: '', // 账号
        bank: '', // 开户行
        financeConfirmStatus: '', // 0未到账1已到账
        paymentTime: '', // 入账时间
        fileList: [] // 文件列表
      })
    },
    // 删除表格
    removeTabelItem(data, index) {
      data.splice(index, 1)
    },
    getAuthCityList({ getDataListParams: province }) {
      if (!province) return []
      return getProvinceOrCityOrAreaList({
        type: 'city',
        province
      }).then(({ data }) => data)
    },
    getAuthAreaList({ getDataListParams: dataString }) {
      const [province, city] = dataString.split(',')
      if (!province || !city) return []
      return getProvinceOrCityOrAreaList({
        type: 'area',
        province,
        city
      }).then(({ data }) => data)
    },
    async getAreaShopList({ getDataListParams: dataString }) {
      const [businessLicenseId, authorizedCityCode] = dataString.split(',')
      if (!businessLicenseId || !authorizedCityCode) {
        return []
      }
      const { data } = await getShopList({
        businessLicenseId,
        authorizedCityCode
      })
      this.otherSelectedBrandList = data
      return data
    },
    changeShopType() {
      this.form.dealeShop.shopTypeValue = ''
      this.form.dealeShop.shopTypeThree = ''
      this.$nextTick(() => {
        this.$refs['flowFormRef'].$refs['ruleForm'].clearValidate(
          'dealeShop.shopTypeValue'
        )
      })
    },
    handleChildren({ value }) {
      if (!value && this.form.dealeShop.childrenSupportingArea > 0) {
        this.form.dealeShop.childrenSupportingArea = 0
      }
    },
    onChangeParentShopNumberFlag(e) {
      if (!e && this.form.dealeShop.parentShopNumber) {
        this.form.dealeShop.parentShopNumber = ''
      }
    },
    async onSubmit(type) {
      const time = moment().format('YYYY-MM-DD hh:mm:ss')
      this.form.dealerBoss.createTime = time
      this.form.dealerBusinessLicense.createTime = time
      this.form.dealerDeclarationForm.optTime = time
      this.form.senderName = this.senderName
      // 如果选择慕思经典任意系列，需在品牌额外添加慕思助眠、芯睡眠
      const findTargetId = this.bigBrandMap.get('慕思经典')?.id
      const existTargetCodeInApplyBrandSeries = this.form.applyBrandSeries
        .filter(item => {
          return ![this.seriesMap.get('慕思经典-新渠道').code].includes(
            item.code
          )
        })
        .some(item => {
          return item.parentId === findTargetId
        })
      const existTargetCodeInDealeShopBrandList =
        this.form.dealeShopBrandList.some(item => {
          return item.parentId === findTargetId
        })

      if (existTargetCodeInApplyBrandSeries) {
        this.form.applyBrandSeries = lodash.unionBy(
          this.form.applyBrandSeries,
          [this.seriesMap.get('助眠系列'), this.seriesMap.get('芯睡眠')],
          'code'
        )
      }

      if (existTargetCodeInDealeShopBrandList) {
        this.form.dealeShopBrandList = lodash.unionBy(
          this.form.dealeShopBrandList,
          [this.seriesMap.get('助眠系列'), this.seriesMap.get('芯睡眠')].map(
            ({ id, ...item }) => {
              return {
                brandId: id,
                updateReason: '1',
                sampleSets: '0',
                brandArea: '0',
                ...item
              }
            }
          ),
          'code'
        )
      }

      if (!this.isClassic) {
        this.form.marketCenter = ''
        this.form.marketCenterCode = ''
      }

      const { data } = await dealerJoinApi.saveDraft(this.form)
      if (type !== 'submit') {
        return formId
      }
      const formId = data.id
      const formData = new FormData()
      formData.append('formId', formId)
      return dealerJoinApi.submit(formData)
    },
    async getProvince() {
      return (this.provinceList = await getProvinceOrCityOrAreaList({
        type: 'province'
      }).then(res => res.data))
    },
    async getDetail(type, cachedFormData) {
      let initFormData = cachedFormData
      // 编辑状态
      if (type === 'detail') {
        const { data } = await dealerJoinApi.detail({
          formId: this.$route.query.id
        })
        initFormData = data
      }

      if (initFormData) {
        try {
          this.brandList = initFormData.dealeShopBrandList.map(
            item => item.brandId
          )
          this.agentType = initFormData.dealerCooperationRecordList.length
            ? '1'
            : '2'
        } catch (error) {
          console.error(error)
        }
        for (const key in this.form) {
          if (initFormData[key] !== undefined) {
            this.form[key] = initFormData[key]
          }
        }
      }
      if (this.userInfo && !this.isOA) {
        const {
          optUserId,
          optJobNumber,
          optUserName,
          optDeptId,
          optDeptName,
          ...rest
        } = this.form.dealerDeclarationForm || {}
        this.form.dealerDeclarationForm = {
          ...rest,
          optUserId: optUserId || this.userInfo.accountId,
          optJobNumber: optJobNumber || this.userInfo.code,
          optUserName: optUserName || this.userInfo.realName,
          optDeptId: optDeptId || this.userInfo.deptId,
          optDeptName: optDeptName || this.userInfo.deptName
        }
      }
      await this.getProvince()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.tip {
  color: $danger-dark-color;
}
</style>
