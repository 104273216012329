<template>
  <div>
    <div class="tip">
      <div>
        1、老经销商增加新系列申请表：经销商在该地区已代理慕思品牌，现申请其他品牌授权
      </div>

      <div>2、如经销商以新执照代理新品牌，请走“区域加盟申请表”</div>

      <div>3、如经销商以原执照代理新品牌，该流程依旧适用</div>
    </div>
    <BaseMessage
      :value="form.title"
      :title="form.title ? form.title : title"
      :zsOrderNumber="form.zsOrderNumber"
      :number="orderNumber" />
    <!-- <GoBack /> -->
    <el-form
      class="flow-form"
      :model="form"
      :inline="true"
      ref="ruleForm"
      label-position="top"
      @validate="handleValidate">
      <HeaderTitle title="经销商个人基础信息" />
      <BaseFormSelect :value="form.optJobNumber" label="提单人工号" readonly />
      <BaseFormSelect :value="form.optUserName" label="提单人" readonly />
      <BaseFormSelect :value="form.optDeptName" label="提单部门" readonly />
      <BaseFormSelect :value="optTime" label="提单日期" readonly />
      <BusinessItemSelect
        v-model="form.businessLicenseId"
        @onChange="handleChangeBusiness"
        :initSuccess="handleSuccess"
        prop="businessLicenseId"
        ref="businessSelect" />
      <BaseFormSelect
        :value="businessMsg.businessLicenseName"
        label="售达方名称"
        disabled />
      <BaseFormSelect
        :value="shopBrandNumber"
        placeholder="归档后自动生成"
        label="送达方编号"
        disabled />
      <BaseFormSelect
        :value="businessMsg.realName"
        label="经销商（实际控股人）"
        disabled />
      <BaseFormSelect
        :value="businessMsg.contactInfo"
        label="经销商（实际控股人）联系方式"
        disabled />
      <!-- <BaseFormSelect :value="businessMsg.education" label="学历" disabled /> -->
      <CommonSelect
        class="formItem"
        label="学历"
        :value="businessMsg.education"
        type="education"
        placeholder=""
        :required="false"
        :selectProps="{
          disabled: true
        }" />
      <BaseFormSelect :value="businessMsg.age" label="年龄" disabled />
      <!-- <BaseFormSelect :value="businessMsg.maritalStatus" label="婚姻状况" disabled /> -->
      <CommonSelect
        class="formItem"
        label="婚姻状况"
        :value="businessMsg.maritalStatus"
        type="marital_status"
        placeholder=""
        :required="false"
        :selectProps="{
          disabled: true
        }" />
      <BaseFormSelect :value="businessMsg.nativePlace" label="籍贯" disabled />
      <!-- <BaseFormSelect :value="businessMsg.sex" label="性别" disabled /> -->
      <CommonSelect
        class="formItem"
        label="性别"
        :value="businessMsg.sex == 0 ? '未知' : businessMsg.sex"
        type="sex"
        placeholder=""
        :required="false"
        :selectProps="{
          disabled: true
        }" />
      <BaseFormSelect :value="businessMsg.idCard" label="身份证" disabled />
      <BaseFormSelect
        v-if="businessMsg.tencentQq || businessMsg.eMail"
        :value="businessMsg.tencentQq || businessMsg.eMail"
        label="QQ/电邮"
        disabled />
      <HeaderTitle title="申请增加新系列城市情况" />
      <required-form-item-select
        prop="authorizedCityCode"
        class="formItem"
        label="授权城市确认"
        :dataList="authorizedCityList"
        v-model="form.authorizedCityCode"
        :labelKey="item => `${item.city}${item.area || ''}${item.town || ''}`"
        valueKey="authorizedCityCode"
        @changeSelectedItem="
          value => {
            authorizedCityMsg = value ? value : {}
            handleChangeAuthorizedCity(value)
          }
        "
        @onChangeItem="
          value => {
            form.brands = []
            brandData = []
            marketLevelName = ''
          }
        " />
      <!-- <BaseFormSelect
        :value="
          $refs.brandSelect &&
          $refs.brandSelect.saveBrandList.map(item => item.name).join(',')
        "
        label="已代理品牌"
        disabled /> -->
      <el-form-item label="已代理品牌" class="formItem">
        <div style="min-height: 33px">
          {{
            $refs.brandSelect &&
            $refs.brandSelect.saveBrandList.map(item => item.name).join(',')
          }}
        </div>
      </el-form-item>
      <BrandSelect
        label="新系列品牌确认"
        ref="brandSelect"
        filter
        hiddenHas
        v-model="form.brands"
        :authorizedCityCode="form.authorizedCityCode"
        :businessLicenseId="form.businessLicenseId"
        :hiddenBrandsIds="defaultNewSeriesHiddenBrandIds"
        :isOA="isOA"
        prop="brands"
        :status="form.status"
        @onChangeSelectedItem="
          data => {
            getBrands(data)
          }
        " />
      <BaseFormSelect
        :value="form.marketLevelName"
        label="市场级别评定"
        disabled />
      <BaseFormSelect
        v-if="marketCenterData && marketCenterData.smallName"
        :value="marketCenterData.smallName"
        label="营销中心"
        disabled />
      <BaseFormSelect
        :value="authorizedCityMsg.province"
        label="省份"
        disabled />
      <BaseFormSelect :value="authorizedCityMsg.city" label="地级市" disabled />
      <el-form-item
        v-if="isBigBrandWithClassic"
        class="formItem"
        label="近三年提货情况（元）">
        <div>
          <div
            v-for="(item, index) in isOA ? form.remarkMap : remarkMap"
            :key="index">
            <div>{{ item.year_desc }}年</div>
            <el-input :value="item.ship_inv_rb_add_amt_cur" readonly />
          </div>
        </div>
      </el-form-item>
      <el-form-item
        v-if="isBigBrandWithClassic"
        class="formItem"
        label="同级别市场排名">
        <div>
          <div
            v-for="(item, index) in isOA ? form.remarkMap : remarkMap"
            :key="index">
            <div>{{ item.year_desc }}年</div>
            <el-input :value="item.row_index" readonly></el-input>
          </div>
        </div>
      </el-form-item>
      <required-form-item-input
        v-if="isBigBrandWithClassic"
        prop="planOpeningCount"
        class="formItem"
        v-model="form.planOpeningCount"
        label="计划开店数量" />
      <required-form-item-input
        v-if="isBigBrandWithClassic"
        prop="planOpeningArea"
        class="formItem"
        v-model="form.planOpeningArea"
        label="计划开店面积" />
      <requiredFormItemSelect
        v-if="isBigBrandWithClassic"
        prop="cityDealerCont"
        label="一城多商情况"
        class="formItem"
        v-model="form.cityDealerCont"
        :dataList="[
          {
            label: '一城一商',
            value: '一城一商'
          },
          {
            label: '一城二商',
            value: '一城二商'
          },
          {
            label: '一城三商',
            value: '一城三商'
          },
          {
            label: '一城四商',
            value: '一城四商'
          }
        ]"
        labelKey="label"
        valueKey="value" />
      <required-form-item-input
        v-if="isBigBrandWithClassic && form.cityDealerCont !== '一城一商'"
        :required="true"
        prop="cityDealerContRemark"
        class="formItem"
        v-model="form.cityDealerContRemark"
        label="多商备注（含 B 商已代理系列、基本业绩情况等）" />
      <required-form-item-input
        v-if="isBigBrandWithClassic"
        class="formItem"
        :value="
          isOA ? (form.ifReassembly ? '是' : '否') : ifReassembly ? '是' : '否'
        "
        label="是否有到期未重装店面"
        disabled />
      <div v-if="!isBigBrandWithClassic">
        <HeaderTitle title="营业执照信息" />
        <BaseFormSelect
          :value="businessMsg.businessLicenseName"
          label="营业执照名称"
          disabled />
        <!-- <BaseFormSelect :value="businessMsg.businessLicenseType" label="营业执照类型" disabled /> -->
        <CommonSelect
          class="formItem"
          label="营业执照类型"
          :value="businessMsg.businessLicenseType"
          type="business_license_type"
          placeholder=""
          :required="false"
          :selectProps="{
            disabled: true
          }" />
        <BaseFormSelect
          :value="businessMsg.creditCode"
          label="社会统一信用代码"
          disabled />
        <BaseFormSelect
          :value="businessMsg.registeredCapital"
          label="注册资本（万元）"
          disabled />
        <BaseFormSelect
          :value="businessMsg.legalPersonName"
          label="法人"
          disabled />
        <BaseFormSelect
          :value="businessMsg.legalPersonContact"
          label="法人联系方式"
          disabled />
        <BaseFormSelect
          :value="businessMsg.registerDate"
          label="注册日期"
          disabled />
        <BaseFormSelect
          :value="businessMsg.businessTerm"
          label="营业期限"
          disabled />
        <BaseFormSelect
          :value="businessMsg.businessLicenseAddress"
          label="营业执照地址（住所）"
          disabled />
        <BaseFormSelect
          :value="businessMsg.businessScope"
          label="经营范围"
          disabled />
        <BaseFormSelect
          :value="businessMsg.registrationAuthority"
          label="登记机关"
          disabled />
        <BaseFormSelect
          :value="businessMsg.certificatesRecAddress"
          label="函证接收地址"
          disabled />
        <HeaderTitle title="开票信息" />
        <BaseFormSelect
          :value="businessMsg.businessLicenseName"
          label="开票名称"
          disabled />
        <BaseFormSelect
          :value="businessMsg.creditCode"
          label="纳税人识别号"
          disabled />
        <BaseFormSelect
          :value="businessMsg.businessLicenseAddress"
          label="纳税人地址"
          disabled />
        <BaseFormSelect
          :value="businessMsg.taxpayerPhone"
          label="纳税人电话"
          disabled />
        <BaseFormSelect :value="businessMsg.bank" label="开户行" disabled />
        <BaseFormSelect
          :value="businessMsg.bankAccount"
          label="开户账号"
          disabled />
        <!-- <BaseFormSelect :value="businessMsg.payTaxesType" label="纳税类型" disabled /> -->
        <CommonSelect
          class="formItem"
          label="纳税类型"
          :value="businessMsg.payTaxesType"
          type="pay_taxes_type"
          placeholder=""
          :required="false"
          :selectProps="{
            disabled: true
          }" />
        <!-- <BaseFormSelect :value="businessMsg.billType" label="专票/普票" disabled /> -->
        <CommonSelect
          class="formItem"
          label="专票/普票"
          :value="businessMsg.billType"
          placeholder=""
          module="public"
          type="invoice_type"
          :required="false"
          :selectProps="{
            disabled: true
          }" />
        <BaseFormSelect
          :value="businessMsg.personLiableName"
          label="税务负责人"
          disabled />
        <BaseFormSelect
          :value="businessMsg.personLiablePhone"
          label="税务负责人电话"
          disabled />
        <BaseFormSelect
          :value="businessMsg.email"
          label="接受电子发票邮箱"
          disabled />

        <HeaderTitle
          title="与慕思的合作情况（现阶段是否在其他业务/其他有和慕思合作）" />
        <div style="margin-bottom: 10px">
          <el-table
            :data="form.cooperationRecordList"
            border
            style="width: 100%">
            <el-table-column
              align="center"
              width="60"
              fixed="left"
              v-if="!isOAComing">
              <template slot="header">
                <span
                  class="el-icon-circle-plus iconfont"
                  @click="addDerucci(0)"></span>
              </template>
              <template slot-scope="scope">
                <div>
                  <span
                    class="el-icon-remove iconfont"
                    @click="delDerucci(scope.$index, 0)"></span>
                </div>
              </template>
            </el-table-column>
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="agentCity" label="代理城市" width="150">
              <template slot-scope="scope">
                <div>
                  <required-form-item-select
                    style="margin: 0"
                    :prop="`cooperationRecordList.${scope.$index}.agentCity`"
                    label="代理城市"
                    :showLabel="false"
                    :dataList="authorizedCityList"
                    v-model="scope.row.agentCity"
                    :labelKey="item => `${item.city}${item.area || ''}`"
                    :valueKey="item => `${item.city}${item.area || ''}`"
                    @onChangeItem="
                      value => {
                        getAuthorizedCity(value, scope.$index)
                      }
                    " />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="province" label="省份" width="150">
            </el-table-column>
            <el-table-column prop="city" label="地级市" width="150">
            </el-table-column>
            <el-table-column prop="agentBrand" label="代理品牌（加盟品牌）">
            </el-table-column>
            <el-table-column label="近三年提货情况（万元）" width="250">
              <template slot-scope="scope">
                <required-form-item-input
                  style="margin: 0"
                  :prop="`cooperationRecordList.${scope.$index}.applySituation`"
                  label="近三年提货情况"
                  :showLabel="false"
                  :inputProps="{ type: 'number' }"
                  v-model="scope.row.applySituation" />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <BaseFormSelect :value="businessMsg.creditCode" label="售达方" disabled /> -->
        <div>
          <UploadFile
            class="formItem"
            label="上传附件"
            v-model="form.commitFileRecordList"
            prop="commitFileRecordList"
            :required="false"
            :onPreview="handlePreview">
            <div slot="describe">
              <div>加盟申请表（新系列）、店铺租赁合同</div>
            </div>
            <div slot="tip">单文件不能超过400M</div>
          </UploadFile>
        </div>
      </div>
      <div>
        <HeaderTitle title="货物及快递收货信息" />
        <required-form-item-input
          class="formItem"
          label="仓库收货人"
          prop="warehouse.warehouseUser"
          v-model="form.warehouse.warehouseUser" />
        <required-form-item-input
          class="formItem"
          label="仓库收货人联系方式"
          prop="warehouse.warehousePhone"
          v-model="form.warehouse.warehousePhone"
          :rules="[{ validator: regCheckPhone, trigger: 'blur' }]" />
        <required-form-item-input
          class="formItem"
          label="仓库收货地址"
          prop="warehouse.warehouseAddress"
          v-model="form.warehouse.warehouseAddress" />
        <required-form-item-input
          class="formItem"
          label="运送目的地"
          prop="warehouse.deliveryAddress"
          v-model="form.warehouse.deliveryAddress" />
        <CommonSelect
          class="formItem"
          type="delivery_type"
          v-model="form.warehouse.deliveryType"
          label="运输方式"
          prop="warehouse.deliveryType" />
        <CommonSelect
          class="formItem"
          type="transport_type"
          v-model="form.warehouse.transportType"
          label="装运条件"
          prop="warehouse.transportType" />
      </div>
      <div v-if="!isBigBrandWithClassic">
        <HeaderTitle title="品牌推广费及品牌保证金收取" />
        <div>
          <el-table :data="form.marginRecordList" border style="width: 100%">
            <el-table-column align="center" width="60" fixed="left">
              <template slot="header">
                <span
                  class="el-icon-circle-plus iconfont"
                  @click="addMoney()"></span>
              </template>
              <template slot-scope="scope">
                <div>
                  <span
                    v-show="form.marginRecordList.length >= 2"
                    class="el-icon-remove iconfont"
                    @click="delMoney(scope.$index)"></span>
                </div>
              </template>
            </el-table-column>
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column label="类型" width="200" align="center">
              <template slot-scope="scope">
                <div>
                  <CommonSelect
                    class="selectItem"
                    v-model="scope.row.type"
                    type="payment_type_code"
                    :prop="`marginRecordList.${scope.$index}.type`"
                    label="类型"
                    :showLabel="false" />
                  <!-- 品牌保证金 -->
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="remittanceAmount"
              label="金额（万）"
              align="center">
              <template slot-scope="scope">
                <div>
                  <!-- <el-input v-model="scope.row.remittanceAmount"
                    @keyup.native="scope.row.remittanceAmount = oninput(scope.row.remittanceAmount)"></el-input> -->
                  <RequiredFormItemInput
                    class="selectItem"
                    v-model="scope.row.remittanceAmount"
                    :prop="`marginRecordList.${scope.$index}.remittanceAmount`"
                    label="金额"
                    :showLabel="false"
                    :inputProps="{ type: 'number' }"
                    :max="99"
                    @blur="e => remittanceAmountSet(e, scope.row)" />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="remittanceDate"
              label="汇款日期"
              align="center">
              <template slot-scope="scope">
                <div>
                  <el-form-item
                    class="selectItem"
                    :prop="`marginRecordList.${scope.$index}.remittanceDate`"
                    :rules="[
                      {
                        required: true,
                        message: '请选择汇款日期',
                        trigger: 'change'
                      }
                    ]">
                    <el-date-picker
                      style="width: 190px"
                      size="mini"
                      v-model="scope.row.remittanceDate"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      type="datetime"
                      placeholder="选择日期时间">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="remittanceUser" label="付款人" align="center">
              <template slot-scope="scope">
                <div>
                  <RequiredFormItemInput
                    delay-update
                    class="selectItem"
                    v-model="scope.row.remittanceUser"
                    :prop="`marginRecordList.${scope.$index}.remittanceUser`"
                    label="付款人"
                    :showLabel="false"
                  />
                </div>
              </template>
            </el-table-column> -->
            <el-table-column label="汇款账户">
              <template slot-scope="scope">
                <div class="account">
                  <RequiredFormItemInput
                    delay-update
                    class="selectItem"
                    v-model="scope.row.accountName"
                    :prop="`marginRecordList.${scope.$index}.accountName`"
                    label="开户名"
                    :showLabel="false" />
                  <RequiredFormItemInput
                    delay-update
                    class="selectItem"
                    v-model="scope.row.account"
                    :prop="`marginRecordList.${scope.$index}.account`"
                    label="开户账号"
                    :showLabel="false" />
                  <required-bank-form-item-select
                    :prop="`marginRecordList.${scope.$index}.bank`"
                    :showLabel="false"
                    label="开户行"
                    v-model="scope.row.bank"
                    :validations="[]" />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="款条" width="250">
              <template slot-scope="scope">
                <div>
                  <UploadFile
                    class="selectItem"
                    style="width: 100%"
                    v-model="scope.row.fileList"
                    :prop="`marginRecordList.${scope.$index}.fileList`"
                    label="款条"
                    :showLabel="false"
                    @onUploaded="file => handleUpload(file, scope.$index)"
                    :onPreview="handlePreview" />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <RemarkItem
        style="margin-top: 20px"
        v-model="form.remark"
        :fileList="form.fileRecordList"
        @onUpload="
          file => {
            form.fileRecordList = file.map(item => item.response)
          }
        " />
    </el-form>
    <div v-if="!isOAComing" style="margin-top: 30px">
      <el-button type="primary" @click="save(10)">提交</el-button>
      <el-button type="info" @click="save(0)">保存</el-button>
      <el-button style="margin-left: 20px" @click="cancel">取消</el-button>
    </div>
  </div>
</template>

<script>
import BaseMessage from './components/baseMessage'
import BaseFormSelect from '@/components/BaseSelect/baseFormSelect'
import GoBack from '@/components/goBack'
import HeaderTitle from './components/headerTitle'
import UploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
import requiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import BusinessItemSelect from '@/components/BaseSelect/businessItemSelect'
import BrandSelect from '@/components/BaseSelect/brandSelect'
import CommonSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import RequiredBankFormItemSelect from '@/components/CustomFormItem/requiredBankFormItemSelect'
import moment from 'moment'
import RemarkItem from './components/remarkItem'
import { positionToError } from '@/utils/index'
import { common } from '@/mixin/mixin'
import { mapGetters } from 'vuex'
import {
  getAuthorizedCityData,
  getShopBrandData,
  requestGetSizeBarListByDept,
  requestGetCityLevel,
  requestDealerIfReShop
} from '@/api/common'
import { saveShopbrand, getDraftDetial } from '@/api/flow'
import {
  getStorage,
  setSession,
  getSession,
  removeSession
} from '@/utils/storage'
import { requestGetPickUp } from './api/common'
export default {
  mixins: [common],
  components: {
    BaseMessage,
    HeaderTitle,
    UploadFile,
    BaseFormSelect,
    requiredFormItemSelect,
    BusinessItemSelect,
    RequiredFormItemInput,
    BrandSelect,
    CommonSelect,
    GoBack,
    RemarkItem,
    RequiredBankFormItemSelect
  },
  data() {
    return {
      defaultNewSeriesHiddenBrandIds: [
        '1390119723112509441',
        '1390119723318030338',
        '1390119723531939841',
        '1390119723603243010',
        '1390119724416937985',
        '1390119724463075330',
        '1390119724605681666',
        '1390119724735705089',
        '1390119724773453826'
      ],
      remarkMap: [],
      ifReassembly: '',
      marketLevelName: '',
      form: {
        zsOrderNumber: '',
        id: '', //ID
        marketLevelName: '',
        marketLevelCode: '',
        bossId: '',
        addType: '',
        optJobNumber: '', //提单人工号
        businessLicenseId: '', //售达方
        authorizedCityCode: '', // 授权城市
        title: '', //标题
        orderNumber: '', //单号
        optUserId: '', //提单人ID
        optUserName: '', //提单人姓名
        optDeptId: '', //提单部门ID
        optDeptName: '', //提单部门名称
        // optTime: parseTime(new Date(), '{y}-{m}-{d} {h}:{m}:{s}'), //提单日期
        dealerType: 1, //0新经销商1老经销商
        status: 0, //0草稿1已发布2已失效3已归档
        remark: '',
        marketCenterCode: '',
        marketCenterName: '',
        brands: [],
        deliveryPartyList: [],
        warehouse: {},
        fileRecordList: [],
        commitFileRecordList: [],
        cooperationRecordList: [],
        // 计划开店数量
        planOpeningCount: '',
        // 计划开店面积
        planOpeningArea: '',
        // 一城多商
        cityDealerCont: '',
        cityDealerContRemark: '',
        ifReassembly: '',
        remarkMap: [],
        reserved: '',
        marginRecordList: [
          {
            type: '',
            remittanceAmount: '',
            remittanceDate: '',
            remittanceUser: '',
            accountName: '',
            account: '',
            bank: '',
            fileRecordList: [],
            fileList: []
          }
        ]
        // orderList: [] // 订单选项
      },
      marketCenterData: {}, // 营销中心数据
      cooperationRecordList: [], // 慕思合作情况
      businessMsg: {},
      authorizedCityMsg: {},
      authorizedBrand: [], // 代理品牌列表
      authorizedCityList: [], //授权城市
      id: this.$route.query.id,
      businessList: [], // 售达方列表
      isOAComing: false, // 是否从oa进来
      // fullscreenLoading: false,
      brandData: [], //针对送达方数据
      flagVisable: true,
      optTime: '',
      shopBrandNumber: '', //送达方编号
      orderNumber: '', // 流程单号
      // marginRecordList: []
      // loading:null
      isCache: true,
      shopMessageByAuthorizedCity: {}
    }
  },
  watch: {
    'form.businessLicenseId'(newVal, oldVal) {
      if (this.id) {
        if (oldVal && newVal != oldVal) {
          this.initForm()
        }
      } else {
        if (!oldVal) {
          this.initForm()
        }
      }
    },
    title: {
      deep: true,
      handler(value) {
        // console.log(value);
      }
    }
  },
  computed: {
    ...mapGetters('dealershipTransfer', ['isOA']),
    ...mapGetters('commonConfig', ['bigBrandMap']),
    getDealer({ brandData }) {
      let str = ''
      let brand = brandData.map(item => item.bigName)
      brand = [...new Set(brand)]
      brand.forEach(item => {
        str += `${this.authorizedCityMsg.city}${
          this.authorizedCityMsg.area || ''
        }-${this.businessMsg.bossNumber}-${item}<br />`
      })
      return str
    },
    title({ businessMsg, authorizedCityMsg, brandData }) {
      const str = `老经销商增加新系列申请表（${authorizedCityMsg.city || ''}${
        authorizedCityMsg.area || ''
      }，${businessMsg.legalPersonName || ''}，门店品牌：${(brandData || [])
        .map(item => item.name)
        .join(',')}）`
      this.form.title = str
      return str
    },
    isBigBrandWithClassic({ brandData }) {
      // console.log(brandData)
      if (this.isOAComing && this.form?.reserved) {
        const reserved = JSON.parse(this.form.reserved)
        return reserved?.isBigBrandWithClassic || false
      }
      if (!this.bigBrandMap.get('慕思经典')) return false
      const { code } = this.bigBrandMap.get('慕思经典')
      const brandDataCodes = brandData.map(item => item.bigCode)
      return brandDataCodes.includes(code)
    }
  },
  created() {
    window.addEventListener('beforeunload', this.reloadBom)
  },
  mounted() {
    this.isOAComing = this.isOA
    this.optTime = moment().format('YYYY-MM-DD hh:mm:ss')
    const userInfo = getStorage('userInfo')
    const formData = {
      optUserId: userInfo?.accountId || '',
      optUserName: userInfo?.realName || '',
      optDeptId: userInfo?.deptId || '',
      optDeptName: userInfo?.deptName || '',
      optJobNumber: userInfo?.code || ''
    }

    this.form = {
      ...this.form,
      ...formData
    }
    console.log('form', this.form)
    this.$load.show()
    if (this.id) {
      // this.loading = this.$load({
      //   lock: true,
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(255, 255, 255)'
      // });
      this.getDetail()
    } else {
      let cacheData = {}
      console.log(getSession('newSeriesFlow'), !this.$router.query?.id)
      if (getSession('newSeriesFlow') && !this.$router.query?.id) {
        cacheData = getSession('newSeriesFlow').form || {}
        console.log(cacheData)
        this.$nextTick(() => {
          for (let key in this.form) {
            if (cacheData[key]) {
              this.form[key] = cacheData[key]
            }
          }
          if (
            cacheData.authorizedBrandList &&
            cacheData.authorizedBrandList.length > 0
          ) {
            this.shopBrandNumber = cacheData.authorizedBrandList
              .map(item => item.shopBrandNumber)
              .join('，')
          }
          this.form.businessLicenseId = this.form.businessLicenseId
            ? String(this.form.businessLicenseId)
            : ''
          if (typeof this.form.brands == 'string') {
            this.form.brands = this.form.brands.split(',')
          }
          this.form.cooperationRecordList =
            cacheData.cooperationRecordList || []
          this.form.marginRecordList = cacheData.marginRecordList.map(item => {
            return {
              ...item,
              fileList: item.fileRecordList
            }
          })
          console.log('marginRecordList', this.form.marginRecordList)
          this.form.deliveryPartyList = cacheData.deliveryPartyList
        })
      }
      this.$load.close()
    }
    // this.asyncBusinessData()
  },
  beforeDestroy() {
    this.reloadBom()
    window.removeEventListener('beforeunload', this.reloadBom)
  },
  methods: {
    // 选择授权城市
    async handleChangeAuthorizedCity(value) {
      const res = await getShopBrandData({
        authorizedCityCode: value.authorizedCityCode,
        businessLicenseId: this.businessMsg.id
      })
      this.shopMessageByAuthorizedCity = res.data
      const dealerData = await requestGetPickUp({
        dealer_code: res.data[0].crmDistributorCode,
        year_desc: new Date().getFullYear()
      })
      this.remarkMap =
        dealerData.data?.sort((a, b) => {
          return Number(b.year_desc) - Number(a.year_desc)
        }) || []
      const ifReShop = await requestDealerIfReShop({
        authorizedCityCode: value.authorizedCityCode,
        bossId: this.businessMsg.bossId
      })
      this.ifReassembly = ifReShop.data
    },
    remittanceAmountSet(e, row) {
      let value = e.target.value.trim()
      if (value.trim() && value.length > 2) {
        this.$message.warning('金额只能输入两位整数')
      }
      value = value.replace('.', '')
      row.remittanceAmount = value.slice(0, 2)
    },
    // 用户界面变化
    reloadBom() {
      // console.log(1)
      if (this.isCache) {
        // console.log('缓存',this.$data);
        setSession('newSeriesFlow', JSON.stringify(this.$data))
      } else {
        removeSession('newSeriesFlow')
      }
    },
    // 文件预览
    handlePreview({ url }) {
      window.open(url)
    },
    // 添加
    addDerucci() {
      if (!this.businessMsg.id) {
        this.$message({
          type: 'warning',
          message: '请选择售达方'
        })
        return
      }
      // 合作情况
      this.form.cooperationRecordList.push({
        bossId: this.businessMsg.bossId,
        province: '',
        city: '',
        agentCity: '',
        agentBrand: '',
        applySituation: ''
      })
    },
    // 删除慕思品牌
    delDerucci(index) {
      this.form.cooperationRecordList.splice(index, 1)
    },
    // 添加收费
    addMoney() {
      if (this.form.marginRecordList.length == 3) {
        return
      }
      // 合作情况
      this.form.marginRecordList.push({
        bossId: this.businessMsg.bossId,
        type: '',
        remittanceAmount: '',
        remittanceDate: '',
        remittanceUser: '',
        // accountName: '',
        // account: '',
        // bank: '',
        fileRecordList: [],
        fileList: []
      })
    },
    // 删除收费
    delMoney(index) {
      if (this.form.marginRecordList.length == 1) return
      this.form.marginRecordList.splice(index, 1)
    },
    handleUpload(file, index) {
      console.log(file)
      this.form.marginRecordList[index].fileRecordList = file.map(item => {
        return {
          ...item.response
        }
      })
    },
    // 获取售达方
    async handleChangeBusiness(data) {
      this.businessMsg = data
      // this.initForm()
      const r = await getAuthorizedCityData({
        businessLicenseId: data.id
      })
      if (r.code === 0) {
        this.authorizedCityList = r.data
      }
    },
    // 编辑获取售达方成功后
    async handleSuccess(data) {
      this.businessMsg = data
      const res = await getAuthorizedCityData({
        businessLicenseId: data.id
      })
      if (res.code === 0) {
        this.authorizedCityList = res.data
        // this.getCity(this.form.authorizedCityCode)
        if (this.form.id) {
          this.$load.close()
        }
      }
    },
    // 获取代理城市
    async getAuthorizedCity(data, index) {
      const agentBrand = this.shopMessageByAuthorizedCity
        .map(item => item.name)
        .join(',')
      this.form.cooperationRecordList[index].province = data.province
      this.form.cooperationRecordList[index].city = data.city
      this.form.cooperationRecordList[index].agentBrand = agentBrand
    },
    // 根据授权城市获取大区信息
    async authorizedCityGetMarketCenterData(list) {
      this.marketCenterData = {}
      this.form.marketCenterCode = ''
      this.form.marketCenterName = ''
      if (list.length === 0) return
      const bigBrandId = this.bigBrandMap.get('慕思经典')?.id
      const index = list.findIndex(v => v.bigBrandId === bigBrandId)
      if (index > -1) {
        new Promise(async (resolve, reject) => {
          if (this.authorizedCityMsg.hasOwnProperty('provinceCode')) {
            const res = await requestGetSizeBarListByDept({
              provinceCode: this.authorizedCityMsg?.provinceCode,
              cityCode: this.authorizedCityMsg?.cityCode,
              areaCode: this.authorizedCityMsg?.areaCode
            })
            resolve(res.data)
          } else {
            const timer = setInterval(async () => {
              if (this.authorizedCityMsg.hasOwnProperty('provinceCode')) {
                const res = await requestGetSizeBarListByDept({
                  provinceCode: this.authorizedCityMsg?.provinceCode,
                  cityCode: this.authorizedCityMsg?.cityCode,
                  areaCode: this.authorizedCityMsg?.areaCode
                })
                resolve(res.data)
                clearTimeout(timer)
              }
            }, 300)
          }
        }).then(res => {
          this.marketCenterData = res
          this.form.marketCenterCode = this.marketCenterData.smallCode
          this.form.marketCenterName = this.marketCenterData.smallName
        })
      }
    },
    // 获取品牌列
    // 获取城市等级
    async handleGetMarketLevel(data) {
      if (
        data &&
        data.length > 0 &&
        this.authorizedCityMsg?.authorizedCityCode
      ) {
        const brandHasSame =
          [...new Set(data.map(item => item.bigBrandName))].length > 1
        if (brandHasSame) return
        const { data: marketLevel } = await requestGetCityLevel({
          brandId: data[data.length - 1].bigBrandId,
          authorizedCityCode: this.authorizedCityMsg?.authorizedCityCode
        })
        // console.log(marketLevel)
        // this.marketLevelName = marketLevel[0].marketLevelName
        this.form.marketLevelName = marketLevel[0].marketLevelName
        this.form.marketLevelCode = marketLevel[0].marketLevelCode
      } else if (
        data &&
        data.length > 0 &&
        !this.authorizedCityMsg?.authorizedCityCode
      ) {
        setTimeout(() => {
          this.handleGetMarketLevel(data)
        }, 500)
      } else {
        this.form.marketLevelName = ''
        this.form.marketLevelCode = ''
      }
    },
    async getBrands(data) {
      // const isIncludeJD = data?.some(l => l.name.includes('慕思经典')) || false
      // const defultBrand = isIncludeJD
      //   ? this.$refs.brandSelect.authorizedBrand.filter(item =>
      //       ['助眠系列', '芯睡眠'].includes(item.name)
      //     )
      //   : []
      // this.brandData = [...data, ...defultBrand]
      this.brandData = data || []
      await this.authorizedCityGetMarketCenterData(this.brandData)
      this.handleGetMarketLevel(data)
      this.form.deliveryPartyList = this.brandData.map(item => {
        return {
          brandId: item.brandId,
          brandCode: item.code
        }
      })
    },
    initForm() {
      this.authorizedBrand = []
      this.form.cooperationRecordList = []
      this.form.authorizedCityCode = ''
      this.form.brands = []
      this.authorizedCityMsg = {}
      this.brandData = []
    },
    // 获取详情
    async getDetail() {
      // const loading = this.$load({
      //   lock: true,
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(255, 255, 255, 0.7)'
      // });
      try {
        const res = await getDraftDetial({
          fromId: this.id
        })
        // loading.close();
        const { code, data } = res
        if (code === 0) {
          this.orderNumber = data.orderNumber
          const formData = JSON.parse(data.historicalData)
          this.form.zsOrderNumber = data?.zsOrderNumber || ''
          // console.log(formData);
          // 近三年提货量
          if (formData?.remarkMap) {
            formData.remarkMap = JSON.parse(formData.remarkMap)
          }
          for (let key in this.form) {
            if (formData[key]) {
              this.form[key] = formData[key]
            }
          }
          if (
            formData.authorizedBrandList &&
            formData.authorizedBrandList.length > 0
          ) {
            this.shopBrandNumber = formData.authorizedBrandList
              .map(item => item.shopBrandNumber)
              .join('，')
          }
          this.optTime = data.optTime
          this.form.id = this.id
          this.form.businessLicenseId = this.form.businessLicenseId
            ? String(this.form.businessLicenseId)
            : ''
          if (typeof this.form.brands == 'string') {
            this.form.brands = this.form.brands.split(',')
          }
          this.form.cooperationRecordList = formData.cooperationRecordList || []
          this.form.marginRecordList = formData.marginRecordList.map(item => {
            return {
              ...item,
              fileList: item.fileRecordList
            }
          })
          this.form.deliveryPartyList = formData.deliveryPartyList
          if (!this.form.businessLicenseId) {
            this.$load.close()
          }
        }
      } catch (error) {
        // loading.close();
        this.$load.close()
        this.$alert(error, '提示', {
          confirmButtonText: '确定',
          type: 'error'
        })
      }
    },

    handleValidate(_, flag, message) {
      if (!flag && this.flagVisable) {
        this.$message.warning(message)
        this.flagVisable = false
      }
    },
    save(status) {
      this.form.status = status
      this.form.bossId = this.businessMsg.bossId
      // this.fullscreenLoading = true
      // this.form.optTime = parseTime(new Date(), '{y}-{m}-{d} {h}:{m}:{s}')
      this.$load.show()
      this.flagVisable = true
      if (status) {
        if (
          !this.isBigBrandWithClassic &&
          (!this.form.cooperationRecordList ||
            this.form.cooperationRecordList.length === 0)
        ) {
          this.$message.warning('与慕思的合作情况不能为空')
          this.$load.close()
          return
        }
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            const formData = {
              ...this.form,
              ifReassembly: this.isBigBrandWithClassic
                ? this.ifReassembly
                : undefined,
              remarkMap: this.isBigBrandWithClassic ? this.remarkMap : [],
              reserved: JSON.stringify({
                isBigBrandWithClassic: this.isBigBrandWithClassic
              })
            }
            if (
              this.isBigBrandWithClassic &&
              formData.cooperationRecordList.length !== 0
            ) {
              formData.commitFileRecordList = []
              formData.cooperationRecordList = []
            }

            // formData.cooperationRecordList = this.cooperationRecordList
            // formData.marginRecordList = this.marginRecordList
            const isIncludeJD = this.brandData.some(
              l => l.name.includes('慕思经典') && l.name !== '慕思经典-新渠道'
            )
            const defultBrand = isIncludeJD
              ? this.$refs.brandSelect.authorizedBrand.filter(item =>
                  ['助眠系列', '芯睡眠'].includes(item.name)
                )
              : []
            defultBrand.forEach(item => {
              if (
                !this.form.deliveryPartyList.find(
                  v => v.brandId === item.brandId
                )
              ) {
                formData.deliveryPartyList.push({
                  brandId: item.brandId,
                  brandCode: item.code
                })
                formData.brands.push(item.brandId)
                formData.title = this.form.title.replace(
                  '）',
                  `，${item.name}）`
                )
              }
            })
            formData.brands = formData.brands.join(',')
            this.commonCode(status, formData)
          } else {
            this.$load.close()
            this.$nextTick(() => {
              positionToError()
              return false
            })
          }
        })
      } else {
        const formData = {
          ...this.form
        }
        formData.brands = formData.brands.join(',')
        // formData.cooperationRecordList = this.cooperationRecordList
        // formData.marginRecordList = this.marginRecordList
        this.commonCode(status, formData)
      }
    },
    async commonCode(status, formData) {
      console.log(status, formData)
      formData.remarkMap = JSON.stringify(formData.remarkMap)
      try {
        const res = await saveShopbrand(formData)
        this.$load.close()
        this.isCache = false
        removeSession('newSeriesFlow')
        if (res.code === 0) {
          this.$confirm(status === 10 ? '流程发布中' : '草稿保存成功', '提示', {
            confirmButtonText: '确定',
            showCancelButton: false,
            type: 'success'
          }).then(() => {
            this.$router.push({
              name: status ? 'myApply' : 'draft'
            })
          })
        } else if (res.code == '8848') {
          this.$router.push({
            name: status ? 'myApply' : 'draft'
          })
        }
      } catch (error) {
        this.$load.close()
        // this.$message.error(error);
        if (error.data.code == '8848') {
          this.$router.push({
            name: status ? 'myApply' : 'draft'
          })
          return
        }
      }
    },
    cancel() {
      this.isCache = false
      this.$router.back(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/colors.scss';

// .formItem {
//   width: 20%;
// }

.iconfont {
  font-size: 20px;
  cursor: pointer;
}

.tip {
  color: $danger-dark-color;
  margin-bottom: 10px;
}

.sdShopName {
  min-height: 40px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0 15px;
}
.account {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .selectItem {
    margin-bottom: 10px;
  }
}

.selectItem {
  margin: 0;

  &.is-error {
    margin-bottom: 22px;
  }
}

::v-deep .el-select {
  width: 100%;
}

@media (max-width: 702px) {
  .formItem {
    width: 100%;
  }
}

@media screen and (min-width: 702px) and (max-width: 1260px) {
  .formItem {
    width: calc(33% - 10px);
  }
}

@media (min-width: 1260px) {
  .formItem {
    width: calc(25% - 10px);
  }
}
</style>
