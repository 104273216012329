<!--
 * @Author: wangmq
 * @Date: 2024-01-09 10:43:34
 * @LastEditors: Michaels-MacBook.local
 * @LastEditTime: 2024-08-19 17:36:36
-->
<template>
  <div>
    <div>
      <HeaderTitle
        title="门店基础信息"
        size="14px"
        style="margin-bottom: 8px" />
      <FormColumnRender
        v-for="item in formStoreBaseColumns"
        :key="item.name"
        v-model="defaultData[item.name]"
        class="formItem"
        :params="defaultData"
        :columnItem="item" />
      <HeaderTitle
        title="门店地址信息"
        size="14px"
        style="margin-bottom: 8px" />
      <FormColumnRender
        v-for="item in formStoreAddressColumns"
        :key="item.name"
        v-model="defaultData[item.name]"
        class="formItem"
        :params="defaultData"
        :columnItem="item" />
      <HeaderTitle
        title="门店装修信息"
        size="14px"
        style="margin-bottom: 8px" />
      <FormColumnRender
        v-for="item in formStoreDecorationColumns"
        :key="item.name"
        v-model="defaultData[item.name]"
        class="formItem"
        :params="defaultData"
        :columnItem="item" />
    </div>

    <div v-if="!isOA && defaultData.ifDianjiangDesigner">
      <el-divider />
      <div v-if="!isMobile" style="width: 1200px; margin: 0 auto">
        <el-carousel :interval="3000" type="card" height="300px">
          <el-carousel-item
            v-for="dianjiang in dianjiangDesigner"
            :key="dianjiang.id"
            class="dianjian">
            <div class="w_flex">
              <div>
                <el-image
                  class="header_img"
                  :src="dianjiang?.coverUrl || ''"
                  alt=""
                  fit="cover" />
              </div>
              <div class="content">
                <div class="w_flex" style="align-items: center">
                  <div>{{ dianjiang?.realName }}</div>
                  <div style="padding: 0 10px">{{ dianjiang?.phone }}</div>
                  <div>
                    <el-button
                      type="warning"
                      size="mini"
                      style="border-radius: 20px"
                      @click="
                        () => {
                          currentVMDPage = 1
                          VMDid = dianjiang.id
                          getVMDList()
                        }
                      "
                      >查看案例</el-button
                    >
                  </div>
                </div>
                <div class="w_flex">
                  <div class="label">部门:</div>
                  <div class="name">{{ dianjiang?.deptName }}</div>
                </div>
                <div class="w_flex">
                  <div class="label">职称:</div>
                  <div class="name">{{ dianjiang?.stylistPost }}</div>
                </div>
                <div class="w_flex">
                  <div class="label">点将牌价:</div>
                  <div class="name">{{ dianjiang?.price }}元/平米</div>
                </div>
                <div class="w_flex">
                  <div class="label">经销商实际承担:</div>
                  <div class="name">
                    {{ dianjiang?.actualDealerBear }}元/平米
                  </div>
                </div>
                <div class="w_flex">
                  <div class="label">平均满意度：</div>
                  <div class="name">
                    {{
                      dianjiang?.totalAvg > 0
                        ? `${dianjiang?.totalAvg.toFixed(2)}分`
                        : '-'
                    }}
                  </div>
                </div>
                <div class="w_flex">
                  <div class="label">历史接单数:</div>
                  <div class="name">
                    {{
                      dianjiang?.historyOrderNumber > 0
                        ? `${dianjiang?.historyOrderNumber}`
                        : '-'
                    }}
                  </div>
                </div>
                <div class="w_flex">
                  <div class="label">在途接单数:</div>
                  <div class="name">
                    {{
                      dianjiang?.nowOrderNumber > 0
                        ? dianjiang?.nowOrderNumber
                        : '-'
                    }}
                  </div>
                </div>
                <div class="w_flex">
                  <div class="label">区域:</div>
                  <div class="name">
                    {{ dianjiang?.region }}
                  </div>
                </div>
                <div>
                  <div class="label" style="margin-bottom: 8px">标签:</div>
                  <div class="name">
                    <el-tag
                      v-for="(
                        item, index
                      ) in dianjiang?.operateSeriesList.slice(0, 3)"
                      :key="index"
                      type="warning"
                      effect="dark"
                      style="margin: 0 8px 8px 0">
                      {{ item.labelName }}
                    </el-tag>
                    <el-popover
                      placement="top-start"
                      title="标签"
                      trigger="hover"
                      width="300">
                      <el-tag
                        v-for="(item, index) in dianjiang?.operateSeriesList"
                        :key="index"
                        type="warning"
                        effect="dark"
                        style="margin: 6px">
                        {{ item.labelName }}
                      </el-tag>
                      <span
                        v-show="dianjiang?.operateSeriesList.length > 3"
                        slot="reference"
                        class="more">
                        更多
                      </span>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div v-else>
        <el-carousel :interval="3000" height="300px" indicator-position="none">
          <el-carousel-item
            v-for="dianjiang in dianjiangDesigner"
            :key="dianjiang.id"
            class="dianjian">
            <div class="w_flex">
              <div>
                <el-image
                  class="header_img"
                  :src="dianjiang?.coverUrl || ''"
                  alt=""
                  fit="cover"
                  style="width: 130px" />
              </div>
              <div class="content">
                <div class="w_flex" style="align-items: center">
                  <div>{{ dianjiang?.realName }}</div>
                  <div style="padding: 0 10px">{{ dianjiang?.phone }}</div>
                  <div>
                    <el-button
                      type="warning"
                      size="mini"
                      style="border-radius: 20px"
                      @click="
                        () => {
                          currentVMDPage = 1
                          VMDid = dianjiang.id
                          getVMDList()
                        }
                      "
                      >查看案例</el-button
                    >
                  </div>
                </div>
                <div class="w_flex">
                  <div class="label">部门:</div>
                  <div class="name">{{ dianjiang?.deptName }}</div>
                </div>
                <div class="w_flex">
                  <div class="label">职称:</div>
                  <div class="name">{{ dianjiang?.stylistPost }}</div>
                </div>
                <div class="w_flex">
                  <div class="label">点将牌价:</div>
                  <div class="name">{{ dianjiang?.price }}元/平米</div>
                </div>
                <div class="w_flex">
                  <div class="label">经销商实际承担:</div>
                  <div class="name">
                    {{ dianjiang?.actualDealerBear }}元/平米
                  </div>
                </div>
                <div class="w_flex">
                  <div class="label">平均满意度：</div>
                  <div class="name">
                    {{
                      dianjiang?.totalAvg > 0
                        ? `${dianjiang?.totalAvg.toFixed(2)}分`
                        : '-'
                    }}
                  </div>
                </div>
                <div class="w_flex">
                  <div class="label">历史接单数:</div>
                  <div class="name">
                    {{
                      dianjiang?.historyOrderNumber > 0
                        ? `${dianjiang?.historyOrderNumber}`
                        : '-'
                    }}
                  </div>
                </div>
                <div class="w_flex">
                  <div class="label">在途接单数:</div>
                  <div class="name">
                    {{
                      dianjiang?.nowOrderNumber > 0
                        ? dianjiang?.nowOrderNumber
                        : '-'
                    }}
                  </div>
                </div>
                <div class="w_flex">
                  <div class="label">区域:</div>
                  <div class="name">
                    {{ dianjiang?.region }}
                  </div>
                </div>
                <div>
                  <div class="label" style="margin-bottom: 8px">标签:</div>
                  <div class="name">
                    <el-tag
                      v-for="(
                        item, index
                      ) in dianjiang?.operateSeriesList.slice(0, 3)"
                      :key="index"
                      type="warning"
                      effect="dark"
                      style="margin: 0 8px 8px 0">
                      {{ item.labelName }}
                    </el-tag>
                    <el-popover
                      placement="top-start"
                      title="标签"
                      trigger="hover"
                      width="300">
                      <el-tag
                        v-for="(item, index) in dianjiang?.operateSeriesList"
                        :key="index"
                        type="warning"
                        effect="dark"
                        style="margin: 6px">
                        {{ item.labelName }}
                      </el-tag>
                      <div
                        v-show="dianjiang?.operateSeriesList.length > 3"
                        slot="reference"
                        class="more">
                        更多
                      </div>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div v-if="dianjiangTotalCount" class="pagination">
        <el-pagination
          background
          :page-size="3"
          layout="total,prev, pager, next"
          :total="dianjiangTotalCount"
          @current-change="page => getDianjiangTableList(page)" />
      </div> -->
    </div>
    <el-drawer
      title="案例列表"
      :visible.sync="VMDdrawer"
      direction="rtl"
      size="60%;overflow-y:auto">
      <div class="app-container" style="display: flex; flex-wrap: wrap">
        <div v-for="item in VMDList" :key="item.id">
          <div style="width: 230px" @click="handleVMDdetail(item.id)">
            <el-card
              style="margin-right: 8px; margin-bottom: 8px"
              shadow="hover"
              :body-style="{ padding: '0px' }">
              <el-image
                style="width: 212px; height: 132px"
                :src="item.coverUrl"
                fit="cover" />
              <div style="padding: 14px">
                <div :title="item.caseName" class="caseName">
                  {{ item.caseName }}
                </div>
                <div
                  class="case"
                  :title="`${item.caseArea}㎡ ${item.caseSite}`">
                  <span class="caseArea">{{ item.caseArea }}</span>
                  <span class="caseSite">{{ item.caseSite }}</span>
                </div>
              </div>
            </el-card>
          </div>
        </div>
      </div>
      <div style="text-align: center; padding-bottom: 50px">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="VMDTotal"
          @size-change="handleVMDSizeChange"
          @current-change="handleVMDCurrentChange" />
      </div>
    </el-drawer>
    <CaseCardDialog :case-data="VMDdetail" :visible.sync="VMDdialogVisible" />
  </div>
</template>

<script>
import CommonTable from '@/components/TablePro/Table.vue'
import FormColumnRender from '@/components/FormPro/FormColumnRender.vue'
import { mapState } from 'vuex'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItem from '@/components/CustomFormItem/requiredFormItem.vue'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import PopupForm, { renderFormItem } from '@/components/FormPro/PopupForm.vue'
import CaseCardDialog from './CaseCardDialog'
import QQMap from '@/components/Map/qqmap'
import HeaderTitle from './headerTitle.vue'
import { render } from '@/components/TablePro/fn'
import { getShopList, requestGetStoreData } from '@/api/common/index'
import {
  requestVMDList,
  requestVMDdetail,
  requestGetDianjiangList
} from '@/api/flow/storeManagement'
import TipsText from '../data/tips_text'
const module_params = {
  moduleBrand: 'C'
}
const validations = {
  realName: ['name'],
  contactInfo: ['phone', 'check_boss_number'],
  idCard: ['user_id'],
  tencentQq: ['email'],
  creditCode: ['credit_code'],
  // taxpayerPhone: ['phone'],
  bankAccount: ['bank_account'],
  personLiableName: ['name'],
  personLiablePhone: ['phone'],
  eMail: ['email'],
  renovationLiablePerson: ['name'],
  renovationPersonPhone: ['phone'],
  contactPerson: ['name'],
  contactPersonIdcard: ['user_id'],
  contactPersonPhone: ['phone'],
  warehouseUser: ['name'],
  warehousePhone: ['phone'],
  legalPersonContact: ['phone'],
  age: ['age'],
  // accountName: ['name'],
  account: ['bank_account'],
  shopTel: ['phone']
}
export default {
  name: 'StoreFlowTable',
  components: {
    FormColumnRender,
    CommonTable,
    RequiredModuleFormItemSelect,
    RequiredFormItemInput,
    RequiredFormItem,
    PopupForm,
    QQMap,
    RequiredFormItemDatePicker,
    RequiredFormItemSelect,
    CaseCardDialog,
    HeaderTitle
  },
  props: {
    defaultData: {
      type: Object,
      default: () => ({})
    },
    authorizedSelectedItem: {
      type: Object,
      default: () => ({})
    },
    defaultDataBrandList: {
      type: Array,
      default: () => []
    },
    businessLicense: {
      type: Object,
      default: () => ({})
    },
    storeOpeningType: {
      type: String,
      default: ''
    },
    storeOpeningMode: {
      type: String,
      default: ''
    },
    bigRegionName: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      isOA: state => state.dealershipTransfer.isOA,
      isMobile: state =>
        state.app.device === 'mobile' || state.app.isWxworkMoblie
    }),
    getAuthAreaListParamsStr({
      businessLicense: { id },
      authorizedSelectedItem: { authorizedCityCode }
    }) {
      return {
        businessLicenseId: id,
        authorizedCityCode
      }
    },
    storeModalEasyBy({ storeOpeningType }) {
      switch (storeOpeningType) {
        case '全新开店':
          return 1
        case '扩店装修':
          return 2
        case '整店重装':
        case '部分装修':
          return 3
      }
    },
    shopAreaDisabled({ storeOpeningMode, storeModalEasyBy }) {
      if (storeOpeningMode === '部分装修' && storeModalEasyBy === 3) {
        return false
      } else if ([1, 3].includes(storeModalEasyBy)) {
        return true
      } else {
        return false
      }
    },
    shopHeaderAreaDisabled({
      storeOpeningMode,
      storeModalEasyBy,
      defaultData
    }) {
      return {
        storeOpeningMode,
        storeModalEasyBy,
        shopTypeName: defaultData.shopTypeName
      }
    },
    formStoreBaseColumns({ storeBaseColumns }) {
      return storeBaseColumns.filter(item => !item?.hideInTable)
    },
    formStoreAddressColumns({ storeAddressColumns }) {
      return storeAddressColumns.filter(item => !item?.hideInTable)
    },
    formStoreDecorationColumns({ storeDecorationColumns }) {
      return storeDecorationColumns.filter(item => !item?.hideInTable)
    },
    parentShopNumberPlaceholder({ defaultData, storeModalEasyBy }) {
      if (storeModalEasyBy === 1) {
        if (defaultData.ifParentShopNumber === '0') {
          return '归档后生成'
        } else if (defaultData.ifParentShopNumber === '1') {
          return '请输入 7 位数字物理店编号'
        } else {
          return ''
        }
      } else {
        return '请选择门店编号'
      }
    }
  },
  watch: {
    shopHeaderAreaDisabled: {
      handler(value) {
        console.log('shopHeaderAreaDisabled', value)
        this.storeBaseColumns.forEach(item => {
          if (value.shopTypeName !== '独立店') {
            if (
              ['门头实用面积', '门头装修面积', '调整后门头面积'].includes(
                item.title
              )
            ) {
              item.hideInTable = true
            }
            return
          }
          if (['门头实用面积', '门头装修面积'].includes(item.title)) {
            item.hideInTable =
              value.storeOpeningMode === '部分装修' &&
              value.storeModalEasyBy === 3
          }
          if (['调整后门头面积'].includes(item.title)) {
            item.hideInTable = value.storeModalEasyBy !== 2
          }
        })
      }
    },
    storeModalEasyBy: {
      handler(value) {
        this.storeBaseColumns.forEach(item => {
          if (['调整后门店面积'].includes(item.title)) {
            item.hideInTable = Number(value) !== 2
          }
          if (['是否有物理门店'].includes(item.title)) {
            item.hideInTable = Number(value) !== 1
          }
        })
        this.storeDecorationColumns.forEach(item => {
          if (['最新装修时间'].includes(item.title)) {
            item.hideInTable = Number(value) === 1
          }
        })
        this.dianjiangshiData = {}
      },
      immediate: true
    },
    'defaultData.marketName': {
      async handler(value) {
        if (
          !this.businessLicense?.bossId ||
          !this.authorizedSelectedItem?.authorizedCityCode
        ) {
          return
        }
        if (value && !this.isOA) {
          const res = await requestGetStoreData({
            bossId: this.businessLicense?.bossId ?? '',
            authorizedCityCode:
              this.authorizedSelectedItem?.authorizedCityCode ?? '',
            marketName: value,
            shopStatus: 0
          })
          this.marketShopCount = res.totalCount
        } else if (!value && !this.isOA) {
          this.marketShopCount = ''
        }
        this.$emit('getMarketShopCount', this.marketShopCount)
      }
    },
    'defaultData.shopTypeName': {
      async handler(value) {
        if (value) {
          if (!this.isOA && value !== '独立店') {
            this.defaultData.doorHeaderArea = ''
            this.defaultData.doorHeaderBeforeArea = ''
            this.defaultData.doorHeaderRenovationArea = ''
          }
        }
      }
    }
  },
  data() {
    return {
      dianjiangshiData: {},
      VMDid: '',
      VMDdialogVisible: false,
      VMDdrawer: false,
      dianjiangDesigner: [],
      // dianjiangTotalCount: 0,
      // dianjiangCurrentPage: 1,
      // dianjiangPageSize: 10,
      VMDList: [],
      VMDTotal: 0,
      currentVMDPage: 1,
      VMDdetail: {},
      VMDPageSize: 10,
      marketShopCount: '',
      storeBaseColumns: [
        {
          title: '是否有物理门店',
          hideInTable: true,
          renderFormItem: render((_, context) => {
            return (
              <RequiredModuleFormItemSelect
                class="formItem"
                prop={`shop.ifParentShopNumber`}
                type="flow_common_select_ifEnable"
                v-model={this.defaultData.ifParentShopNumber}
                label="是否已有物理店"
                onOnChangeItem={val => {
                  this.defaultData.parentShopNumber = ''
                }}
              />
            )
          })
        },
        {
          title: '物理店编号',
          renderFormItem: render((h, context) => {
            return (
              <RequiredFormItemInput
                inputProps={{ type: 'number' }}
                class="formItem"
                v-model={this.defaultData.parentShopNumber}
                label="物理店编号"
                disabled={
                  !this.defaultData.ifParentShopNumber ||
                  this.defaultData.ifParentShopNumber === '0' ||
                  this.storeModalEasyBy !== 1
                }
                required={this.defaultData.ifParentShopNumber === '1'}
                placeholder={this.parentShopNumberPlaceholder}
                prop="shop.parentShopNumber"
                rules={[
                  {
                    validator(rule, value, callback) {
                      console.log(value)
                      if (
                        value.length !== 7 ||
                        !/^[0-9]+\.?[0-9]*$/.test(value)
                      ) {
                        return callback('请输入7位数字物理店编号')
                      }
                      callback()
                    },
                    trigger: 'blur'
                  }
                ]}
              />
            )
          })
        },
        {
          // 最基础的数据结构
          title: '门店编号',
          width: 160,
          renderFormItem: render((h, context) => {
            const selectProp = {
              clearable: true,
              filterable: true,
              'popper-class': 'custom-business-select'
            }
            return [2, 3].includes(this.storeModalEasyBy) ? (
              <RequiredFormItemSelect
                v-model={this.defaultData.shopNumber}
                label="门店编号"
                class="formItem"
                prop={`shop.shopNumber`}
                label-key="shopNumber"
                value-key="shopNumber"
                get-data-list={this.getAreaShopList}
                get-data-list-params={this.getAuthAreaListParamsStr}
                onOnChangeItem={this.onChangeShopNumber}
                disabled={
                  !this.authorizedSelectedItem.authorizedCityCode ||
                  !this.businessLicense.id
                }
                selectProps={selectProp}
                scopedSlots={{
                  default: ({ scope }) => {
                    return (
                      <div>
                        <small>{scope.row.shopNumber}</small>
                        <div>{scope.row.authorizedCityName}</div>
                      </div>
                    )
                  }
                }}></RequiredFormItemSelect>
            ) : (
              <RequiredFormItemInput
                value={
                  this.defaultData.shopNumber
                    ? this.defaultData.shopNumber
                    : '归档后生成'
                }
                class="formItem"
                delay-update
                disabled
                label="门店编号"
              />
            )
          })
        },
        {
          title: '店态性质(必填)',
          width: 170,
          renderFormItem: render((h, context) => (
            <RequiredModuleFormItemSelect
              v-model={this.defaultData.storeNature}
              class="formItem"
              prop={`shop.storeNature`}
              label="店态性质(必填)"
              getDataListParams={module_params}
              type="shop_nature_code"
              disabled={[2, 3].includes(this.storeModalEasyBy)}
              labelTip={TipsText.storeNatureTipsText}>
              <p
                slot="contentBefore"
                style="color: red; line-height: 1; font-size: 0.8em; margin-bottom: 10px">
                若为经典+沙发综合店,必须选择:客餐卧综合店
              </p>
            </RequiredModuleFormItemSelect>
          ))
        },
        {
          title: '独立外立面门头(必填)',
          width: 160,
          renderFormItem: render((h, context) => (
            <RequiredModuleFormItemSelect
              v-model={this.defaultData.independentDoorHeadFlag}
              class="formItem"
              prop={`shop.independentDoorHeadFlag`}
              label="独立外立面门头(必填)"
              type="independent_door_head_flag_boolean"
              disabled={[2, 3].includes(this.storeModalEasyBy)}
            />
          ))
        },
        {
          title: '渠道性质(必填)',
          width: 170,
          renderFormItem: render((h, context) => (
            <RequiredModuleFormItemSelect
              v-model={this.defaultData.shopType}
              class="formItem"
              prop={`shop.shopType`}
              label="渠道性质(必填)"
              type="store_type"
              label-tip={TipsText.shopTypeTipsText}
              onChangeSelectedItem={e => {
                if (!e) return
                this.shopTypeSelectedItem = e
                this.$set(this.defaultData, 'shopTypeName', e.content)
              }}
              disabled={[2, 3].includes(this.storeModalEasyBy)}
              getDataListParams={module_params}
              onChange={e => {
                this.defaultData.shopTypeValue = ''
                this.defaultData.shopPositionLevel = ''
                if (['SJ001', 'SJ008'].includes(e)) {
                  this.defaultData.marketName = ''
                  this.defaultData.marketType = ''
                }
                this.$nextTick(() => {
                  if (!e) return
                  if (!['SJ001', 'SJ008'].includes(e)) {
                    this.defaultData.marketName = `${
                      this.authorizedSelectedItem?.authorizedCityName || ''
                    } ${this.shopTypeSelectedItem?.content || ''}`
                    this.defaultData.marketType = [
                      'SJ007',
                      'SJ005',
                      'SJ003',
                      'SJ009'
                    ].includes(e)
                      ? this.shopTypeSelectedItem?.content
                      : '非集中卖场'
                  }
                  this.defaultData.shopTypeValue = [
                    'SJ008',
                    'SJ001',
                    'SJ009',
                    'SJ007',
                    'SJ005'
                  ].includes(e)
                    ? ''
                    : this.shopTypeSelectedItem?.content
                })
              }}>
              <p
                slot="contentBefore"
                style="color: red; line-height: 1; font-size: 0.8em; margin-bottom: 10px">
                若门店所在商场属于KA商场，必须选择KA商场店
              </p>
            </RequiredModuleFormItemSelect>
          ))
        },
        {
          title: '渠道性质二级分类(必填)',
          width: 240,
          renderFormItem: render((h, context) => {
            const params = {
              ...module_params,
              parentType: 'store_type'
            }
            return ['SJ001', 'SJ009', 'SJ007', 'SJ008', 'SJ005'].includes(
              this.defaultData.shopType
            ) ? (
              this.defaultData.shopType === 'SJ005' ? (
                <RequiredFormItemInput
                  v-model={this.defaultData.shopTypeValue}
                  class="formItem"
                  prop={`shop.shopTypeValue`}
                  label="渠道性质二级分类(必填)"
                  disabled={
                    [2, 3].includes(this.storeModalEasyBy) ||
                    !this.defaultData.shopType
                  }
                />
              ) : (
                <RequiredModuleFormItemSelect
                  v-model={this.defaultData.shopTypeValue}
                  class="formItem"
                  getDataListParams={params}
                  prop={`shop.shopTypeValue`}
                  label="渠道性质二级分类(必填)"
                  type="secondary_store_type"
                  value-key="content"
                  parent-code={this.defaultData.shopType}
                  disabled={
                    [2, 3].includes(this.storeModalEasyBy) ||
                    !this.defaultData.shopType
                  }
                  onOnChangeItem={e => {
                    if (!e) {
                      this.defaultData.marketName = ''
                      this.defaultData.marketType = ''
                    }
                  }}
                />
              )
            ) : (
              <RequiredFormItemInput
                v-model={this.defaultData.shopTypeValue}
                class="formItem"
                prop={`shop.shopTypeValue`}
                label="渠道性质二级分类"
                disabled={true}
              />
            )
          })
        },
        {
          title: '卖场名称(必填)',
          width: 230,
          renderFormItem: render((_, context) => {
            return !['SJ001', 'SJ008'].includes(this.defaultData.shopType) ? (
              // <div>{this.defaultData.marketName}</div>
              // <RequiredFormItem class="formItem" label="卖场名称">
              //   {this.defaultData.marketName}
              // </RequiredFormItem>
              <RequiredFormItemInput
                v-model={this.defaultData.marketName}
                class="formItem"
                label="卖场名称"
                disabled={true}
              />
            ) : (
              <RequiredModuleFormItemSelect
                v-model={this.defaultData.marketName}
                class="formItem"
                getDataListParams={{
                  ...module_params,
                  parentType: 'secondary_store_type',
                  explainJson: this.authorizedSelectedItem?.authorizedCityName
                }}
                prop="shop.marketName"
                label="卖场名称(必填)"
                disabled={
                  [2, 3].includes(this.storeModalEasyBy) ||
                  !this.defaultData.shopTypeValue
                }
                value-key="content"
                type="dealer_shop.market_name"
                parent-content={this.defaultData.shopTypeValue}
                onOnChangeItem={this.handleChangeMarketName}
              />
            )
          })
        },
        {
          title: '卖场类型',
          width: 170,
          name: 'marketType',
          renderFormItem: render((_, context) => {
            return (
              <RequiredFormItemInput
                v-model={this.defaultData.marketType}
                class="formItem requiredFormItem"
                label="卖场类型"
                disabled={true}
              />
            )
          })
        },
        {
          title: '该卖场专卖店数量',
          name: 'marketShopCount',
          width: 140,
          renderFormItem: render((_, context) => {
            return (
              <RequiredFormItemInput
                v-model={this.defaultData.marketShopCount}
                class="formItem"
                label="该卖场专卖店数量"
                disabled={true}
              />
            )
          })
        },
        {
          title: '门店位置等级',
          width: 160,
          renderFormItem: render((h, context) => {
            return this.defaultData.shopType === 'SJ009' ||
              ['重点卖场', '一般卖场', '强势卖场', '非集中卖场'].includes(
                this.defaultData.marketType
              ) ? (
              <RequiredModuleFormItemSelect
                v-model={this.defaultData.shopPositionLevel}
                prop={`shop.shopPositionLevel`}
                class="formItem requiredFormItem"
                label="门店位置等级(必填)"
                getDataListParams={module_params}
                type="shop_level"
                parentCode={
                  this.defaultData.shopType === 'SJ009'
                    ? this.defaultData.shopType
                    : '0'
                }
              />
            ) : (
              <RequiredFormItemInput
                value={''}
                class="formItem requiredFormItem"
                label="门店位置等级"
                disabled={true}
              />
            )
          })
        },
        {
          title: '门店名称',
          width: 160,
          renderFormItem: render((h, context) => (
            <RequiredFormItem
              class="formItem"
              label="门店名称"
              required={false}>
              {
                (this.defaultData.shopName =
                  this.getShopName(
                    this.defaultData,
                    this.defaultDataBrandList
                  ) || '名称')
              }
            </RequiredFormItem>
          ))
        },
        {
          title: '门店简称(必填)',
          width: 200,
          renderFormItem: render((h, context) => (
            <RequiredFormItemInput
              v-model={this.defaultData.shopAlias}
              class="formItem"
              delay-update
              prop={`shop.shopAlias`}
              label="门店简称(必填)"
              disabled={[2, 3].includes(this.storeModalEasyBy)}>
              <div slot="contentBefore">
                <p style="color: red; line-height: 1; font-size: 0.8em; margin-bottom: 10px">
                  授权城市+商场名称+楼层+系列/授权城市+小区名称+楼层+系列
                </p>
              </div>
            </RequiredFormItemInput>
          ))
        },
        {
          title: '经营模式(必填)',
          width: 180,
          renderFormItem: render(h => (
            <RequiredModuleFormItemSelect
              v-model={this.defaultData.managementModel}
              class="formItem"
              prop={`shop.managementModel`}
              label="经营模式(必填)"
              type="management_model"
              onChangeSelectedItem={e => {
                if (!e) return
                setTimeout(() => {
                  this.storeBaseColumns.forEach(item => {
                    if (
                      [
                        '二级分销商(必填)',
                        '二级分销商身份证(必填)',
                        '二级分销商联系方式(必填)'
                      ].includes(item.title)
                    ) {
                      item.hideInTable = Number(e.code) !== 20
                    }
                  })
                }, 0)
              }}
              disabled={[2, 3].includes(this.storeModalEasyBy)}
            />
          ))
        },
        {
          title: '二级分销商(必填)',
          width: 160,
          hideInTable: true,
          renderFormItem: render(h => (
            <RequiredFormItemInput
              v-model={this.defaultData.contactPerson}
              class="formItem"
              delay-update
              placeholder={this.placeholder.contactPerson || null}
              prop={`shop.contactPerson`}
              label={'二级分销商(必填)'}
              validations={validations['contactPerson'] || []}
              disabled={[2, 3].includes(this.storeModalEasyBy)}
            />
          ))
        },
        {
          title: '二级分销商身份证(必填)',
          width: 160,
          hideInTable: true,
          renderFormItem: render(h => (
            <RequiredFormItemInput
              v-model={this.defaultData.contactPersonIdcard}
              class="formItem"
              delay-update
              placeholder={this.placeholder.contactPersonIdcard || null}
              prop={`shop.contactPersonIdcard`}
              label={'二级分销商身份证(必填)'}
              validations={validations['contactPersonIdcard'] || []}
              disabled={[2, 3].includes(this.storeModalEasyBy)}
            />
          ))
        },
        {
          title: '二级分销商联系方式(必填)',
          width: 160,
          hideInTable: true,
          renderFormItem: render(h => (
            <RequiredFormItemInput
              v-model={this.defaultData.contactPersonPhone}
              class="formItem"
              delay-update
              placeholder={this.placeholder.contactPersonPhone || null}
              prop={`shop.contactPersonPhone`}
              label={'二级分销商联系方式(必填)'}
              validations={validations['contactPersonPhone'] || []}
              disabled={[2, 3].includes(this.storeModalEasyBy)}
            />
          ))
        },
        {
          title: '门店租金（万/年）(必填)',
          width: 160,
          renderFormItem: render(h => {
            return (
              <RequiredFormItemInput
                v-model={this.defaultData.shopRent}
                delay-update
                class="formItem"
                placeholder={this.placeholder['shopRent'] || null}
                prop={`shop.shopRent`}
                label="门店租金（万/年）(必填)"
                type="number"
                rules={[
                  {
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                      if (value === '') {
                        callback(new Error('请输入门店租金'))
                      } else if (value && value < 0) {
                        callback('门店租金不能少于 0')
                      } else if (value > 1000) {
                        callback('门店租金不能大于 1000')
                      } else {
                        callback()
                      }
                    }
                  }
                ]}
              />
            )
          })
        },
        {
          title: '门店实用面积(必填)',
          width: 160,
          renderFormItem: render(h => {
            {
              return this.storeModalEasyBy === 1 ? (
                <RequiredFormItemInput
                  v-model={this.defaultData.shopArea}
                  delay-update
                  class="formItem"
                  prop={`shop.shopArea`}
                  label="门店实用面积(必填)"
                  type="number"
                />
              ) : (
                <RequiredFormItemInput
                  v-model={this.defaultData.oldShopArea}
                  class="formItem"
                  label="门店实用面积"
                  disabled={true}
                />
              )
            }
          })
        },
        {
          title: '装修面积(必填)',
          width: 160,
          renderFormItem: render(h => {
            return ['部分装修', '原店扩大'].includes(this.storeOpeningMode) ? (
              <RequiredFormItemInput
                v-model={this.defaultData.shopAddArea}
                delay-update
                class="formItem"
                prop={`shop.shopAddArea`}
                label="装修面积(必填)"
                type="number"
              />
            ) : (
              <RequiredFormItemInput
                v-model={this.defaultData.shopArea}
                delay-update
                class="formItem"
                prop={`shop.shopArea`}
                label="装修面积(必填)"
                type="number"
                disabled={[1, 3].includes(this.storeModalEasyBy)}
              />
            )
          })
        },
        {
          title: '调整后门店面积',
          width: 160,
          hideInTable: true,
          renderFormItem: render(h => {
            const areaCount =
              this.storeOpeningMode === '原店扩大'
                ? Number(this.defaultData.shopAddArea || 0) +
                  Number(this.defaultData.oldShopArea || 0)
                : Number(this.defaultData.shopArea || 0)
            return (
              <RequiredFormItemInput
                value={areaCount ? areaCount.toFixed(2) : ''}
                class="formItem"
                label="调整后门店面积"
                disabled={true}
              />
            )
          })
        },
        {
          title: '门头实用面积',
          width: 160,
          hideInTable: true,
          renderFormItem: render(h => {
            {
              return (
                <RequiredFormItemInput
                  v-model={this.defaultData.doorHeaderBeforeArea}
                  delay-update
                  class="formItem"
                  prop={`shop.doorHeaderBeforeArea`}
                  label="门头实用面积(必填)"
                  type="number"
                  disabled={this.storeModalEasyBy !== 1}
                />
              )
            }
          })
        },
        {
          title: '门头装修面积',
          width: 160,
          hideInTable: true,
          renderFormItem: render(h => {
            return this.storeModalEasyBy === 1 ? (
              <RequiredFormItemInput
                v-model={this.defaultData.doorHeaderBeforeArea}
                delay-update
                class="formItem"
                label="门头装修面积"
                type="number"
                disabled={true}
                required={false}
              />
            ) : (
              <RequiredFormItemInput
                v-model={this.defaultData.doorHeaderRenovationArea}
                delay-update
                class="formItem"
                prop={`shop.doorHeaderRenovationArea`}
                label="门头装修面积(必填)"
                type="number"
                disabled={this.storeModalEasyBy === 3}
              />
            )
          })
        },
        {
          title: '调整后门头面积',
          width: 160,
          hideInTable: true,
          renderFormItem: render(h => {
            this.defaultData.doorHeaderArea =
              this.storeOpeningMode === '原店扩大'
                ? Number(this.defaultData.doorHeaderBeforeArea || 0) +
                  Number(this.defaultData.doorHeaderRenovationArea || 0)
                : Number(this.defaultData.doorHeaderRenovationArea || 0)
            const _doorHeaderAre = this.defaultData.doorHeaderArea.toFixed(2)
            return (
              <RequiredFormItemInput
                value={_doorHeaderAre}
                class="formItem"
                label="调整后门头面积"
                disabled={true}
              />
            )
          })
        }
      ],
      storeAddressColumns: [
        {
          title: '省份',
          width: 160,
          name: 'province',
          renderFormItem: render(_ => {
            return this.defaultData.province === '全国' ? (
              <RequiredModuleFormItemSelect
                v-model={this.defaultData.regionProvince}
                prop="shop.province"
                class="formItem"
                label="省份"
                valueKey="content"
                type="province"
                module="public"
                disabled={
                  !this.authorizedSelectedItem ||
                  [2, 3].includes(this.storeModalEasyBy)
                }
                placeholder="请选择省份"
                onOnChangeItem={e => {
                  this.defaultData.regionProvince = e ? e.content : ''
                  this.defaultData.regionProvinceCode = e ? e.code : ''
                }}
              />
            ) : (
              <RequiredFormItemInput
                v-model={this.defaultData.province}
                class="formItem"
                label="省份"
                disabled={true}
              />
            )
          })
        },
        {
          title: '地级市',
          width: 160,
          name: 'city',
          renderFormItem: render(_ => {
            return this.defaultData.city === '城市' ? (
              <RequiredModuleFormItemSelect
                v-model={this.defaultData.regionCity}
                prop="shop.city"
                class="formItem"
                label="地级市"
                valueKey="content"
                type="city"
                module="public"
                parent-code={this.defaultData.regionProvinceCode}
                disabled={
                  !this.authorizedSelectedItem ||
                  [2, 3].includes(this.storeModalEasyBy)
                }
                placeholder="请选择地级市"
                onOnChangeItem={e => {
                  this.defaultData.regionCity = e ? e.content : ''
                  this.defaultData.regionCityCode = e ? e.code : ''
                }}
              />
            ) : (
              <RequiredFormItemInput
                v-model={this.defaultData.city}
                class="formItem"
                label="地级市"
                disabled={true}
              />
            )
          })
        },
        {
          title: '区/县(必填)',
          width: 160,
          renderFormItem: render((h, context) => {
            return this.defaultData.province === '全国' ? (
              <RequiredModuleFormItemSelect
                v-model={this.defaultData.area}
                prop="shop.area"
                class="formItem"
                label="区/县(必填)"
                valueKey="content"
                type="area"
                module="public"
                parent-code={this.defaultData.regionCityCode}
                disabled={
                  !this.authorizedSelectedItem ||
                  [2, 3].includes(this.storeModalEasyBy)
                }
                placeholder="请选择授权城市"
                onOnChangeItem={e => {
                  this.defaultData.regionAreaName = e ? e.content : ''
                  this.defaultData.regionAreaCode = e ? e.code : ''
                }}
              />
            ) : (
              <RequiredModuleFormItemSelect
                v-model={this.defaultData.area}
                prop="shop.area"
                class="formItem"
                label="区/县(必填)"
                valueKey="content"
                type="area"
                module="public"
                parent-code={
                  this.authorizedSelectedItem &&
                  this.authorizedSelectedItem.cityCode
                }
                disabled={
                  !this.authorizedSelectedItem ||
                  [2, 3].includes(this.storeModalEasyBy)
                }
                placeholder="请选择授权城市"
                onChangeSelectedItem={e => {
                  this.defaultData.regionAreaName = e ? e.content : ''
                  this.defaultData.regionAreaCode = e ? e.code : ''
                }}
              />
            )
          })
        },
        {
          title: '道路/街道(必填)',
          width: 160,
          renderFormItem: render((h, context) => (
            <RequiredFormItemInput
              v-model={this.defaultData.street}
              class="formItem"
              delay-update
              placeholder="示例：湖景街道科技大道 1 号"
              prop={`shop.street`}
              label="道路/街道(必填)"
              disabled={[2, 3].includes(this.storeModalEasyBy)}>
              <p
                slot="contentBefore"
                style="color: red; line-height: 1; font-size: 0.8em; margin-bottom: 10px">
                请填写门店所在街道以及所在道路
              </p>
            </RequiredFormItemInput>
          ))
        },
        {
          title: '门店楼层(必填)',
          width: 160,
          renderFormItem: render((h, context) => (
            <RequiredFormItemInput
              v-model={this.defaultData.shopFloor}
              prop="shop.shopFloor"
              delay-update
              placeholder={this.placeholder['shopFloor'] || null}
              label="门店楼层(必填)"
              class="formItem"
              disabled={[2, 3].includes(this.storeModalEasyBy)}
              maxlength="20"
            />
          ))
        },
        {
          title: '门店铺号(必填)',
          width: 160,
          renderFormItem: render((h, context) => (
            <RequiredFormItemInput
              v-model={this.defaultData.shopFloorNumber}
              delay-update
              placeholder={this.placeholder['shopFloorNumber'] || null}
              prop={`shop.shopFloorNumber`}
              label="门店铺号(必填)"
              class="formItem"
              disabled={[2, 3].includes(this.storeModalEasyBy)}
            />
          ))
        },
        {
          title: '门店经纬度(必填)',
          width: 160,
          renderFormItem: render((h, context) => {
            const sortMethods = {
              'on-submit': params => {
                this.defaultData.longitude = params.longitude
                this.defaultData.latitude = params.latitude
                return true
              }
            }
            return (
              <RequiredFormItem
                prop="shop.longitude"
                class="formItem"
                label="门店经纬度(必填)">
                <PopupForm
                  title="选择地址"
                  columns={this.selectLocationColumns}
                  attrs={sortMethods}
                  scopedSlots={{
                    trigger: ({ onShow }) => (
                      <div>
                        {this.defaultData.longitude ? (
                          <div>
                            {this.defaultData.longitude}，
                            {this.defaultData.latitude}
                          </div>
                        ) : null}
                        {!this.isOA ? (
                          <el-button
                            v-loading-click={() =>
                              onShow({
                                _latitude_longitude: this.defaultData.longitude
                                  ? {
                                      longitude: this.defaultData.longitude,
                                      latitude: this.defaultData.latitude
                                    }
                                  : {}
                              })
                            }>
                            选择地址
                          </el-button>
                        ) : null}
                      </div>
                    )
                  }}
                />
              </RequiredFormItem>
            )
          })
        },
        {
          title: '门店地址',
          width: 160,
          renderFormItem: render((h, context) => (
            <RequiredFormItem
              class="formItem"
              label="门店地址"
              required={false}>
              {this.getShopAddress(this.defaultData) || '自动带出'}
            </RequiredFormItem>
          ))
        }
      ],
      storeDecorationColumns: [
        {
          title: '最新装修时间',
          width: 240,
          hideInTable: true,
          renderFormItem: render(() => {
            const props = { 'picker-options': this.pickerOptions }
            return (
              <RequiredFormItemDatePicker
                v-model={this.defaultData.decorationTime}
                class="formItem"
                prop={`shop.decorationTime`}
                label="最新装修时间"
                required={false}
                date-picker-props={props}
                disabled={[2, 3].includes(this.storeModalEasyBy)}
              />
            )
          })
        },
        {
          title: '装修对接人（点将项目合同签署人）',
          width: 160,
          renderFormItem: render(h => (
            <RequiredFormItemInput
              v-model={this.defaultData.renovationLiablePerson}
              class="formItem"
              delay-update
              prop={`shop.renovationLiablePerson`}
              label={'装修对接人（点将项目合同签署人）'}
            />
          ))
        },
        {
          title: '装修对接人电话（须接收点将合同短信通知）',
          width: 160,
          renderFormItem: render(h => (
            <RequiredFormItemInput
              v-model={this.defaultData.renovationPersonPhone}
              class="formItem"
              delay-update
              placeholder={this.placeholder.renovationPersonPhone || null}
              prop={`shop.renovationPersonPhone`}
              label={'装修对接人电话（须接收点将合同短信通知）'}
              validations={validations['renovationPersonPhone'] || []}
            />
          ))
        },
        {
          title: '是否点将设计(必填)',
          width: 160,
          renderFormItem: render((h, context) => (
            <RequiredModuleFormItemSelect
              v-model={this.defaultData.ifDianjiangDesigner}
              class="formItem"
              prop={`shop.ifDianjiangDesigner`}
              label="是否点将设计(必填)"
              type="flow_common_select_ifEnable_cn"
              onOnChangeItem={data => {
                this.dianjiangshiData = {}
                this.defaultData.dianjiangDesigner = ''
                this.defaultData.dianjiangDesignerName = ''
                this.defaultData.dianjiangDesignerPrice = ''
                this.defaultData.dianjiangDesignerFactor = ''
                this.getDianjiangTableList(1)
              }}>
              <p
                slot="contentBefore"
                style="color: red; line-height: 1; font-size: 0.8em; margin-bottom: 10px">
                点将项目时，合作协议后3天内支付45%设计费，完成施工交底后支付55%设计费
              </p>
            </RequiredModuleFormItemSelect>
          ))
        },
        {
          title: '设计师',
          width: 160,
          renderFormItem: render((h, context) => {
            const selectProp = {
              filterable: true,
              remote: true,
              'popper-class': 'custom-business-select'
            }
            return (
              <RequiredFormItemSelect
                v-model={this.defaultData.dianjiangDesigner}
                class="formItem"
                prop={`shop.dianjiangDesigner`}
                label="设计师"
                required={true}
                clearable
                get-data-list={this.getDesignerList}
                get-data-list-params={{
                  stylistPost:
                    this.defaultData.ifDianjiangDesigner === '是'
                      ? '点将设计师'
                      : '区域设计师'
                }}
                onOnOptionsListNotFound={this.onOptionsListNotFound}
                label-key="realName"
                value-key="account"
                selectProps={selectProp}
                scopedSlots={{
                  default: ({ scope }) => {
                    return (
                      <div>
                        <small>{scope.row.account}</small>
                        <div>{scope.row.realName}</div>
                      </div>
                    )
                  }
                }}
                onChangeSelectedItem={data => {
                  if (!data) {
                    this.defaultData.dianjiangDesignerName = ''
                    this.dianjiangshiData = {}
                    return
                  }
                  this.defaultData.dianjiangDesignerName = data.realName
                  this.dianjiangshiData = data
                }}
              />
            )
          })
        },
        {
          title: '参考费用',
          width: 170,
          renderFormItem: render((h, context) => {
            const shopArea = Number(context.$attrs.params.shopArea) || 0
            const shopAddArea = Number(context.$attrs.params.shopAddArea) || 0
            const area = ['部分装修', '原店扩大'].includes(
              this.storeOpeningMode
            )
              ? shopAddArea
              : shopArea
            if (!this.isOA) {
              if (this.dianjiangshiData.id) {
                this.defaultData.dianjiangDesignerFactor =
                  this.bigRegionName === this.dianjiangshiData.region ? 1 : 1.2
                this.defaultData.dianjiangDesignerPrice =
                  area < 100
                    ? 400
                    : (
                        area *
                        this.dianjiangshiData.actualDealerBear *
                        this.defaultData.dianjiangDesignerFactor
                      ).toFixed(2)
              } else {
                this.defaultData.dianjiangDesignerPrice = ''
                this.defaultData.dianjiangDesignerFactor = ''
              }
            }
            return (
              <RequiredFormItemInput
                v-model={this.defaultData.dianjiangDesignerPrice}
                class="formItem"
                label="参考费用"
                disabled={true}>
                <p
                  slot="contentBefore"
                  style="color: red; line-height: 1; font-size: 0.8em; margin-bottom: 10px">
                  {'跨区域需乘以1.2系数，面积 < 100 ㎡项目验收后支付设计费'}
                </p>
              </RequiredFormItemInput>
            )
          })
        }
      ],
      selectLocationColumns: [
        {
          name: '_latitude_longitude',
          search: {
            transform(e) {
              return e
                ? {
                    latitude: e.latitude,
                    longitude: e.longitude
                  }
                : {}
            }
          },
          renderFormItem: renderFormItem((h, context) => {
            return (
              context.$attrs.value && (
                <QQMap
                  ref="mapRef"
                  center={
                    context.$attrs.value.longitude ? context.$attrs.value : null
                  }
                  onOnChange={e => {
                    context.$listeners.input(e)
                  }}
                />
              )
            )
          })
        }
      ],
      shopTypeSelectedItem: null,
      placeholder: {
        registrationAuthority: '请填写营业执照右下方登记机关红章名称',
        bank: '详细至营业点，**分行/分理处',
        certificatesRecAddress: '实控人接收慕思财务下发函证资料地址',
        nativePlace: '广东东莞',
        capitalSource: '自有资金/政策贷款/其他',
        oaAccount: '归档后生成',
        advertisingInvestment: '年度广告投入具体单位新增（万）单位',
        businessTotalTime: '已从事时间指年份',
        shopFloor: '填写示例:1层',
        shopFloorNumber: '填写示例:1号'
      },
      // 最小时间
      pickerOptions: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now()
        }
      }
    }
  },
  mounted() {
    this.getDianjiangTableList()
  },
  methods: {
    async onOptionsListNotFound({ value, getDataListParams }, callback) {
      console.log(value)
      const reg = /^(?:[\u4e00-\u9fa5·]{1,16})$/
      const res = await requestGetDianjiangList({
        realName: reg.test(value) ? value : null,
        account: !reg.test(value) ? value : null,
        pageSize: 20
      })
      callback(res.data)
    },
    async getAreaShopList({ getDataListParams }) {
      console.log(getDataListParams)
      if (
        !getDataListParams.authorizedCityCode ||
        !getDataListParams.businessLicenseId
      ) {
        return {
          data: []
        }
      }
      const { data } = await getShopList({
        ...getDataListParams,
        shopStatus: this.isOA ? '-1' : '0'
      })
      // this.otherSelectedBrandList = data
      return data
    },
    onChangeShopNumber(shopData) {
      console.log(shopData)
      if (!shopData) return
      // this.defaultData.oldShopId = shopData.id
      // 草稿箱可能把这个字段干掉了
      // if (!this.defaultData.shopRent) {
      //   this.defaultData.shopRent = ''
      // }
      shopData.oldShopArea = shopData?.shopArea || 0
      shopData.doorHeaderBeforeArea = shopData?.doorHeaderArea || 0
      for (const key in this.defaultData) {
        if (typeof shopData[key] === 'boolean') {
          this.defaultData[key] = shopData[key]
        } else if (typeof shopData[key] === 'string') {
          this.defaultData[key] = shopData[key] || ''
        } else if (typeof shopData[key] === 'number') {
          this.defaultData[key] = shopData[key]
        } else {
          this.defaultData[key] = shopData[key]
        }
      }
      this.defaultData.renovationPersonPhone = ''
      this.defaultData.renovationLiablePerson = ''
      this.dianjiangshiData = {}
    },
    getShopName(shopData, dealeShopBrandList) {
      const mergeBrandList = [
        ...new Set(dealeShopBrandList.map(item => item.brandId))
      ].map(brandId =>
        dealeShopBrandList.find(item => item.brandId === brandId)
      )
      const {
        city,
        area,
        street,
        shopTypeValue,
        shopFloor,
        shopFloorNumber,
        regionCity
      } = shopData
      const brandNames = mergeBrandList
        .map(item => item.brandName || item.name)
        .join('/')
      return `${
        regionCity || city
      }${area}${street}${shopTypeValue}${shopFloor}${shopFloorNumber}（${brandNames}）专卖店`
    },
    getShopAddress(row) {
      const {
        regionProvince,
        regionCity,
        province,
        city,
        area,
        street,
        shopTypeValue,
        shopFloor,
        shopFloorNumber
      } = row
      const content = this.shopTypeSelectedItem?.content || ''
      return `${regionProvince || province}${
        regionCity || city
      }${area}${street}${content}${shopTypeValue}${shopFloor}${shopFloorNumber}`
    },
    // otherShopSelectedBrandList({
    //   otherSelectedBrandList,
    //   form: {
    //     shop: { oldShopFlag }
    //   }
    // }) {
    //   if (!oldShopFlag) {
    //     return []
    //   }
    //   const otherShopSelectedBrandListData = otherSelectedBrandList
    //     .map(item => item.shopBrandDetailsList)
    //     .flat()
    //   return otherShopSelectedBrandListData
    // },
    // 获取点将设计师列表
    async getDesignerList(params) {
      const reg = /^(?:[\u4e00-\u9fa5·]{1,16})$/
      const res = await requestGetDianjiangList({
        realName: reg.test(params.searchText) ? params.searchText : null,
        account: !reg.test(params.searchText) ? params.searchText : null,
        currPage: params.page,
        stylistPost: params.getDataListParams.stylistPost
      })
      return res
      // this.dianjiangTotalCount = res.totalCount
    },
    // 选择卖场名称
    async handleChangeMarketName(e) {
      // console.log(e)
      this.defaultData.marketType = e?.typeDetail ?? ''
      this.defaultData.shopPositionLevel = e
        ? this.defaultData.shopPositionLevel
        : ''
    },
    // 获取案例列表
    async getVMDList() {
      const res = await requestVMDList({
        stylistId: this.VMDid,
        currPage: this.currentVMDPage,
        pageSize: this.VMDPageSize
      })
      this.VMDList = res.data
      this.VMDTotal = res.totalCount
      this.VMDdrawer = true
      // console.log(res)
    },
    handleVMDSizeChange(val) {
      this.VMDPageSize = val
      this.getVMDList()
    },
    handleVMDCurrentChange(val) {
      this.currentVMDPage = val
      this.getVMDList()
    },
    async handleVMDdetail(id) {
      const { data } = await requestVMDdetail(id)
      this.VMDdetail = data
      this.VMDdialogVisible = true
    },
    async getDianjiangTableList(currPage = 1) {
      const res = await requestGetDianjiangList({
        currPage,
        pageSize: 1000,
        stylistPost:
          this.defaultData.ifDianjiangDesigner === '是'
            ? '点将设计师'
            : '区域设计师'
      })
      this.dianjiangDesigner = res.data
    }
  }
}
</script>

<style scoped lang="scss">
.tablePro {
  ::v-deep {
    .el-input.is-disabled {
      .el-input__inner {
        background-color: #f2f6fc;
        color: #333;
      }
    }
  }
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.caseName {
  font-size: 14px;
  margin-bottom: 10px;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
  // width: 100%;
}
.case {
  font-size: 12px;
  color: #999;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  .caseArea {
    margin-right: 10px;
  }
}
::v-deep {
  .dianjian.is-active {
    background: #fff;
    opacity: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 6px rgba(0, 0, 0, 0.04);
  }
}
.dianjian {
  font-size: 14px;
  background: #f3f3f3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  //opacity: 0.5;
  .header_img {
    width: 200px;
    height: 300px;
    margin-right: 10px;
  }
  .w_flex {
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    &.around {
      justify-content: space-between;
    }
  }

  .content {
    flex: 1;
    height: auto;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    .label {
      color: #999;
      width: 130px;
    }
    .more {
      color: #333;
    }
    .w_flex {
      margin-bottom: 6px;
    }
    .name {
      color: #f5972e;
    }
  }
}
.pagination {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
.requiredFormItem {
  ::v-deep {
    .el-form-item__label,
    .el-form-item__content,
    .el-input__inner {
      font-size: 18px;
      font-weight: 600;
      color: #f56c6c !important;
    }
  }
}
</style>
